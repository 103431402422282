import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { getProfitLossForAccountStatement } from "../../api/services/profitLossReportService";
import moment from "moment";
// import ProfitLossReportByMarket from "./profitLossReportByMarket";
import ProfitLossReportByMarket from "../accountStatement/profitLossReportByMarket";
import CustomIcon from "../../components/customIcon";

export default function AccountStatement() {
  const [plData, setPlData] = useState([]);
  const [apiParams, setApiParams] = useState({});
  const [totalPl, setTotalPl] = useState(0);
  const [marketId, setMarketId] = useState("");
  const [isCasino, setIsCasino] = useState(false);

  const {userData,settings} = useSelector((state) => state.user);

  const getProfitLossReportFunc = async (from, to, user_id) => {
    try {
      const response = await getProfitLossForAccountStatement(
        moment(from).startOf("day").unix(),
        moment(to).endOf("day").unix(),
        user_id
      );
      let temp = 0;
      Object.keys(response.data.data).map((key, i) => {
        temp += response.data.data[key].reduce(
          (prev, row) => (row.market_id ? prev + (row.profitAndLoss || 0) : prev),
          0
        );
      });

      setTotalPl(temp);

      const plArray = Object.entries(response.data.data);
      setPlData(plArray);
    } catch (error) {
      console.log("Error Fetching Profit & Loss Reportt");
    }
  };

  useEffect(() => {
    getProfitLossReportFunc(apiParams.from, apiParams.to, apiParams.user_id);
  }, []);

  useLayoutEffect(() => {
    setApiParams({
      ...apiParams,
      from: moment(),
      to: moment(),
      user_id: userData?.user_id,
    });
  }, [userData]);

  return (
    <Box className={"account-statement"}>
      <Box sx={{ marginTop: "9px", height: "99px" }}>
        <Box className={"datepicker-container"}>
          <Box
            component={"label"}
            sx={{
              display: "block",
              marginBottom: "10px",
            }}
          >
            From
          </Box>
          <MobileDatePicker
            value={apiParams.from}
            onChange={(e) => setApiParams({ ...apiParams, from: e })}
            format="DD/MM/YYYY"
            disableFuture={true}
          />
        </Box>
        <Box className={"datepicker-container"}>
          <Box
            component={"label"}
            sx={{
              display: "block",
              marginBottom: "10px",
            }}
          >
            To
          </Box>
          <MobileDatePicker
            value={apiParams.to}
            onChange={(e) => setApiParams({ ...apiParams, to: e })}
            format="DD/MM/YYYY"
            disableFuture={true}
          />
        </Box>
        <Button
          onClick={() => {
            getProfitLossReportFunc(
              apiParams.from,
              apiParams.to,
              apiParams.user_id
            );
            setMarketId("");
          }}
          variant="success"
        >
          <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
          <CustomIcon color={"#fff"} name={"search"} fontSize={14} />
            <Box>Search</Box>
          </Stack>
        </Button>
      </Box>
      <Box sx={{ width: "100%" }} className={"filter "}>
        <Box component={"ul"} sx={{ textAlign: "right" }}>
          <Box component={"li"} className="total-pnl">
            <Box mb={2} component={"p"} sx={{ textAlign: "right" }}>
              <Box component={"span"}>Total P&L</Box>
              &nbsp;:&nbsp;
              <Box
                component={"span"}
                className={totalPl > 0 ? "-positive" : "-negative"}
              >
                {totalPl}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {marketId && marketId != "" ? (
        <ProfitLossReportByMarket
          setMarket={setMarketId}
          marketId={marketId}
          isCasino={isCasino}
        />
      ) : (
        <Table className="listing">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell></TableCell>
              <TableCell className="numeric">P&L</TableCell>
              <TableCell className="numeric">Credit Limit</TableCell>
              <TableCell className="numeric">Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plData.map((pld) => (
              <>
                <TableRow sx={{ bgcolor: "#f9f9f9 !important" }}>
                  <TableCell sx={{ fontWeight: 600, pl: "20px !important" }}>
                    {moment(pld[0]).utcOffset(settings?.utc.value).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {pld[1].map((data) => (
                  <TableRow sx={{ bgcolor: "#fff !important" }}>
                    <TableCell
                      sx={{
                        fontWeight: 600,
                        color: "#909090",
                        pl: "30px !important",
                      }}
                    >
                      {data.time ? `${moment(data.time,"HH:mm:ss").utcOffset(settings?.utc.value).format("HH:mm:ss")}` : "-"}
                    </TableCell>
                    <TableCell>
                      {data.description ? data.description : "-"}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        setMarketId(data.provider_market_id);
                        setIsCasino(data?.is_casino);
                      }}
                    >
                      {data.title ? data.title : "-"}
                    </TableCell>

                    <TableCell
                      className="numeric"
                      sx={{
                        fontWeight: 600,
                        color: data.profitAndLoss > 0 ? "green" : "red",
                      }}
                    >
                      {data.profitAndLoss}
                    </TableCell>
                    <TableCell className="numeric">
                      {data.credit_limit}
                    </TableCell>
                    <TableCell
                      className="numeric"
                      sx={{ color: "green", fontWeight: 600 }}
                    >
                      {data.balance}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}
