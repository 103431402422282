import { Box, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import { useContext, useEffect, useState } from "react";
import LinkText from "./miniComponents/linkText";
import { useNavigate } from "react-router-dom";
import LoginModal from "./modals/loginModal";
import { useDispatch, useSelector } from "react-redux";
import { changeTimeZone, clearUser, updateBalance } from "../redux/reducers/user";
import { clearBets } from "../redux/reducers/bets";

import CustomIcon from "./customIcon";
import { searchEventByName } from "../api/services/eventsService";
import { LoginContext } from "../context/login-context";
import { removeAllExposures } from "../redux/reducers/exposure";
import userSocket from "../sockets/userSocket";

export default function Header() {
  const navigate = useNavigate();

  // const [openLogin, setLogin] = useState(false);
  const {openLogin, setLogin,logout} =  useContext(LoginContext)
  const { isLogginIn } = useSelector((state) => state.user);
 

  return (
    <Box
      sx={{
        height: 90,
        bgcolor: "primary.main",
        borderBottom: "1px solid #DDDDDD",
        width: "100%",
        color: "#fff",
      }}
    >
      <LoginModal open={openLogin} onClose={() => setLogin(false)} />
      <Box
        sx={{
          maxWidth: isLogginIn ? 1254 : 1230,
          padding: "0 15px",
          margin: "0 auto",
          height: "100%",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          height={"100%"}
        >
          <Stack direction={"row"} spacing={"20px"} alignItems={"center"}>
            <Box
              sx={{
                width: "158px",
              }}
            >
              <Box
                onClick={() => navigate("/home")}
                sx={{
                  height: "65px",
                  width: "100%",
                  cursor: "pointer",
                  background:
                    `url(/assets/images/logo.png) no-repeat center center`,
                  backgroundSize: "contain",
                }}
              />
            </Box>
            <Timer />
          </Stack>
          {isLogginIn ? (
            <InfoBar />
          ) : (
            <LinkText sx={{ mr: "30px" }} onClick={() => setLogin(true)}>
              <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <CustomIcon name={"login-icon"} fontSize={14} />
                <Typography>Login</Typography>
              </Stack>
            </LinkText>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

const InfoBar = () => {
  const userData = useSelector((state) => state.user.userData);

  const {logout} =  useContext(LoginContext)

  // const logout = async () => {
  //   try {
  //     const response = await userLogout();
  //     if (response.status === 200) {
  //       sessionStorage.clear("token");
  //       dispatch(clearUser());
  //       dispatch(clearBets());
  //       dispatch(removeAllExposures())
  //       navigate("/");
  //     }
  //     console.log(response);
  //     return response;
  //   } catch (error) {
  //     console.error("Logout Failed: ", error.response.status);
  //     if (error.response.status === 401) {
  //       sessionStorage.clear("token");
  //       dispatch(clearUser());
  //       dispatch(clearBets());
  //       dispatch(removeAllExposures())
  //       navigate("/");
  //     }
  //   }
  // };

  return (
    <Box
      sx={{
        float: "right",
        margin: "15px 0",
        padding: "10px 20px",
      }}
    >
      <Box
        component={"ul"}
        sx={{
          listStyle: "outside none none",
          marginBottom: "5px",
          float: "right",
          li: {
            display: "inline-table",
            verticalAlign: "middle",
            paddingLeft: "20px",
          },
        }}
      >
        <Box component={"li"}>
          <SearchEvents />
        </Box>
        <Box component={"li"}></Box>
        <Box component={"li"} fontSize={"11px"}>
          <Box component={"p"}>Logged in as {userData.user_name}</Box>
          <Box component={"p"}>
            Last logged in: {moment.unix(userData?.last_login).format("DD/MM/YYYY HH:mm")}
          </Box>
        </Box>
        <Box component={"li"}>
          <AccountMenu />
        </Box>
        <Box component={"li"}>
          <LinkText onClick={logout}>
            <Stack direction={"row"} spacing={0.2} alignItems={"center"}>
              <CustomIcon name={"logout-icon"} fontSize={12} />
              <Typography variant="body2">Logout</Typography>
            </Stack>
          </LinkText>
        </Box>
      </Box>
    </Box>
  );
};

const Timer = () => {
  const [timer, setTimer] = useState(moment().format("HH:mm:ss"));
  const [selectedZone, setSelectedZone] = useState(1);
  const [showTimeZones, setShowTimeZones] = useState(false);
  const { isLogginIn,settings } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (selectedZone === 1) {
  //       setTimer(moment().format("HH:mm:ss"));
  //     } else if (selectedZone === 2) {
  //       setTimer(moment().utcOffset("+05:30").format("HH:mm:ss"));
  //     } else {
  //       setTimer(moment().utcOffset("+00:00").format("HH:mm:ss"));
  //     }
  //   }, 1000);
  //   // eslint-disable-next-line
  // }, [timer, selectedZone]);

  useEffect(()=>{
    setTimeout(()=>{
      if(settings?.utc){
        console.log(settings?.utc)
        setTimer(moment().utcOffset(settings?.utc.value).format("HH:mm:ss"))
      }
      else{
        setTimer(moment().format("HH:mm:ss"))
      }
    },1000)
},[timer,settings?.utc])

  useEffect(() => {
    window.addEventListener("mousedown", hideTimeZones);
    return () => window.removeEventListener("mousedown", hideTimeZones);
    // eslint-disable-next-line
  }, [showTimeZones]);

  const hideTimeZones = (e) => {
    // document.body.className.includes("menu-item");
    if (showTimeZones && !e.target.className.includes("menu-item"))
      setShowTimeZones(false);
  };

  const handleSelectZone = (index) => {
    setShowTimeZones(false);
    setSelectedZone(index);
    if (index === 1) {
      dispatch(changeTimeZone({value:"+05:30",id:index}))
    } else if (index === 2) {
      dispatch(changeTimeZone({value:"+05:30",id:index}))
    } else {
      dispatch(changeTimeZone({value:"+00:00",id:"0"}))
    }
  };
  return (
    <Stack direction={"row"} spacing={"5px"}>
      <Typography sx={{ fontSize: 12 }}>
        {moment().format("MMM DD, YYYY")}
      </Typography>
      <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>{timer}</Typography>
      {isLogginIn && (
        <Box display={"inline"} sx={{ fontSize: 12 }}>
          (&nbsp;
          <Box
            sx={{
              display: "inline-table",
              padding: "2px 0",
              position: "relative",
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                color: "#ffecc6",
                cursor: "pointer",
                ":hover": { color: "white" },
              }}
              onClick={() => setShowTimeZones(true)}
            >
              {selectedZone === 1
                ? moment().format("Z")
                : selectedZone === 2
                ? "+05:30"
                : "+00:00"}
              &nbsp;
              {/* <Box
                sx={{
                  width: 8.3,
                  height: 6,
                  bgcolor: "#ffecc6",
                  display: "inline-table",
                }}
              ></Box> */}
              <CustomIcon name={"sm-down-arrow"} fontSize={14} />
            </Box>
            <Box
              component={"ul"}
              className="dropdown timer"
              sx={{
                visibility: showTimeZones ? "visible !important" : "hidden",
              }}
            >
              <Box component={"li"}>
                <Box
                  onClick={() => handleSelectZone(0)}
                  className={"menu-item"}
                >
                  System time - (GMT +00:00)
                </Box>
              </Box>
              <Box component={"li"}>
                <Box
                  onClick={() => handleSelectZone(1)}
                  className={"menu-item"}
                >
                  Your computer time - (GMT {moment().format("Z")})
                </Box>
              </Box>
              <Box component={"li"}>
                <Box
                  onClick={() => handleSelectZone(2)}
                  className={"menu-item"}
                >
                  India Standard time - (GMT +05:30)
                </Box>
              </Box>
            </Box>
          </Box>
          &nbsp;)
        </Box>
      )}
    </Stack>
  );
};

const AccountMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("mousedown", hideMenu);
    return () => window.removeEventListener("mousedown", hideMenu);
    // eslint-disable-next-line
  }, [openMenu]);

  const hideMenu = (e) => {
    // document.body.className.includes("menu-item");
    if (openMenu && !e.target.className.includes("menu-item"))
      setOpenMenu(false);
  };

  const handleClick = (link) => {
    setOpenMenu(false);
    navigate(`/account/${link}`);
  };

  const menu = [
    {
      link: "mybets",
      title: "My Bets",
    },
    {
      link: "betProfitLoss",
      title: "Betting Profit and Loss",
    },
    {
      link: "accountStatement",
      title: "Account Statement",
    },
    {
      link: "transferStatement",
      title: "Transfer Statement",
    },
    {
      link: "security",
      title: "Security",
    },
    {
      link: "changePassword",
      title: "Change password",
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        margin: "0 auto",
        padding: "10px",
      }}
    >
        <Stack direction={"row"} spacing={0.2} alignItems={"normal"}>
          <CustomIcon name={"account"} fontSize={12} color={"#ffecc6"} />
          <LinkText onClick={() => setOpenMenu(true)}>
              <Typography variant="body2">Account</Typography>
          </LinkText>
        </Stack>
      <Box
        component={"ul"}
        className="dropdown"
        sx={{ visibility: openMenu ? "visible !important" : "hidden" }}
      >
        {menu.map((item, index) => {
          return (
            <Box
              key={index}
              component={"li"}
              onClick={() => handleClick(item.link)}
            >
              <Box className={"menu-item"}>{item.title}</Box>
            </Box>
          );
        })}
        {/* <Box component={"li"}>
                    <Box  className={"menu-item"}>
                        My Bets
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Betting Profit and Loss
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Account Statement
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Transfer Statement
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Security
                    </Box>
                </Box>
                <Box component={"li"}>
                    <Box className={"menu-item"}>
                        Change password
                    </Box>
                </Box> */}
      </Box>
    </Box>
  );
};

const SearchEvents = () => {
  const [active, setActive] = useState(false);
  const [query, setQuery] = useState("");
  const [events,setEvents] = useState([]);
  const navigate = useNavigate();

  const handleBlurr = () => {
    setTimeout(()=>{
      setActive(false);
      setQuery("");
    },300)
  };

  useEffect(()=>{
    if(query && query.length>2) onSearchEvents();
  },[query])

  const onSearchEvents = async()=>{
    try {
      const res = await searchEventByName(query);
      if(res){
        setEvents(res);
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  }

  const handleClick = (data) =>{
    console.log(data);
    navigate(`/sport/${data.provider_game_id}/${data.league_id}/${data.game_event_id}`)
  }

  return (
    <Box position={"relative"}>
      <Box
        sx={{
          zIndex: "1000",
          position: "absolute",
          top: active ? "-9px" : "50%",
          left: "34px",
          transform: "translateY(-50%)",
          fontSize: active ? "10px" : "12px",
          color: active ? "#fff" : "#666",
          transition: "top 0.3s ease, font-size 0.3s ease, color 0.5s ease",
          userSelect: "none",
          pointerEvents: "none",
        }}
      >
        Search Events
      </Box>
      <Box
        sx={{
          display: "inline-block",
          position: "relative",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            width: "30px",
            marginTop: "-8px",
            fontSize: "14px",
            textAlign: "center",
            pointerEvents: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Box sx={{ bgcolor: "#999999", width: 14, height: 14 }}></Box> */}
          <CustomIcon color={"#999999"} name={"search"} fontSize={14} />
        </Box>
        <Box
          component={"input"}
          onFocus={() => setActive(true)}
          onBlur={handleBlurr}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          sx={{
            height: "36px",
            padding: "8px 4px",
            fontSize: "12px",
            paddingLeft: "30px !important",
            width: "300px",
            border: "1px solid #DDDDDD",
            transition: "linear 0.2s",
            borderRadius: "3px",
            background: "#FFFFFF",
            color: "#666666",
            maxWidth: "100%",
            "&:focus": {
              borderColor: "#0f2327",
              background: "#fff",
              color: "#0f2327",
            },
            "&:focus-visible": {
              outline: "none",
            },
          }}
        ></Box>
      </Box>
      <Box
        component={"ul"}
        sx={{
          position: "absolute",
          background: "#fff",
          border: "1px solid #DDDDDD",
          borderTop: "none",
          width: "300px",
          zIndex: "999",
          display: query.length > 2?"block":"none",
          li: {
            paddingLeft: "0",
            cursor:"pointer",
            "&:hover":{
              backgroundColor: '#DDDDDD',
              textDecoration: 'none',
              color: '#24555e !important'
            },
            p: {
              margin: "0",
              lineHeight: "1.8",
              padding: "5px 10px",
              display: "block",
              width: "100%",
              color: "#0f2327",
              fontSize: "12px",
            },
          },
        }}
      >
        {
          events && events.length === 0 ? 
          <Box component={"li"}>
            <Box component={"p"}>No events found matching '{query}'</Box>
          </Box>
          :
          events && events.map((event)=>{
            return(
              <Box onClick={()=>handleClick(event)} component={"li"}>
                <Box component={"p"}>{event.event_name}</Box>
              </Box>
            )
          })
        }
      </Box>
    </Box>
  );
};
