

export default function Dialog(theme)
{
    return {
        MuiDialog:{
            styleOverrides:{
                paper:{
                    boxShadow:"none"
                },
            }
        },
        MuiBackdrop:{
            styleOverrides:{
                root:{
                    backgroundColor:"rgba(0, 0, 0, 0.8)"
                }
            }
        }
    }
}