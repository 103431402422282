import {
  Box,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BetButton from "../../../components/miniComponents/betButton";
import CustomIcon from "../../../components/customIcon";
import { useDispatch, useSelector } from "react-redux";
import { addFavorite, removeFavorite, removeFavoriteByProvider } from "../../../redux/reducers/favorites";
import { onUpdatedExposure, userExposureByMarket } from "../../../api/services/betServices";
import { isNegative } from "../../../utils/functions";

const OtherTable = ({ market, event,updateExposure=[],setUpdateExposure }) => {
  const { favorites } = useSelector(state =>state.favorites)
  const dispatch = useDispatch();
  const { isLogginIn,userData } = useSelector((state) => state.user);
  const [exposures, setExposures] = useState([]);

  const exposure = useSelector((state) => state.exposures.exposure);
  const isExpExist = Boolean(exposure.find((exp) => exp.market_id == market.provider_market_id))

  const isInFavorites = favorites.find((fav)=>fav.provider_market_id === market.provider_market_id)
  // console.log(market.market_id)

  const onAddFavorite = () =>{
    if(isLogginIn){

      if(favorites.find((fav)=>fav.provider_market_id === market.provider_market_id))
      {
          dispatch(removeFavoriteByProvider(market.provider_market_id))
      }
      else{
        dispatch(addFavorite({...market,game_name:event.game_name,game_event_id:market.event_id,event_name:event.event_name,event_time:event.event_time}));
      }
    }
  }


  const getExposureData = async (marketId) => {
    const response = await userExposureByMarket(marketId);
    if (response) {
      console.log(response);
      setExposures(response[0]);
    }
    onUpdateExposure()
  };

  const onUpdateExposure = async() =>{
    try {
        const req = {
            user_id:userData.userData,
            exposure_id:market.market_id,
        }
        await onUpdatedExposure(req);
        setUpdateExposure(updateExposure.filter((id)=>id!==market.market_id));

    } catch (error) {
        console.log(error)
    }
}

useEffect(() => {
  if(isLogginIn && market && exposures && exposures.length === 0){
    getExposureData(market.provider_market_id);
  }
}, [market,isLogginIn,exposures]);

useEffect(()=>{
  if(isLogginIn && updateExposure.includes(market.market_id)){
    getExposureData(market.provider_market_id);
  }
},[updateExposure,isLogginIn])

  const getOddYesExposure = () =>{
    const profit = exposure
          ?.filter((exp) => exp.market_id == market.provider_market_id)
          .reduce((profit, exp) => {
            profit += exp.runners.find(
              (run) => run.runnerId == `${market.provider_market_id}_odd`
            ).profit;
            return profit;
          }, 0);
          const exposureProfit = exposures?.runners && exposures?.runners[0]?.profit ? exposures?.runners[0]?.profit : null
          return (
            <>
            {!isNaN(
              exposureProfit
            ) && (
              <Typography
                variant="body2"
              >
                {
                  exposureProfit ? 
                  <>
                  <span className={
                  isNegative(exposureProfit)
                    ? "-negative"
                    : "-positive"
                }>{exposureProfit}</span>
                  {
                    !isNaN(profit) && isExpExist &&
                    <>
                      <span> » </span>
                      <span className={
                      isNegative(exposureProfit + profit)
                        ? "-negative"
                        : "-positive"
                    } >{`${(exposureProfit + profit).toFixed(2)}`} </span>
                    </>
                  }
                  </>
                  :
                  <span className={
                    isNegative(profit)
                      ? "-negative"
                      : "-positive"
                  }>{!isNaN(profit) && isExpExist && `${(profit).toFixed(2)}`} </span>
                }
                {/* {exposureProfit} {!isNaN(profit) && profit !== 0 && `» ${(exposureProfit + profit).toFixed(2)}`}  */}
              </Typography>
            )}
            </>
          );
  }
  const getEvenNoExposure = () =>{
    const profit = exposure
          ?.filter((exp) => exp.market_id == market.provider_market_id)
          .reduce((profit, exp) => {
            profit += exp.runners.find(
              (run) => run.runnerId == `${market.provider_market_id}_even`
            ).profit;
            return profit;
          }, 0);
          const exposureProfit = exposures?.runners && exposures?.runners[1]?.profit ? exposures?.runners[1]?.profit : null
          return (
            <>
            {!isNaN(
              exposureProfit
            ) && (
              <Typography
                variant="body2"
              >
                {
                  exposureProfit ? 
                  <>
                  <span className={
                  isNegative(exposureProfit)
                    ? "-negative"
                    : "-positive"
                }>{exposureProfit}</span>
                  {
                    !isNaN(profit) && isExpExist &&
                    <>
                      <span> » </span>
                      <span className={
                      isNegative(exposureProfit + profit)
                        ? "-negative"
                        : "-positive"
                    } >{`${(exposureProfit + profit).toFixed(2)}`} </span>
                    </>
                  }
                  </>
                  :
                  <span className={
                    isNegative(profit)
                      ? "-negative"
                      : "-positive"
                  }>{!isNaN(profit) && isExpExist && `${(profit).toFixed(2)}`} </span>
                }
                {/* {exposureProfit} {!isNaN(profit) && profit !== 0 && `» ${(exposureProfit + profit).toFixed(2)}`}  */}
              </Typography>
            )}
            </>
          );
  }

  return (
    <Table className="market-listing-table">
      <TableHead>
        <TableRow>
          <TableCell className="title">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack
                sx={{ flex: 1 }}
                direction={"row"}
                alignItems={"center"}
                spacing={"6px"}
              >
                <CustomIcon onClick={onAddFavorite} name={!isInFavorites?"favorite":"favorite-filled"} fontSize={14} color={"#e4c41e"} />
                <Box>&nbsp;{market && market.market_name}</Box>
              </Stack>
              <Box
                  sx={{
                    flex: 1,
                    display: "grid",
                    gridAutoFlow: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box className={"icon-box"}>
                    <CustomIcon name={"inplay"} fontSize={16} color={"primary.main"} popover={"Going in-play"} />
                  </Box>
                  <Box className={"icon-box"}>
                    <CustomIcon name={"0percent"} fontSize={16} color={"primary.main"} popover={"No commission"} />
                  </Box>
                  <Box className={"icon-box"}>
                    <CustomIcon name={"stopwatch-race"} fontSize={16} color={"primary.main"} popover={"Faster bet acceptance"} />
                  </Box>
                </Box>
            </Stack>
          </TableCell>
          <TableCell className="back" colSpan={2}></TableCell>
          <TableCell className="back"> Back </TableCell>
          <TableCell className="lay"> Lay </TableCell>
          <TableCell className="lay" colSpan={2}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* {market?.runners.map((runner) => { */}
        {/* return ( */}
        <TableRow>
          <TableCell className="event-row">
            <Box className="event-name">{market.category === "ODD_EVEN"?"Odd":'Yes'}</Box>
            {getOddYesExposure()}
          </TableCell>
          <TableCell className="back betting-disabled unhighlighted"></TableCell>
          <TableCell className="back betting-disabled unhighlighted"></TableCell>
          <BetButton
            event={event}
            market={{...market,market_id:market.provider_market_id,runners:[{runner_id:`${market.provider_market_id}_odd`},{runner_id:`${market.provider_market_id}_even`}]}}
            runner={{runner_id:`${market.provider_market_id}_odd`,runner_name:market.category === "ODD_EVEN"?"Odd":'Yes'}}
            odds={market.yes_rate}
            side={0}
            className={`back`}
            type={`back`}
          >
            <Box className={"bet-button-wrapper"}>
              <strong>{market.yes_rate}</strong>
              <Box className={"size"}>{market.yes_value}</Box>
            </Box>
          </BetButton>

          <BetButton
            event={event}
            market={market}
            odds={0}
            side={1}
            className={`lay`}
            type={`lay`}
          >
            <Box className={"bet-button-wrapper"}>
              {/* <strong>{market.no_rate}</strong> */}
              {/* <Box className={"size"}>{market.no_value}</Box> */}
            </Box>
          </BetButton>
          <TableCell className="lay betting-disabled unhighlighted"></TableCell>
          <TableCell className="lay betting-disabled unhighlighted"></TableCell>
        </TableRow>


        <TableRow>
          <TableCell className="event-row">
            <Box className="event-name">{market.category === "ODD_EVEN"?"Even":'No'}</Box>
            {getEvenNoExposure()}
          </TableCell>
          <TableCell className="back betting-disabled unhighlighted"></TableCell>
          <TableCell className="back betting-disabled unhighlighted"></TableCell>
          <BetButton
            event={event}
            market={{...market,market_id:market.provider_market_id,runners:[{runner_id:`${market.provider_market_id}_odd`},{runner_id:`${market.provider_market_id}_even`}]}}
            runner={{runner_id:`${market.provider_market_id}_even`,runner_name:market.category === "ODD_EVEN"?"Even":'No'}}
            odds={market.no_rate}
            side={0}
            className={`back`}
            type={`back`}
          >
            <Box className={"bet-button-wrapper"}>
              <strong>{market.no_rate}</strong>
              <Box className={"size"}>{market.no_value}</Box>
            </Box>
          </BetButton>

          <BetButton
            event={event}
            market={market}
            odds={0}
            side={1}
            className={`lay`}
            type={`lay`}
          >
            <Box className={"bet-button-wrapper"}>
              {/* <strong>{market.no_rate}</strong> */}
              {/* <Box className={"size"}>{market.no_value}</Box> */}
            </Box>
          </BetButton>
          <TableCell className="lay betting-disabled unhighlighted"></TableCell>
          <TableCell className="lay betting-disabled unhighlighted"></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default OtherTable;
