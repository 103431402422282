// sports.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedSport: null,
    sportsData: []
}

const sportsSlice = createSlice({
    name: "sports",
    initialState: initialState,
    reducers: {
        selectSport: (state, action) => {
            state.selectedSport = action.payload;
        },
        updateSportsData: (state, action) => {
            state.sportsData = action.payload;
        },
        clearSports: () => initialState
    }
});

export const { selectSport, updateSportsData, clearSports } = sportsSlice.actions;

export default sportsSlice.reducer;