import { Box } from '@mui/material';
import React from 'react';

export default function CustomIcon({name,fontSize,color,onClick=()=>{},className="",popover,cursor}){
    const icons = [
        {
            title:"uparrow-filled",
            code:'"\\f0de"'
        },
        {
            title:"downarrow-filled",
            code:'"\\f0dd"'
        },
        {
            title:"x",
            code:'"\\e93e"'
        },
        {
            title:"info",
            code:'"\\e935"'
        },
        {
            title:"tick",
            code:'"\\f00c"'
        },
        {
            title:"18plus",
            code:'"\\e942"'
        },
        {
            title:"earth-lock",
            code:'"\\e92f"'
        },
        {
            title:"live",
            code:'"\\a00c"'
        },
        {
            title:"heart",
            code:'"\\f004"'
        },
        // {
        //     title:"video-tv",
        //     code:'"\\e905"'
        // },
        {
            title:"inplay",
            code:'"\\a006"'
        },
        {
            title:"inplay-o",
            code:'"\\a008"'
        },
        {
            title:"match-stats",
            code:'"\\a010"'
        },
        {
            title:"clock",
            code:'"\\a014"'
        },
        {
            title:"ladder",
            code:'"\\a015"'
        },
        {
            title:"32223",
            code:'"\\e900"'
        },
        {
            title:"Inplay",
            code:'"\\e901"'
        },
        {
            title:"video-tv",
            code:'"\\e911"'
        },
        {
            title:"info-sign",
            code:'"\\e913"'
        },
        {
            title:"popular",
            code:'"\\e915"'
        },
        {
            title:"my_market",
            code:'"\\e96c"'
        },
        {
            title:"chevron-down",
            code:'"\\e96f"'
        },
        {
            title:"chevron-up",
            code:'"\\e918"'
        },
        {
            title:"stopwatch",
            code:'"\\e952"'
        },
        {
            title:"bet-builder",
            code:'"\\a002"'
        },
        {
            title:"heart",
            code:'"\\f004"'
        },
        {
            title:"user",
            code:'"\\f007"'
        },
        {
            title:"remove",
            code:'"\\f00d"'
        },
        {
            title:"time",
            code:'"\\f017"'
        },
        {
            title:"signout",
            code:'"\\f08b"'
        },
        {
            title:"repeat",
            code:'"\\f01e"'
        },
        {
            title:"password",
            code:'"\\f023"'
        },
        {
            title:"open_bets",
            code:'"\\f03a"'
        },
        {
            title:"pnl",
            code:'"\\f0d6"'
        },
        {
            title:"pnl2",
            code:'"\\f0d7"'
        },
        {
            title:"setting",
            code:'"\\f140"'
        },
        {
            title:"plus-square-o",
            code:'"\\f196"'
        },
        {
            title:"balance",
            code:'"\\f19c"'
        },
        {
            title:"settings2",
            code:'"\\f141"'
        },
        {
            title:"gamecare",
            code:'"\\e934"'
        },
        {
            title:"openbets",
            code:'"\\e92c"'
        },
        {
            title:"transferstatement",
            code:'"\\f02e"'
        },
        {
            title:"lock",
            code:'"\\e95f"'
        },
        {
            title:"rules",
            code:'"\\e90d"'
        },
        {
            title:"signout",
            code:'"\\f08c"'
        },
        {
            title:"search",
            code:'"\\f002"'
        },
        {
            title:"menuicon",
            code:'"\\e91e"'
        },
        {
            title:"0percent",
            code:'"\\e941"'
        },
        {
            title:"stopwatch-race",
            code:'"\\e949"'
        },
        {
            title:"right-arrow",
            code:'"\\f054"'
        },
        {
            title:"back-arrow",
            code:'"\\e92e"'
        },
        {
            title:"modal-close",
            code:'"\\e937"'
        },
        {
            title:"loading",
            code:'"\\e939"'
        },

        //sports
        {
            title:"tabletennis",
            code:'"\\e90f"'
        },
        {
            title:"esports",
            code:'"\\e968"'
        },
        {
            title:"greyhound racing",
            code:'"\\e976"'
        },
        {
            title:"horse racing",
            code:'"\\e977"'
        },
        {
            title:"cricket",
            code:'"\\e967"'
        },
        {
            title:"football",
            code:'"\\e969"'
        },
        {
            title:"tennis",
            code:'"\\e979"'
        },

        //left-menu icon
        {
            title:"home",
            code:'"\\e97a"'
        },
        {
            title:"favorite",
            code:'"\\e917"'
        },
        {
            title:"favorite-filled",
            code:'"\\e992"'
        },
        {
            title:"mymarkets",
            code:'"\\e96c"'
        },
        {
            title:"livecasino",
            code:'"\\e981"'
        },
        {
            title:"indiancasino",
            code:'"\\e97f"'
        },
        {
            title:"slots",
            code:'"\\e956"'
        },
        {
            title:"genie",
            code:'"\\e96f"'
        },

        //casino icons
        {
            title:"all",
            code:'"\\e970"'
        },
        {
            title:"roulette",
            code:'"\\e989"'
        },
        {
            title:"baccarat",
            code:'"\\e90b"'
        },
        {
            title:"game shows",
            code:'"\\e91a"'
        },
        {
            title:"dragon tiger",
            code:'"\\e90e"'
        },
        {
            title:"blackjack",
            code:'"\\e972"'
        },
        {
            title:"poker",
            code:'"\\e987"'
        },
        {
            title:"teen patti",
            code:'"\\e98e"'
        },
        {
            title:"indian games",
            code:'"\\e97c"'
        },
        {
            title:"slots",
            code:'"\\e98c"'
        },
        {
            title:"instant games",
            code:'"\\e980"'
        },
        {
            title:"exchange",
            code:'"\\e974"'
        },
        {
            title:"casino lobby",
            code:'"\\e90c"'        
        },
        {
            title:"other",
            code:'"\\e985"'        
        },

        // login
        {
            title:"eye-open",
            code:"'\\e963'"
        },
        {
            title:"eye-close",
            code:"'\\e962'"
        },
        {
            title:"login-icon",
            code:"'\\e982'"
        },
        {
            title:"logout-icon",
            code:"'\\e983'"
        },

        // header
        {
            title:"account",
            code:'"\\e901"'
        },
        {
            title:"sm-down-arrow",
            code:'"\\f107"'
        },
        {
            title:"sm-up-arrow",
            code:'"\\f106"'
        }
    ]
    return(
        <Box onClick={onClick} className={className} component={"icon"} sx={{cursor,verticalAlign:"middle",fontSize:fontSize ? fontSize :"20px",":before":{content:icons?.filter((icon)=>icon?.title===name)[0]?.code,fontSize:fontSize ? fontSize :"20px",fontFamily:"icomoon",color: color ? color: ""}}}>
            {popover && 
                <Box className={"popover"}>
                    {popover}
                </Box>
            }
        </Box>
    )
}