import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "../components/header";
import SportMenu from "../components/sportMenu";
import Footer from "../components/footer";
import NotificationMarquee from "../components/miniComponents/notificationMarquee";
import { useSelector } from "react-redux";

export default function MainLayout() {
  const { isLogginIn } = useSelector((state) => state.user);


  return (
    <>
      {isLogginIn && <NotificationMarquee />}
      
        <Header />
        <SportMenu />
        <Box
          sx={{
            minHeight: 420,
            width: "100%",
            height: "calc(100% - 114px)",
            position: "relative",
            margin: "auto",
            overflow: "hidden",
            display: "table",
            zIndex: 1,
            padding: 0,
          }}
        >
          <Outlet />
        </Box>
        <Footer />
    </>
  );
}
