import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { sports } from "../../../../config/data";

import { useParams } from "react-router-dom";
import { UpdateRounded } from "@mui/icons-material";
import CustomIcon from "../../../../components/customIcon";

const EventCategoryTabButtons = ({ currentCategory, tabData, handleClick }) => {
  const { sportId } = useParams();

  const [bgColor, setBgColor] = useState("");
  const [hoverColor, setHoverColor] = useState("");

  React.useEffect(() => {
    const getSportById = sports.filter((sport) => sport.id === sportId);
    setBgColor(getSportById[0]?.bgcolor);
    setHoverColor(getSportById[0]?.hoverColor);
  }, [bgColor, hoverColor, sportId]);

  return (
    <>
      {tabData && (
        <>
          <Stack
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(11, 1fr)",
              gridGap: 4,
            }}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                background: currentCategory === 0 ? hoverColor : bgColor,
                color: "white",
                border: `1px solid`,
                my: 0,
                p: 0.5,
                borderRadius: 1,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: hoverColor,
                },
              }}
              onClick={() => {
                handleClick(0);
              }}
            >
              {/* <CasinoOutlined /> */}
              <CustomIcon name={'all'} />
              <Typography fontSize={12}>All</Typography>
            </Stack>
            {tabData.map((data, i) => {
              let icon;

              switch (data.game_name) {
                case "Dragon Tiger":
                  icon = <UpdateRounded />;
                  break;
                case "Blackjack":
                  icon = <UpdateRounded />;
                  break;
                case "Roulette":
                  icon = <UpdateRounded />;
                  break;
                case "Baccarat":
                  icon = <UpdateRounded />;
                  break;
                case "Poker":
                  icon = <UpdateRounded />;
                  break;
                case "Teen Patti":
                  icon = <UpdateRounded />;
                  break;
                case "Game Shows":
                  icon = <UpdateRounded />;
                  break;
                case "Indian Games":
                  icon = <UpdateRounded />;
                  break;
                case "Slots":
                  icon = <UpdateRounded />;
                  break;
                case "Exchange":
                  icon = <UpdateRounded />;
                  break;
                case "Instant Games":
                  icon = <UpdateRounded />;
                  break;
                case "Casino Lobby":
                  icon = <UpdateRounded />;
                  break;
                case "Other":
                  icon = <UpdateRounded />;
                  break;
                default:
                  break;
              }

              return (
                <Stack
                  key={data.game_id}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    background:
                      currentCategory === data.game_id ? hoverColor : bgColor,
                    color: "white",
                    border: `1px solid`,
                    my: 0,
                    p: 0.5,
                    borderRadius: 1,
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: hoverColor, // Darken the background color on hover
                    },
                  }}
                  onClick={(e) => {
                    handleClick(data.game_id);
                  }}
                >
                  <CustomIcon name={data?.game_name?.toLowerCase()} />
                  <Typography fontSize={12}>{data.game_name}</Typography>
                </Stack>
              );
            })}
          </Stack>
        </>
      )}
    </>
  );
};

export default EventCategoryTabButtons;
