import { Box, Button, Dialog, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { login } from "../../redux/reducers/user";

import { login as userLogin } from "../../api/services/userAuthService";
import { useState } from "react";

import socketServices from "../../sockets/socketService";
import CustomIcon from "../customIcon";

export default function LoginModal({ open, onClose = () => {} }) {
  const [showError, setShowError] = useState(false);
  const [showPass,setShowPass] = useState(false);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
  });

  const handleInputChange = (e) => {
    // alert('Changed')
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async () => {
    try {
      const response = await userLogin(formData.user_name, formData.password);
      if (response) {
        dispatch(login(response.data));
        onClose();
      } else {
        setShowError(true);
        return;
      }
    } catch (error) {
      setShowError(true);
      console.error("Login failed:", error);
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          width: "375px",
          height: "345px",
          position: "relative",
          padding: "30px",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            background: `url(/assets/images/logo.png) no-repeat center center`,
            backgroundSize: "contain",
            width: "100%",
            height: "60px",
            // mt: "20px",
          }}
        ></Box>
        <Box mt={"40px"}>
          <input
            name="user_name"
            onChange={(e) => handleInputChange(e)}
            className="login-input"
            value={formData.user_name}
            placeholder="User Name"
          />
          <Box position={"relative"}>
            <input
              name="password"
              onChange={(e) => handleInputChange(e)}
              className="login-input"
              type={`${showPass?"text":"password"}`}
              value={formData.password}
              placeholder="Password"
            />
            <Box className={"show-password"}>
              {/* <Box
                sx={{
                  width: 23,
                  height: 20,
                  bgcolor: "#9b9b9b",
                  alignSelf: "center",
                }}
              ></Box> */}
              <CustomIcon onClick={()=>setShowPass(!showPass)} name={showPass?"eye-open":"eye-close"} />
            </Box>
          </Box>
          <Button onClick={handleLogin} variant="login">
            Login
          </Button>
          {showError && (
            <Typography color={"error"} align="center" mt={2}>
              Login failed. Please try again
            </Typography>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
