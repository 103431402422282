

export default function Input(theme)
{
    return{
        MuiTextField:{
            styleOverrides:{
                root:{
                    "&.small":{
                        "& .MuiOutlinedInput-root":{
                            height: '23px',
                            fontSize: '10px'
                        },
                        "& input":{
                            padding: '3px 2px',
                        }
                    }
                }
            }
        },
        MuiOutlinedInput:{
            styleOverrides:{
                root:{
                    borderRadius:0,
                    fontSize:12,
                    height:27,
                    "& fieldset":{
                        display:"none"
                    },

                    "& input":{
                        padding:"4px 6px",
                        border:"2px solid #DDDDDD",
                        background:"#fff",
                        color:"#666666",
                        transition:"linear 0.2s",
                        maxWidth:"100%",
                        borderRadius:3,
                        "&:focus":{
                            color:"#0f2327",
                            borderColor:"#0f2327"
                        }
                    },

                    
                },
            }
        }
    }
}