import { Box, Stack, Typography } from "@mui/material";


export default function Footer()
{
    return(
        <Box
            sx={{
                textAlign: "center",
                bgcolor: "primary.main",
                color: "#fff",
                lineHeight: "20px",
                padding: "21px 20px",
                marginTop: "0"
            }}
        >
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={"20px"} px={"10px"} width={"fit-content"} margin={"auto"} >
                <Box sx={{height:32,width:32}} />
                <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
                    <Box sx={{height:32,width:32}} />
                    <Typography variant="body2">Underage gambling is an offence</Typography>
                </Stack>
                <Stack direction={"row"} spacing={"10px"} alignItems={"center"}>
                    <Box sx={{height:32,width:32}} />
                    <Typography variant="body2">Restricted territories</Typography>
                </Stack>
                <FooterLinks text={"KYC"}  />
                <FooterLinks text={"Rules & Regulations"}  />
                <FooterLinks text={"Responsible Gambling"}  />
                <FooterLinks text={"Exclusion Policy"}  />
                <Typography variant="body2">@ 2016-2023</Typography>
            </Stack>
        </Box>
    )
}

const FooterLinks = ({text}) =>{
    return(
        <Typography
        variant="body2"
        sx={{
            cursor:"pointer",
            ":hover":{
                textDecoration:"underline",
                color:"#e6e6e6"
            }
        }}

        >
            {text}
        </Typography>
    )
}