import { TableCell } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addBet, updateOdds } from "../../redux/reducers/bets";
import { useContext, useEffect, useState } from "react";
import { BetSlipContext } from "../../context/bet-slip-context";
import { useParams } from "react-router-dom";
import { LoginContext } from "../../context/login-context";

export default function BetButton(props) {
  const {changeTab} = useContext(BetSlipContext)
  const { bets } = useSelector((state) => state.bets);
  const { isLogginIn, userData,settings } = useSelector((state) => state.user);
  const { one_click_stake,user_id,is_one_click_bet_enable } = userData || {};
  const {active_one_click_stake} = settings;
  const {setLogin} =  useContext(LoginContext)
  const {compId,sportId} = useParams();


  const dispatch = useDispatch();
  const generateBetSlip = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(props)
    if (isLogginIn && props.event && props.market && props.runner && props.odds) {
      changeTab(0);
      const bet = bets.find(
        (bet) =>
          bet.betId ===
          `${props?.event?.game_event_id}-${props?.market?.market_id}-${props?.runner?.runner_name}-${props?.side}`
      )
      if (
        !bet
      ) {
        console.log(props)
        const data = {
          betDelay: 5,
          book: [],
          event_id: props?.event.game_event_id,
          provider_event_id: props?.event.provider_event_id,
          event_name: props?.event?.event_name,
          game_name: props?.event?.game_name,
          league_name: props?.event?.league_name,
          provider_league_id: props?.event?.provider_league_id,
          market_id: props?.market?.market_id,
          provider_market_id: props?.market?.provider_market_id,
          member_code: userData.member_code,
          provider_game_id: props?.event?.provider_game_id || sportId || "4",
          market_name: props?.market?.market_name,
          market_type: props?.market?.market_type,
          runner_name: props.runner.runner_name,
          runner_id: props.runner.runner_id,
          runners: props.market.runners,
          bettingTime: 1,
          isWeak: false,
          line: props.line,
          fromOneClick: is_one_click_bet_enable ? 1:0,
          is_fancy: props.market.market_type === "fancy",
          fancy_category: props.market.category,
          is_fractional_odds: props.market.market_type === "match_odd",
          totalSize: 124.36,
          priceIndex: 0,
          ip_address: "192.168.0.1",
          sport_id: props.event.game_id,
          odds: props.odds,
          // odds: 5,
          side: props.side,
          type: props.type,
          preMatch:props.event.provider_status === "UPCOMING",
          betId: `${props?.event?.game_event_id}-${props.market.market_id}-${props.runner.runner_name}-${props.side}`,
          liability:0,
          bet_amount:is_one_click_bet_enable ? parseInt(one_click_stake[active_one_click_stake]) :0
        };
        dispatch(addBet(data));
      } else {
        if(props.odds !== bet.odds) {
          dispatch(
            updateOdds({ betId: bet.betId, odds: props.odds })
          );
        }
        else{
          console.log("already added");
        }
      }
    }
    else if(!isLogginIn){
      setLogin(true)
    }
  };

  const [green, setGreen] = useState(false);
  const [red, setRed] = useState(false);
  const [odd, setOdd] = useState(props.odds);

  useEffect(() => {
    if (props.odds) {
      if (odd > props.odds) {
        setGreen(true);
        setTimeout(() => {
          setGreen(false);
        }, 250);
      }

      if (odd < props.odds) {
        setRed(true);
        setTimeout(() => {
          setRed(false);
        }, 250);
      }
    }
  }, [props.odds]);

  return (
    <TableCell
      sx={{
        position: "relative",
        overflow: "hidden",
        "&::after": {
          display: !red && "none",
          content: '""',
          position: "absolute",
          bottom: "-10%",
          left: "-10%",
          background: "red",
          width: "16px",
          height: "16px",
          borderRadius: "50px",
        },
        "&::before": {
          display: !green && "none",
          content: '""',
          position: "absolute",
          top: "-10%",
          right: "-10%",
          background: "green",
          width: "16px",
          height: "16px",
          borderRadius: "50px",
        },
      }}
      onClick={generateBetSlip}
      {...props}
    >
      {props.children}
    </TableCell>
  );
}
