import { useParams } from "react-router-dom";
import RaceHeader from "./components/raceHeader";
import RaceMarket from "./components/raceMarket";
import UpcomingRaces from "./components/upcomingRaces";
import { useContext, useEffect, useState } from "react";
import { getRacesRunnersBySport } from "../../api/services/eventsService";
import { Racescontext } from "../../context/races-context";


export default function Races()
{

    const {marketId,sportId,eventId} = useParams(); 
    const {races} = useContext(Racescontext)

    const [currentRace,setCurrentRace] = useState();
    const [upcomingRaces,setUpcomingRaces] = useState();

    const currentMarketId = marketId || races[0]?.provider_market_id

    useEffect(()=>{
        if(currentRace?.provider_market_id !== currentMarketId){
            if(marketId,eventId){
                getRace(eventId,marketId)
            }
            else if(races[0]){
                getRace(races[0].game_event_id,races[0].market_id)
            }
        }
    }, [marketId,eventId,races[0]])
    



    const getRace = async(event_id,market_id)=>{
        const res = await getRacesRunnersBySport(event_id,market_id);
        console.log(res);
        if(res){
            if(res.market_details){
                setCurrentRace(res.market_details)
            }
            else{
                setCurrentRace(null)
            }
            if(res.market_list){
                setUpcomingRaces(res.market_list)
            }
        }
    }

    return(
        <>
            <RaceHeader race={currentRace} />
            <RaceMarket market={currentRace} event={currentRace} sportId={sportId} />
            <UpcomingRaces races={upcomingRaces} />
        </>
    )
}