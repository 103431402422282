import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import moment from "moment";

import { getUserTransferStatement } from "../../api/services/transferService";
import { useEffect, useState } from "react";
import { newGroupBy } from "../../utils/functions";

const tableHeader = ["Date", "Description", "Amount"];

export default function TransferStatement() {
  const [tableData, setTableData] = useState([]);
  const [transferDates, setTransferDates] = useState([]);

  const newGroupBy = (array, key, commonKeys = []) => {
    // First, group by the key
    const grouped = array.reduce((result, obj) => {
      (result[moment.unix(obj.items.created_at).format("DD/MM/YYYY")] =
        result[moment.unix(obj.items.created_at).format("DD/MM/YYYY")] ||
        []).push(obj.items);
      return result;
    }, {});

    // Then, convert the object back into an array
    return Object.entries(grouped).map(([keyValue, data]) => {
      const commonFields = {};
      commonKeys.forEach((commonKey) => {
        if (data.length > 0 && data[0].hasOwnProperty(commonKey)) {
          commonFields[commonKey] = data[0][commonKey];
        }
      });

      return {
        [key]: keyValue,
        ...commonFields,
        data,
      };
    });
  };

  console.log(tableData)
  
  useEffect(() => {
    const getUserTransferStatementFunc = async () => {
      try {
        const response = await getUserTransferStatement();
        console.info(response)
        if(response && response.length > 0){
          const newTableData = newGroupBy(response, "created_at");
          setTableData(newTableData);
        }
      } catch (error) {
        console.error("Error Fetching User Transfer Statement", error);
      }
    };

    getUserTransferStatementFunc();
  }, []);

  return (
      tableData && tableData.length > 0 ?
      <>
      
        <TableContainer>
          <Table>
            <TableHead sx={{ bgcolor: "#DDDDDD" }}>
              <TableRow>
                {tableHeader.map((th) => (
                  <TableCell
                    sx={{
                      px: "20px",
                      py: "8px",
                      textAlign: th === "Amount" && "right",
                    }}
                    key={th}
                  >
                    {th}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((td) => (
                <>
                  <TableRow sx={{ bgcolor: "#F9F9F9" }}>
                    <TableCell sx={{ px: "30px", py: "8px" }}>
                      {td.created_at}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {td.data.map((tdData, index) => (
                    <TableRow sx={{bgcolor: index % 2 === 0 ? '#fff' : '#F0F0F0'}}>
                      <TableCell sx={{ px: "45px", py: "8px", color: '#909090' }}>{moment.unix(tdData.created_at).format('HH:mm:ss')}</TableCell>
                      <TableCell sx={{ px: "20px", py: "8px" }}>{tdData.description}</TableCell>
                      <TableCell sx={{ px: "20px", py: "8px", textAlign: 'right', color: tdData.amount < 0 ? 'red' : 'green', fontWeight: 600 }}>{tdData.amount}</TableCell>
                    </TableRow>
                  ))}
                </>
              ))}
              {/* Add more rows as needed */}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      :
      <Box>
          <Typography variant="body2">There have been no transfers in the last 180 days.</Typography>
      </Box>
  );
}
