import { useRoutes } from "react-router-dom";
import MainLayout from "./layout/mainLayout";
import Dashboard from "./pages/dashboard/dashboard";
import Home from "./pages/home/home";
import MenuLayout from "./layout/menuLayout";
import SportPage from "./pages/sports/sports";
import EventDetail from "./pages/eventDetail/eventDetail";
import Races from "./pages/races/races";
import MyBets from "./pages/myBets/myBets";
import BettingProfitLoss from "./pages/bettingProfitLoss/bettingProfitLoss";
import AccountStatement from "./pages/accountStatement/accountStatement";
import TransferStatement from "./pages/transferStatement/transferStatement";
import Security from "./pages/security/security";
import ChangePassword from "./pages/changePassword.js/changePassword";
import Favorites from "./pages/favorites/favorites";


export default function Router()
{
    return useRoutes([
        {
            path:"",
            element:<MainLayout />,
            children:[
                {
                    path:"",
                    element:<Dashboard />
                },
                {
                    path:"home",
                    index:true,
                    element:<Home />
                },
                {
                    path:"account",
                    element:<MenuLayout />,
                    children:[
                        {
                            path:"mybets",
                            element:<MyBets />
                        },
                        {
                            path:"betProfitLoss",
                            element:<BettingProfitLoss />
                        },
                        {
                            path:"accountStatement",
                            element:<AccountStatement />
                        },
                        {
                            path:"transferStatement",
                            element:<TransferStatement />
                        },
                        {
                            path:"security",
                            element:<Security />
                        },
                        {
                            path:"changePassword",
                            element:<ChangePassword />
                        },
                    ]
                },
                {
                    path:"favorite",
                    element:<MenuLayout />,
                    children:[
                        {
                            path:"",
                            element:<Favorites />
                        },
                    ]
                },
                {
                    path:"sport",
                    element:<MenuLayout />,
                    children:[
                        {
                            path:":sportId",
                            element:<SportPage />
                        },
                        {
                            path:":sportId/:compId",
                            element:<SportPage />
                        },
                        {
                            path:"",
                            element:<SportPage />
                        },
                        {
                            path:":sportId/:compId/:eventId",
                            element:<EventDetail />
                        },
                        {
                            path:":sportId/:eventId/:marketId/race",
                            element:<Races />
                        },
                    ]
                },
                {
                    path:"racing",
                    element:<MenuLayout />,
                    children:[
                        {
                            path:":sportId",
                            element:<SportPage />
                        },
                        {
                            path:":sportId/:compId",
                            element:<SportPage />
                        },
                        {
                            path:"",
                            element:<SportPage />
                        },
                        {
                            path:":sportId/:compId/:eventId",
                            element:<EventDetail />
                        },
                        {
                            path:":sportId/:eventId/:marketId/race",
                            element:<Races />
                        },
                    ]
                }
            ]
        },
    ]);
}