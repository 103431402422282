// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { onSnapshot, collection, getFirestore, doc } from 'firebase/firestore'
import { FIREBASE_COLLECTION, handleCatch, handleResponse } from './functions'
import { instance } from '../api/services/apiService'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_PROJECTID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app)
export const subscribeToFirestoreCollection = (collectionPath, callback) => {
  const unsubscribe = onSnapshot(collection(db, collectionPath), async querySnapshot => {
    try {
      const fetchedData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      callback(fetchedData)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  })
  return unsubscribe
}
export const subscribeUserCollection = (userId, callback) => {
  console.log({userId},"userId")
  const unsubscribe = onSnapshot(doc(db, FIREBASE_COLLECTION.USER_BALANCE, userId), async querySnapshot => {
    try {
      if (querySnapshot.exists()) {
        // const documentData = {
        //   id: querySnapshot.id,
        //   ...querySnapshot.data()
        // };
        // callback(documentData)
        callback(querySnapshot.data())
      } else {
        // Document does not exist
        callback(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  })
  return unsubscribe
}
export const removeMatch = async (userId, betId) => {
  instance.post(`api/remove-match`, {
    userId,
    betId
  })
    .then((res) => handleResponse(res.data))
    .catch(handleCatch)
}