import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Stack } from "@mui/material";
import moment, { min } from "moment";
import { useEffect, useState } from "react";
import ScoreBoard from "./scoreBoard";
import CustomIcon from "../../../components/customIcon";

export default function EventHeader({ data }) {
  const [isLive, setIsLive] = useState(() =>
    data?.provider_status === "IN_PLAY" ? true : false
  );


  const [scoreAndLiveAvailable, setScoreAndLiveAvailable] = useState(() => {
    return {
      is_live_score: data?.is_live_score,
      is_live_stream: data?.is_live_stream,
      is_score_available: data?.is_score_available,
      is_stream_available: data?.is_stream_available,
    };
  });
  const [showScoreBoard, setShowScoreBoard] = useState(false);

  // console.log({ isLive }, data, scoreAndLiveAvailable);

  const timeDifferenceFromNow = (unixTimeStamp) => {
    let timeStampNow = moment.unix(data.event_time);
    let now = moment();

    let diff = moment.duration(timeStampNow.diff(now));

    let days = diff.days();
    let hours = diff.hours();
    let mins = diff.minutes();

    return ` Opens in ${days}day ${hours}hrs ${mins}mins`;
  };

  return (
    <Box mb={"10px"} sx={{ borderBottom: "1px solid #ddd" }}>
      <Box
        sx={{
          bgcolor: "primary.main",
          color: "white",
        }}
      >
        <Stack direction={"row"} alignItems={"center"} className="title-box">
          {/* <Box sx={{ width: 16, height: 16, bgcolor: "white", ml: "10px" }} /> */}
          <CustomIcon name={data?.game_name} fontSize={16} color={"#fff"}/>
          <Box>
            <Box className={"event-name"}>{data && data.event_name}</Box>
            <Box className={"comp-name"}>{data && data.league_name}</Box>
          </Box>
          {!isLive ? (
            <Box className={"event-datetime"}>
              {data &&
                moment.unix(data.event_time).format("DD/MM/YYYY HH:mm:ss")}
            </Box>
          ) : (
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => setShowScoreBoard(!showScoreBoard)}
              className={"event-datetime"}
            >
              {showScoreBoard ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Box>
          )}
        </Stack>
        {isLive? (
          <ScoreBoard
            data={data}
            scoreAndLiveAvailable={scoreAndLiveAvailable}
          />
        ) : (
          <Box className={"event-tabs"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              className={"start-time"}
            >
              {/* <Box
                sx={{ width: 12, height: 12, bgcolor: "white", mr: "5px" }}
              /> */}
              {
                data.provider_status === "UPCOMING" &&
                <>
                  <CustomIcon name={"clock"} fontSize={12} color={"#fff"} />
                  &nbsp;{timeDifferenceFromNow(data.event_time)}
                </>
              }
            </Stack>
            <Box className="rule-box">
              <Stack direction={"row"} alignItems={"center"} spacing={0.75}>
                {/* <Box
                  sx={{ width: 16, height: 16, bgcolor: "white", mr: "6px" }}
                /> */}
                <CustomIcon name="info" fontSize={16} color={"#fff"} />
                <Box>Rules</Box>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
