import {
  Box,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BetButton from "../../../components/miniComponents/betButton";
import socketServices from "../../../sockets/socketService";
import { getFancyMinMaxByMarketId } from "../../../api/services/eventsService";
import CustomIcon from "../../../components/customIcon";

const Fancy = ({ market, event,exposure,openLadder,setOpenLadder }) => {
  const [suspended, setSuspended] = useState(false);
  const [ballRunning, setBallRunning] = useState(false);
  const [maxBetAndMktData, setFetchMaxBetAndMktData] = useState({});
  const isLadderOpen = Boolean(openLadder === market.provider_market_id)

  const handleMatchOddsSocketData = (data) => {
    // setOdds(data.data);
    // console.log(market.provider_status);
    switch (market.provider_status) {
      case "SUSPENDED":
        setSuspended(true);
        break;
      case "BALL_RUNNING":
        setBallRunning(true);
        break;
      default:
        setSuspended(false);
        setBallRunning(false);
    }
  };

  const fetchMaxBetAndMktData = async (market_id) => {
    const response = await getFancyMinMaxByMarketId(market_id);
    if (response) {
      setFetchMaxBetAndMktData(response);
    }
  };

  useEffect(() => {
    if(market.provider_market_id){
      fetchMaxBetAndMktData(market.provider_market_id);
    }
  }, [market.provider_market_id]);

  useEffect(() => {
    handleMatchOddsSocketData();
  }, [market.provider_status]);

  // console.log(exposure)

  const handleLadderClick =() =>{
    if(exposure){
      if(openLadder === market.provider_market_id){
        setOpenLadder(null);
      }
      else{
        setOpenLadder(market.provider_market_id)
      }
    }
  }

  return (
    <Box sx={{marginBottom:1}}>
    <Table className="market-listing-table" sx={{marginBottom:"0 !important"}}>
      <TableHead>
        <TableRow>
          <TableCell className="back"></TableCell>
          <TableCell className="back"></TableCell>
          <TableCell className="back"></TableCell>
          <TableCell className="back"></TableCell>
          <TableCell className="lay"></TableCell>
          <TableCell className="lay"></TableCell>
          <TableCell className="lay"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* {market?.map((runner) => { */}
        {/* return ( */}
        <TableRow>
          <TableCell className="event-row">
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box>
                <Box className="event-name" >{market.market_name}</Box>
                {
                  exposure &&
                <Box className="event-name" lineHeight={"8px !important"}>Max Exposure: <span className="-negative">{
                  exposure?.runners.reduce((res,curr)=>{
                    if(curr.profit>res) return res;
                    return curr.profit
                  },0)

                }</span>	</Box>
                }
              </Box>
              {/* <Box sx={{ width: 14, height: 14, bgcolor: "#000" }}></Box> */}
              <CustomIcon onClick={handleLadderClick} cursor={exposure?"pointer":""} name={"ladder"} fontSize={14} color={exposure ? isLadderOpen ?"#344e6b":"#000":"#a29ca2"} />
            </Stack>
          </TableCell>

          <TableCell
            colSpan={6}
            className={`${
              suspended || market.provider_status === "SUSPENDED"
                ? "-status suspended"
                : "inner-table-container"
            }`}
          >
            {suspended || market.provider_status === "SUSPENDED" ? (
              <Box className="status-label">SUSPENDED</Box>
            ) : (
              <>
                <Table className="inner-table">
                  <TableBody>
                    <TableRow>
                      <TableCell className="lay betting-disabled unhighlighted"></TableCell>
                      <TableCell className="lay betting-disabled unhighlighted"></TableCell>
                      <BetButton
                        line={market.no_value}
                        event={event}
                        market={market}
                        runner={{runner_id:market.provider_market_id,runner_name:(market.no_value).toString()}}
                        odds={market.no_rate}
                        side={1}
                        className={`lay`}
                        type={`lay`}
                      >
                        <Box className={"bet-button-wrapper"}>
                          <strong>{market.no_value}</strong>
                          <Box className={"size"}>{market.no_rate}</Box>
                        </Box>
                      </BetButton>

                      <BetButton
                        line={market.yes_value}
                        event={event}
                        market={market}
                        runner={{runner_id:market.provider_market_id,runner_name:(market.yes_value).toString()}}
                        odds={market.yes_rate}
                        side={0}
                        className={`back`}
                        type={`back`}
                      >
                        <Box className={"bet-button-wrapper"}>
                          <strong>{market.yes_value}</strong>
                          <Box className={"size"}>{market.yes_rate}</Box>
                        </Box>
                      </BetButton>

                      <TableCell
                        colSpan={2}
                        className="back betting-disabled unhighlighted"
                        sx={{
                          textAlign: "left",
                          borderRight: "1px solid transparent !important",
                        }}
                      >
                        Max Bet:
                        <br />
                        Max Mkt:
                      </TableCell>
                      <TableCell
                        colSpan={2}
                        className="back betting-disabled unhighlighted"
                        sx={{ textAlign: "left" }}
                      >
                        {maxBetAndMktData?.max}
                        <br />
                        {maxBetAndMktData?.max_bet_amount}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {ballRunning && (
                  <Box className="ball-running-overlay">Ball Running</Box>
                )}
              </>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
    <Box className={`ladder-component ${isLadderOpen?"open":""}`}>
        <Box className={`ladder-container`}>
            <Box className={`ladder-header`}>
                <Box className={`row`}>
                    <Box className={`col`}>Runs</Box>
                    <Box className={`col`}>P&L</Box>
                </Box>
            </Box>
            <Box className={`ladder-row-container`}>
              {
                exposure && exposure?.runners?.map((runner)=>(
                <Box className={`row`}>
                    <Box className={`col`}>{runner.runner_name}</Box>
                    <Box className={`col ${runner.profit>-1?"-positive":"-negative"}`}>{runner.profit}</Box>
                </Box>
                ))
              }
            </Box>
        </Box>
    </Box>
    </Box>
  );
};

export default Fancy;
