import React, { createContext, useState } from "react";

export const BetSlipContext = createContext();

const BetSlipProvider = ({ children }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const changeTab = (tabNo) => {
    setCurrentTab(tabNo);
  };

  return (
    <BetSlipContext.Provider value={{ currentTab, setCurrentTab, changeTab }}>
      {children}
    </BetSlipContext.Provider>
  );
};

export default BetSlipProvider;
