import { handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const getProfitLossForAccountStatement = async (from, to, user_id) => {
  try {
    const response = await instance.get(
      `api/profit-loss-report/account-statement-user?from=${from}&to=${to}&user_id=${user_id}`
    );
    return response;
  } catch (error) {
    console.error("Error Fetching Proift & Loss Report", error);
  }
};

export const getProfitLossReport = async (from, to, user_id) => {
  try {
    const response = await instance.post(`api/profit-loss-report/admin`, {
      from: from.toString(),
      to: to.toString(),
      user_id: user_id,
    });
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Fetching Proift & Loss Report", error);
  }
};

export const getProfitLossReportByMarket = async (market_id, user_id) => {
  try {
    const response = await instance.post(
      `api/profit-loss-report/admin/by-market`,
      {
        marketId: market_id,
        userId: user_id,
      }
    );
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Fetching Proift & Loss Report", error);
  }
};

export const getProfitLossReportByMarketForCasino = async (market_id) => {
  try {
    const response = await instance.get(
      `api/profit-loss-report/evolution-bets/${market_id}`,
    );
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Fetching Proift & Loss Report For Casino", error);
  }
};
