import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { eventData } from "../../config/data";
import { useParams } from "react-router-dom";
import EventName from "./components/eventName";
import OddsTable from "./components/oddsTable";
import Races from "../races/races";
import LiveCasinoPage from "./components/liveCasinoPage/liveCasinoPage";
import { getEventsListByGameId } from "../../api/services/eventsService";
import { useEffect, useState } from "react";
import { getSportById } from "../../utils/functions";
import { useSelector } from "react-redux";



export default function SportPage()
{
    const sportsData = useSelector(state => state.sports)
    const {compId,sportId} = useParams();
    const [events, setEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])

    const getEventsListByIdFunc = async (game_id)=>{
        const response = await getEventsListByGameId(game_id)
        if(response && response.length > 0){
            setEvents(response)
            setFilteredEvents(response)
        }
    }

    useEffect(()=>{
        const sport = getSportById(sportId, sportsData.sportsData)
        if(sport) getEventsListByIdFunc(sport.provider_game_id)
        else getEventsListByIdFunc("4")
    }, [sportsData,sportId])


    useEffect(()=>{
        if(compId){
            const newEvents = events.filter((event)=> event.league_id === compId)
            setFilteredEvents(newEvents)
        }else{
            setFilteredEvents(events)
        }
    }, [compId])

    if(sportId === "9") return <></>
    if(sportId === "10") return <LiveCasinoPage/>
    if(sportId !== "4339" && sportId !== "7")
    {
        return(
            <Table 
            className="market-listing-table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell className="title">Live & Upcoming</TableCell>
                        <TableCell className="_align-center col-first-player" colSpan={2} >
                            <Box className="two-cells-header">1</Box>
                        </TableCell>
                        <TableCell className="_align-center player-draw" colSpan={2}>
                            <Box className="two-cells-header">X</Box>
                        </TableCell>
                        <TableCell className="_align-center col-second-player" colSpan={2}>
                            <Box className="two-cells-header">2</Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        filteredEvents && filteredEvents.map((event, index)=>{
                            return(
                                <TableRow key={index}>
                                    <EventName data={event} />
                                    <OddsTable event={event} data={event} />
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        );
    }
    else {
        return (
            <Races />
        )
    }
    
}