import axios from "axios";

// const API_URL = `http://192.168.0.162:8002`
const API_URL = process.env.REACT_APP_API_LINK
// const API_URL = `https://server.spacex365.net`

export const instance = axios.create({
    baseURL: API_URL
})

export {API_URL}