import { Box } from "@mui/material";



export default function Loader(){
    return(
        <Box className="loader-wrap">
            <Box className="loader">
            <svg class="circular" viewBox="25 25 50 50">
                <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"></circle>
            </svg>
            </Box>
            <Box component={"span"} className="loader-message"> Bet placement in progress </Box>
        </Box>
    )
}