// menu.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedMenuItem: '',
  menuData: null,
};

const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    selectMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload;
    },
    updateMenuData: (state, action) => {
      state.menuData = action.payload;
    },
    clearMenu: () => initialState,
  },
});

export const { selectMenuItem, updateMenuData, clearMenu } = menuSlice.actions;

export default menuSlice.reducer;
