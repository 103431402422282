import { io } from "socket.io-client";
// import { API_LINK } from "../constants/constants";
import { authHeader } from "../api/services/userAuthService";
import { parse } from "cookie";
import { API_URL } from "../api/services/apiService";

// const SOCKET_LINK = `http://betfair-sap-stage.spacex365.io`;

let socket;

const connect = (handleConnection) => {
    // console.log(authHeader())
    socket = io(`${API_URL}/v1/user`, {
        forceNew: true,
        withCredentials: true,
        extraHeaders: {
            Authorization: authHeader(),
        }
    })

    const COOKIE_NAME = process.env.REACT_APP_SECRET_KEY;

    socket.io.on("open", () => {
        socket.io.engine.transport.on("pollComplete", () => {
            const request = socket.io.engine.transport.pollXhr.xhr;
            const cookieHeader = request?.getResponseHeader("set-cookie");
            if (!cookieHeader) {
                return;
            }
            cookieHeader.forEach(cookieString => {
                if (cookieString.includes(`${COOKIE_NAME}=`)) {
                    const cookie = parse(cookieString);
                    socket.io.opts.extraHeaders = {
                        cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`
                    }
                }
            });
        });
    });


    return new Promise((res, rej) => {
        socket.on('connect', function (socket) {
            console.log("user connected");
            handleConnection(true)
            // setTimeout(()=>{
            //     joinUser();
            // },500)
            res(socket);
        });
        socket.on('disconnect', function () {
            handleConnection(false)
        });
        socket.once('connect_error', function () {
            console.log("error")
        });
        socket.once('connect_timeout', function () {
        });
    })
}

const joinUser = () => {
    // setTimeout(() => {
        console.log("User joining.................")
        socket?.emit("join-user-room", "")
    // }, 500)
}

const getBalanceData = (setBalance) => {
    console.info("balance listening.......")
    socket?.on("get-user-balance", (data) => {
        console.info(data, "user-balance");
        if (data && data.data) {
            setBalance(data.data)
        }
    })
}

const getExposureAndOpenBets = (handleChange) => {
    console.info("refresh-match-list  listening.......")
    socket?.on("refresh-match-list", (data) => {
        console.info(data,"in refresh-match-list")
        handleChange();
    })
}

const logoutUser = (handleLogout) => {
    socket?.on("log-out", (data) => {
        console.log(data, "log-out");
        if (data && data.data && data.data.log_out) {
            handleLogout()
        }
    })
}

const userError = (handleLogout) => {
    socket?.on("user-error", (data) => {
        console.log(data, "user-error");
        if (data && data.status_code == 401) {
            handleLogout()
        }
    })
}

const disconnect = () => {
    // socket?.emit("user-leave", "")
    socket.disconnect();
}

const userSocket = {
    connect,
    getBalanceData,
    getExposureAndOpenBets,
    joinUser,
    disconnect,
    logoutUser,
    userError
}

export default userSocket;