import { Box, Button, Dialog, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeStake } from "../../redux/reducers/stakes";
import { editStakesByUserId } from "../../api/services/settingsService";

import { editStakes as editStakesInRedux } from "../../redux/reducers/user";

export default function EditStakeModal({ open, onClose = () => {}, parent }) {
  const user = useSelector((state) => state.user.userData);

  const [offset, setOffset] = useState();
  const [stakes, setStakes] = useState([]);
  const stake = useSelector((state) => state.user.userData.stake);
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      if (parent && parent.current && open) {
        setOffset(parent.current.getBoundingClientRect());
      }
    }, 10);
  }, [parent, open]);

  useEffect(() => {
    if (open) setStakes([...stake]);
  }, [stake, open]);

  const handleChange = (index, value) => {
    const temp = stakes;
    temp[index] = value;
    setStakes([...temp]);
  };

  const editStakes = async (user_id, data) => {
    const response = await editStakesByUserId(user_id, data);
    if (response) {
      console.log(response);
    }
  };

  const saveStakes = () => {
    const stakeData = {
      is_one_click_bet_enable: user?.is_one_click_bet_enable,
      stake: stakes,
      mobile_stake: user?.mobile_stake,
      one_click_stake: user?.one_click_stake,
    };
    editStakes(user?.user_id, stakeData);
    dispatch(editStakesInRedux(stakes));
    dispatch(changeStake(stakes));
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiModal-backdrop": {
          background: "rgba(0, 0, 0, 0.5)",
        },
        ".MuiDialog-paper": {
          borderRadius: "0",
          maxWidth: "270px",
          maxHeight: "150px",
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          margin: "auto",
          color: "#0f2327",
          background: "#fff",
          zIndex: "2000",
          marginLeft: `${offset?.x + 20}px`,
          marginTop: `${offset?.top + 20}px`,
        },
      }}
    >
      <Box padding={"20px"}>
        <Stack>
          <Stack
            direction={"row"}
            sx={{
              flexFlow: "wrap row",
              "& .item": { marginBottom: "3%", maxWidth: "70px" },
            }}
            justifyContent={"space-around"}
          >
            {stakes.map((st, i) => {
              return (
                <Box className={"item"} key={i}>
                  <TextField
                    onChange={(e) => handleChange(i, e.target.value)}
                    value={st}
                  />
                </Box>
              );
            })}
          </Stack>
          <Stack direction={"row"} justifyContent={"center"} mt={"10px"}>
            <Button onClick={onClose} variant="text" className="large">
              Cancel
            </Button>
            <Button onClick={saveStakes} variant="success" className="large">
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
