import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bets: [],
};

const betsSlice = createSlice({
  name: "bets",
  initialState: initialState,
  reducers: {
    addBet: (state, action) => {
      state.bets.push(action.payload);
    },
    removeBet: (state, action) => {
      state.bets = state.bets.filter((bet) => bet.betId !== action.payload);
    },
    updateOdds: (state, action) => {
      const temp = state.bets;
      temp.forEach((bet) => {
        if (bet.betId === action.payload.betId) {
          bet.odds = action.payload.odds;
        }
      });
      state.bets = temp;
    },
    updateAmount: (state, action) => {
      const temp = state.bets;
      temp.forEach((bet) => {
        if (bet.betId === action.payload.betId) {
          bet.bet_amount = action.payload.bet_amount;
        }
      });
      state.bets = temp;
    },
    updateLiability: (state, action) => {
      const temp = state.bets;
      temp.forEach((bet) => {
        if (bet.betId === action.payload.betId) {
          bet.liability = action.payload.liability;
        }
      });
      state.bets = temp;
    },
    clearBets: () => initialState,
  },
});

export const { addBet, clearBets, removeBet, updateAmount, updateOdds,updateLiability } = betsSlice.actions;

export default betsSlice.reducer;
