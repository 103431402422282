


export default function Button(theme)
{
    return{
        MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
          },
        MuiButton:{
            styleOverrides:{
                root:{
                    appearance:"none",
                    margin:0,
                    background:"#C0C0C0",
                    textAlign:"center",
                    borderBottom:"2px solid #a7a7a7",
                    borderRight:"2px solid #a7a7a7",
                    borderRadius:3,
                    padding:"0 10px",
                    lineHeight:"26px",
                    minHeight:26,
                    fontSize:12,
                    color:"#fff",
                    minWidth:"auto",
                
                    "&:hover, &:focus":{
                        background:"#cdcdcd",
                        color:"#676767",
                        outline:"none",
                        textDecoration:"none",
                        boxShadow:"1.5px 1.5px 0px 0px #a7a7a7"
                    },

                    "&:active":{
                        background:"#bbbbbb",
                        color:"#676767",
                        borderBottom:0,
                        borderRight:0,
                        borderTop:"2px solid #a4a4a4",
                        borderLeft:"2px solid #a4a4a4",
                        boxShadow:"none",

                    },
                    "&:disabled":{
                        backgroundColor: '#F5F5F5',
                        color: '#999999',
                        borderColor: '#F5F5F5',
                        boxShadow: 'none'
                    },
                    "&.large":{
                        minHeight: '30px',
                        padding: '0 12px',
                        lineHeight: '30px',
                        fontSize: '14px',
                        fontWeight:'normal'
                    },
                    "&.small":{
                        minHeight: '21px',
                        padding: '0 10px',
                        lineHeight: '21px',
                        fontSize: '10px',
                        minWidth:"auto",
                    },
                    "&.mini":{
                        minHeight: '17px',
                        padding: '0 5px',
                        lineHeight: '17px',
                        fontSize: '8px'
                    }
                },

                success:{
                    background:"#5c996f",
                    borderBottomColor:"#497958",
                    borderRightColor:"#497958",
                    "&:hover, &:focus":{
                        background:"#69a57c",
                        color:"#fff",   
                        boxShadow:"1.5px 1.5px 0px 0px #3f694c"
                    },

                    "&:active":{
                        background:"#58936a",
                        color:"#fff",
                        borderTopColor:"#477656",
                        borderLeftColor:"#477656",

                    }
                },

                error:{
                    background:"#bf3e35",
                    borderBottomColor:"#97312a",
                    borderRightColor:"#97312a",
                    "&:hover, &:focus":{
                        background:"#cb4c43",
                        color:"#fff",   
                        boxShadow:"1.5px 1.5px 0px 0px #832b24"
                    },

                    "&:active":{
                        background:"#b73b33",
                        color:"#fff",
                        borderTopColor:"#933029",
                        borderLeftColor:"#933029",

                    }
                },
                error_text:{
                    background: '#ede1df',
                    border: 'none',
                    boxShadow: 'none',
                    color: '#a82a18',
                    "&:hover, &:focus":{
                        background: '#ede1df',
                        border: 'none',
                        boxShadow: 'none',
                        color: '#a82a18'
                    },
                    "&:active":{
                        background: '#eccdc8',
                        border: 'none',
                        boxShadow: 'none',
                        color: '#a82a18'
                    },
                },
                login:{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight:"400",
                    height: '40px',
                    borderRadius: '4px',
                    backgroundColor: '#183f45',
                    color: '#fff',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderBottom: '2px solid #0b1c1f',
                    borderRight: '2px solid #0b1c1f',
                    "&:hover, &:focus":{
                        background:"#1f5058",
                        color:"#fff",   
                        boxShadow:"1.5px 1.5px 0px 0px #040b0c"
                    },

                    "&:active":{
                        background:"#15383d",
                        color:"#fff",
                        borderTopColor:"#0b1c1f",
                        borderLeftColor:"#0b1c1f",

                    }
                },
                text:{
                    borderColor: 'transparent',
                    background: 'none',
                    boxShadow: 'none',
                    color:theme.palette.primary.main,
                    "&:hover,&:focus,&:active":{
                        color: '#010202',
                        textDecoration: 'underline',
                        borderColor: 'transparent',
                        background: 'none',
                        boxShadow: 'none',
                    },
                    "&:focus":{
                        outline: '1px dotted'
                    }
                },
                secondary:{
                    background:"#09262b",
                    borderBottomColor:"#000101",
                    borderRightColor:"#000101",
                    color:"#fff",
                    "&:hover, &:focus":{
                        background:"#0d3940",
                        color:"#fff",   
                        boxShadow:"1.5px 1.5px 0px 0px #000000"
                    },

                    "&:active":{
                        background:"#071f23",
                        color:"#fff",
                        borderTopColor:"#082227",
                        borderLeftColor:"#082227",
                        boxShadow:"none"
                    }
                }
            }
        }
    }
}