import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  getProfitLossReport,
  getProfitLossReportByMarket,
} from "../../api/services/profitLossReportService";
import moment from "moment";
import { useSelector } from "react-redux";
import { isNegative } from "../../utils/functions";
import InvoiceSection from "../accountStatement/invoiceSection";
import ProfitLossReportByMarket from "../accountStatement/profitLossReportByMarket";
import CustomIcon from "../../components/customIcon";

export default function BettingProfitLoss() {
  const [bets, setBets] = useState([]);
  const [filteredBets, setFilteredBets] = useState([]);
  const [gameTotal, setGameTotal] = useState([]);
  const [totalProfitLoss, setTotalProfitLoss] = useState(0);
  const [currentGame, setCurrentGame] = useState(0);
  const [apiParams, setApiParams] = useState({});
  const [market, setMarket] = useState("");
  const [plData, setPlData] = useState({});

  const userData = useSelector((state) => state.user.userData);

  const getProfitLossReportFunc = async (from, to, user_id) => {
    try {
      const response = await getProfitLossReport(
        moment(from).startOf("day").unix(),
        moment(to).endOf("day").unix(),
        user_id
      );
      setBets(response.bets);
      setTotalProfitLoss(response.totalProfitLoss);
      setGameTotal(response.gameTotal);
    } catch (error) {
      console.log("Error Fetching Profit & Loss Report");
    }
  };

  useEffect(() => {
    getProfitLossReportFunc(apiParams.from, apiParams.to, apiParams.user_id);
  }, []);

  useLayoutEffect(() => {
    setApiParams({
      ...apiParams,
      from: moment(),
      to: moment(),
      user_id: userData.user_id,
    });
  }, [userData]);

  useEffect(() => {
    if (gameTotal.length > 0) setCurrentGame(gameTotal[0].provider_game_id);
  }, [gameTotal]);

  useEffect(() => {
    const filteredData = bets
      .map((item) => ({
        ...item,
        markets: item.markets.filter(
          (event) => event.provider_game_id == currentGame
        ),
      }))
      .filter((item) => item.markets.length > 0);

    setFilteredBets(filteredData);
  }, [currentGame]);

  const getProfitLossReportByMarketFunc = async (market_id, user_id) => {
    try {
      const response = await getProfitLossReportByMarket(market_id, user_id);
      setPlData(response);
      console.log(response);
    } catch (error) {
      console.error("Error Fetching Profit Loss Report");
    }
  };

  // useEffect(() => {
  //   getProfitLossReportByMarketFunc(market, userData.user_id);
  // }, [market]);

  return (
    <Box className={"pnl-listing"}>
      <Box sx={{ marginTop: "9px", height: "99px" }}>
        <Box className={"datepicker-container"}>
          <Box
            component={"label"}
            sx={{
              display: "block",
              marginBottom: "10px",
            }}
          >
            From
          </Box>
          <MobileDatePicker
            onChange={(e) => setApiParams({ ...apiParams, from: e })}
            value={apiParams.from}
            format="DD/MM/YYYY"
            disableFuture={true}
          />
        </Box>
        <Box className={"datepicker-container"}>
          <Box
            component={"label"}
            sx={{
              display: "block",
              marginBottom: "10px",
            }}
          >
            To
          </Box>
          <MobileDatePicker
            onChange={(e) => setApiParams({ ...apiParams, to: e })}
            value={apiParams.to}
            format="DD/MM/YYYY"
            disableFuture={true}
          />
        </Box>
        <Button
          onClick={() =>{
            getProfitLossReportFunc(
              apiParams.from,
              apiParams.to,
              apiParams.user_id
            )
            setMarket("")
          }}
          variant="success"
        >
          <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
          <CustomIcon color={"#fff"} name={"search"} fontSize={14} />
            <Box>Search</Box>
          </Stack>
        </Button>
      </Box>
      <Box pb={4} className={"filter "}>
        <Box component={"ul"} sx={{ textAlign: "right" }}>
          {gameTotal.map((game) => (
            <Box
              key={game.provider_game_id}
              component={"li"}
              className="total-pnl"
              onClick={() => {
                setCurrentGame(game.provider_game_id)
                setMarket('')
              }}
            >
              <Box
                component={"p"}
                sx={{
                  textAlign: "right",
                  borderBottom:
                    currentGame !== game.provider_game_id && `1px solid`,
                }}
              >
                <Box
                  component={"span"}
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    textTransform: "capitalize",
                  }}
                >
                  {game._id}
                </Box>
                &nbsp;:&nbsp;
                <Box
                  component={"span"}
                  className={
                    isNegative(game.total_profit_loss)
                      ? "-negative"
                      : "-positive"
                  }
                  sx={{ fontSize: 12, fontWeight: 400 }}
                >
                  {game.total_profit_loss.toFixed(2)}
                </Box>
              </Box>
            </Box>
          ))}
          <Box component={"li"} className="total-pnl">
            <Box component={"p"} sx={{ textAlign: "right" }}>
              <Box component={"span"}>Total P&L</Box>
              &nbsp;:&nbsp;
              <Box
                component={"span"}
                className={
                  isNegative(totalProfitLoss) ? "-negative" : "-positive"
                }
              >
                {totalProfitLoss.toFixed(2)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {market && market !== "" ? (
        <ProfitLossReportByMarket isCasino={market.is_casino} marketId={market.provider_market_id} setMarket={setMarket}/>
      ) : (
        <Table className="listing">
          <TableHead>
            <TableRow>
              <TableCell>Market</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>Settled time</TableCell>
              <TableCell className="numeric">Comm.</TableCell>
              <TableCell className="numeric">Net Win</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ bgcolor: "#f9f9f9" }}>
            {filteredBets.map((bet) => (
              <>
                <TableRow key={bet._id}>
                  <TableCell>
                    <Typography fontWeight={600}>{bet._id}</Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {bet.markets.map((market) => (
                  <TableRow key={market.provider_market_id}>
                    <TableCell
                      onClick={() => setMarket(market)}
                    >
                      {market.event_name} - {market.market_name}
                    </TableCell>
                    <TableCell>
                      {moment.unix(market.event_time).format("DD/MM/YYYY")}
                      <br />
                      {moment.unix(market.event_time).format("HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      {moment.unix(market.settled_at).format("DD/MM/YYYY")}
                      <br />
                      {moment.unix(market.settled_at).format("HH:mm:ss")}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "right !important" }}
                      className={
                        isNegative(market.comission) ? "-negative" : "-positive"
                      }
                    >
                      {market.comission}
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: "right !important" }}
                      className={
                        isNegative(market.profitAndLoss)
                          ? "-negative"
                          : "-positive"
                      }
                    >
                      {market.profitAndLoss}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}
