import { handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const generateBarcode = async () => {
  try {
    const response = await instance.get(`/api/auth/generate-barcode`);
    return response;
  } catch (error) {
    console.error("Error Generating Barcode", error);
  }
};

export const verifyOtp = async (token) => {
  try {
    const response = await instance.post(`/api/auth/verify-otp`, {token: token});
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Generating Barcode", error);
  }
};
