import { Box, Stack, TableCell } from "@mui/material";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import CustomIcon from "../../../components/customIcon";
import { useDispatch, useSelector } from "react-redux";
import { addFavorite, removeFavorite } from "../../../redux/reducers/favorites";


export default function EventName({data})
{
    const navigate = useNavigate();
    const { favorites } = useSelector(state =>state.favorites)
    const {compId,sportId} = useParams();
    const dispatch = useDispatch();
    const { isLogginIn } = useSelector((state) => state.user);
    const isInFavorites = favorites.find((fav)=>fav.market_id === data.market_id)

    const onAddFavorite = () =>{
        if(isLogginIn){
            if(favorites.find((fav)=>fav.market_id === data.market_id))
            {
                dispatch(removeFavorite(data.market_id))
            }
            else{
                dispatch(addFavorite(data));
            }
        }
    }

    return(
        <TableCell className="event-row">
            <Box className={"event-flex-row"}>
                <Stack direction={"row"} className={"event-col"} spacing={"6px"}>
                    {/* <Box sx={{width:14,height:14,bgcolor:"#e4c41e"}}></Box> */}
                    <CustomIcon onClick={onAddFavorite} name={!isInFavorites?"favorite":"favorite-filled"} fontSize={15} color={"#e4c41e"} />
                    <Box 
                    onClick={()=>navigate(`/sport/${sportId || 4}/${data.league_id}/${data.game_event_id}`)}
                    sx={{
                        fontWeight: 'bold',
                        lineHeight: '22px',
                        color: '#005ba2',
                        cursor:"pointer",
                        ":hover":{
                            textDecoration: 'underline',
                        },
                        ":visited":{
                            color: '#005ba2'
                        }
                    }}>
                        {data.event_name}
                    </Box>
                </Stack>
                {
                    data.provider_status == 'IN_PLAY' ?
                    <Stack direction={"row"} spacing={"8px"} className="event-col">
                        {/* <Box sx={{width:14,height:14,bgcolor:"#1d7f1e"}}></Box> */}
                        {
                            data.is_live_stream && data.is_stream_available &&
                            <CustomIcon name={"video-tv"} fontSize={12} color={"#1d7f1e"} />
                        }
                        <Stack direction={"row"} spacing={"2px"} alignItems={"center"}>
                            <CustomIcon name={"live"} fontSize={14} color={"#1d7f1e"} />
                            <Box component={"small"} color={"#1d7f1e"}>In-Play</Box>
                        </Stack>
                    </Stack>
                    :
                    <Box component={"small"} className={"event-time"}>{moment.unix(data.event_time).format('DD/MM/YY HH:mm')}</Box> 
                }
            </Box>
        </TableCell>
    );
}