import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import CustomIcon from "../../../components/customIcon";
import BetButton from "../../../components/miniComponents/betButton";
import { useNavigate, useParams } from "react-router-dom";


export default function UpcomingRaces({races})
{
    const [openRace,setOpenRace] = useState(-1);
    const {sportId} = useParams(); 
    const navigate = useNavigate();

    const handleToggle = (id)=>{
        if(openRace === id) setOpenRace(-1);
        else setOpenRace(id);
    }


    const handleRaceClick = (event,race) =>{
        event.preventDefault();
        event.stopPropagation();
        navigate(`/sport/${sportId}/${race.game_event_id}/${race.market_id}/race`)
      }

    return(
        <Box>
            <Typography variant="h3" mb={"13px"}>Upcoming Races</Typography>
            {
                races?.map((race,i)=>{
                    return(
                        <Box onClick={()=>handleToggle(i)} className={`race-accordion ${i === openRace ? "open":""}`}>
                            <Box className={"race-accordion-header"}>
                                <Box className={"race-flag"}>
                                    <Box className={"flag"}></Box>
                                </Box>
                                <Box className={"race-info"}>
                                    <Box onClick={(e)=>handleRaceClick(e,race)} className={"race-title"}>
                                        {race.event_name}
                                    </Box>
                                    <Box className={"race-time"} fontSize={12} color={"primary.main"}>
                                        {race?.market_time && moment.unix(race?.market_time).format("HH:mm")}
                                    </Box>
                                </Box>
                                <Box sx={{display: 'inline-block',verticalAlign: 'middle'}}>
                                    <Box
                                        sx={{
                                            flex: 1,
                                            display: "grid",
                                            gridAutoFlow: "column",
                                            justifyContent: "flex-end",
                                        }}
                                        >
                                        <Box className={"icon-box"}>
                                            <CustomIcon name={"inplay"} fontSize={18} color={"primary.main"} popover={"Going in-play"} />
                                        </Box>
                                        <Box className={"icon-box"}>
                                            <CustomIcon name={"0percent"} fontSize={18} color={"primary.main"} popover={"No commission"} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={"indicators"}><Box className={"chevron-container"}><Box className={"chevron"} /></Box></Box>
                            </Box>
                            <Box className={"race-accordion-content"}>
                                <Table className="market-listing-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="title">
                                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Stack direction={"row"} alignItems={"center"} >
                                                        <Box>&nbsp;Top 3 of 7 runners</Box>
                                                    </Stack>
                                                </Stack>
                                            </TableCell>
                                            <TableCell className="_align-center" colSpan={3}>
                                                <Box className="three-cells-header">Back</Box>
                                            </TableCell>
                                            <TableCell className="_align-center" colSpan={3}>
                                                <Box className="three-cells-header">Lay</Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                           race && race?.runners.slice(0,3).map((runner)=>{
                                                let suspended = false;
                                                let ballRunning = false;
                                                return(
                                                    <TableRow>
                                                        <TableCell className="event-row">
                                                            {/* <Box className={"runner-numbers"}>
                                                                <Box component={"span"}>4</Box>
                                                                <Box component={"span"}>(4)</Box>
                                                            </Box> */}
                                                            {/* <Box component={"img"} className={"runner-img"} src={"/d/assets/images/race-runner-img.png"} /> */}
                                                            <Box sx={{float:"left"}}>
                                                                <Box sx={{fontWeight:"bold"}}>{runner.runner_name}</Box>
                                                                {/* <Box  display={"block"}>A Domeyer</Box> */}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${
                                                            suspended || runner.provider_status === "SUSPENDED"
                                                                ? "-status suspended"
                                                                : "inner-table-container"
                                                            }`}
                                                            colSpan={6}
                                                        >
                                                            {suspended || runner.provider_status === "SUSPENDED" ? (
                                                            <Box className="status-label">SUSPENDED</Box>
                                                            ) : (
                                                            <>
                                                                <Table className="inner-table">
                                                                <TableBody>
                                                                    <TableRow>
                                                                    {runner?.back ? runner?.back
                                                                        ?.slice()
                                                                        ?.reverse()
                                                                        ?.map((back, i) => (
                                                                        <BetButton
                                                                        event={race}
                                                                        market={race}
                                                                            runner={runner}
                                                                            odds={back.price}
                                                                            type={"back"}
                                                                            side={0}
                                                                            className={`back ${
                                                                            i < 2 && "unhighlighted"
                                                                            }`}
                                                                            key={i}
                                                                        >
                                                                            <Box className={"bet-button-wrapper"}>
                                                                            <strong>{back.price}</strong>
                                                                            <Box className={"size"}>{back.size}</Box>
                                                                            </Box>
                                                                        </BetButton>
                                                                        ))
                                                                            :
                                                                            [...Array(3)].map((e,i)=>(
                                                                                <BetButton
                                                                                event={race}
                                                                                market={race}
                                                                                    runner={runner}
                                                                                    odds={""}
                                                                                    type={"back"}
                                                                                    side={0}
                                                                                    className={`back ${
                                                                                    i < 2 && "unhighlighted"
                                                                                    }`}
                                                                                    key={i}
                                                                                >
                                                                                    <Box className={"bet-button-wrapper"}>
                                                                                    <strong></strong>
                                                                                    <Box className={"size"}></Box>
                                                                                    </Box>
                                                                                </BetButton>
                                                                            ))
                                                                    }
                                                                    {runner?.lay ? runner?.lay.map((lay, i) => (
                                                                        <BetButton
                                                                        event={race}
                                                                        market={race}
                                                                        runner={runner}
                                                                        odds={lay.price}
                                                                        side={1}
                                                                        className={`lay ${
                                                                            i > 0 && "unhighlighted"
                                                                        }`}
                                                                        type="lay"
                                                                        >
                                                                        <Box className={"bet-button-wrapper"}>
                                                                            <strong>{lay.price}</strong>
                                                                            <Box className={"size"}>{lay.size}</Box>
                                                                        </Box>
                                                                        </BetButton>
                                                                    ))
                                                                :
                                                                [...Array(3)].map((e,i)=>(
                                                                    <BetButton
                                                                    event={race}
                                                                    market={race}
                                                                        runner={runner}
                                                                        odds={""}
                                                                        side={1}
                                                                        className={`lay ${
                                                                            i > 0 && "unhighlighted"
                                                                        }`}
                                                                        type="lay"
                                                                        >
                                                                        <Box className={"bet-button-wrapper"}>
                                                                            <strong></strong>
                                                                            <Box className={"size"}></Box>
                                                                        </Box>
                                                                        </BetButton>
                                                                ))
                                                                }
                                                                    </TableRow>
                                                                </TableBody>
                                                                </Table>
                                                                {ballRunning && (
                                                                <Box className="ball-running-overlay">Ball Running</Box>
                                                                )}
                                                            </>
                                                            )}
                                                        </TableCell>
                                                        {/* <TableCell className="back  unhighlighted">
                                                            <Box className={"bet-button-wrapper"}>
                                                                <strong>{5.70}</strong>
                                                                <Box className={"size"}>{161}</Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="back  unhighlighted">
                                                            <Box className={"bet-button-wrapper"}>
                                                                <strong>{5.70}</strong>
                                                                <Box className={"size"}>{161}</Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="back ">
                                                            <Box className={"bet-button-wrapper"}>
                                                                <strong>{5.70}</strong>
                                                                <Box className={"size"}>{161}</Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="lay ">
                                                            <Box className={"bet-button-wrapper"}>
                                                                <strong>{6.60}</strong>
                                                                <Box className={"size"}>{90}</Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="lay  unhighlighted">
                                                            <Box className={"bet-button-wrapper"}>
                                                                <strong>{5.70}</strong>
                                                                <Box className={"size"}>{161}</Box>
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell className="lay  unhighlighted">
                                                            <Box className={"bet-button-wrapper"}>
                                                                <strong>{5.70}</strong>
                                                                <Box className={"size"}>{161}</Box>
                                                            </Box>
                                                        </TableCell> */}
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}