import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Stack, Snackbar } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { groupBy, calcProfit } from "../../utils/functions";
import { clearBets, removeBet, updateAmount } from "../../redux/reducers/bets";
// import { clearBets } from "../../redux/reducers/bets";

import { StakeButtons } from "../rightSidePane";
import SingleBet from "./SingleBet";
import { removeAllExposures } from "../../redux/reducers/exposure";
import { api_placeBet } from "../../api/services/betServices";
import { useSnackbar } from "notistack";
import ConfirmBetModal from "../modals/confirmBetModal";
import { changeConfirmBet } from "../../redux/reducers/user";

const BetSlip = ({setActiveTab,setPendingBetsId,openConfirmBet,setOpenConfirmBet,parent,placingBet,setPlacingBet}) => {
  const { enqueueSnackbar } = useSnackbar();
  

  const [focusedBet, setFocus] = useState();
  const dispatch = useDispatch();
  const [groupedBets, setGroupedBets] = useState({});
  const { bets } = useSelector((state) => state.bets);
  const { settings } = useSelector((state) => state.user);
  useEffect(() => {
    const fromOneClick = bets.find(bet=>bet.fromOneClick);
    if(fromOneClick){
        placeBet();
    }
    const bySide = groupBy(bets, "side");
    if (bySide["0"]) bySide["0"] = groupBy(bySide["0"], "eventId");
    if (bySide["1"]) bySide["1"] = groupBy(bySide["1"], "eventId");
    setGroupedBets(bySide);
  }, [bets]);

  const clearBet = (betID) => {
    dispatch(removeBet(betID));
  };

  const updateBet = (betId, amount) => {
    dispatch(updateAmount({ betId, bet_amount: parseFloat(parseFloat(amount).toFixed(2)) }));
  };
  const addAmount = (betId, amount, oldamount) => {
    const totalAmount = parseInt(amount) + (oldamount ? oldamount : 0);
    dispatch(updateAmount({ betId, bet_amount: totalAmount }));
  };

  const removeAllBets = () => {
    dispatch(removeAllExposures());
    dispatch(clearBets());
  };

  const placeBet = async () => {
    if(!placingBet){
      setPlacingBet(true);
      const response = await api_placeBet(
        bets.map(({ runners, side, betId,liability,preMatch, ...rest }) => rest)
      );
      setPlacingBet(false);
      if (response) {
        response.forEach((element) => {
          let variant = "";
          switch (element.status_type) {
            case "matched":
              variant = "success";
              break;
            case "error":
              variant = "error";
              break;
            case "pending":
              variant = "warning";
              setPendingBetsId(prev=>[...prev,element.bet_id])
              break;
            default:
              variant = "error";
              break;
          }
          enqueueSnackbar(element.message, { variant });
        });
        removeAllBets();
        setActiveTab(1)
      }
    }
  };

  const handlePlaceBetClick = () =>{
    if(settings.confirmBet){
      setOpenConfirmBet(true);
    }
    else{
      placeBet();
    }
  }

  const handleConfirmClick = () =>{
    dispatch(changeConfirmBet(!settings.confirmBet))
  }

  const cantBet = Boolean((bets.find((bet)=>!bet.bet_amount || !bet.odds || bet.bet_amount<1 || bet.odds<1.01)))

  

  return (
    <Box>
      <ConfirmBetModal open={openConfirmBet} onClose={()=>setOpenConfirmBet(false)} parent={parent} placeBet={placeBet} />
      {groupedBets["0"] && (
        <Box className={"side"}>
          <Box className={"bet-type-info back"}>Back</Box>
          <Box className={"market-list"} component={"ul"}>
            {Object.keys(groupedBets["0"]).map((key) => {
              return (
                <Box component={"li"}>
                  <Box className={"event-name"}>
                    {groupedBets["0"][key][0].event_name}
                  </Box>
                  <Box component={"ul"} className="bets-list">
                    {groupedBets["0"][key].map((bet) => {
                      return (
                        <SingleBet
                          bet={bet}
                          focusedBet={focusedBet}
                          updateBet={updateBet}
                          setFocus={setFocus}
                          clearBet={clearBet}
                          addAmount={addAmount}
                        />
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      {groupedBets["1"] && (
        <Box className={"side"}>
          <Box className={"bet-type-info lay"}>Lay</Box>
          <Box className={"market-list"} component={"ul"}>
            {Object.keys(groupedBets["1"]).map((key) => {
              return (
                <Box component={"li"}>
                  <Box className={"event-name"}>
                    {groupedBets["1"][key][0].event_name}
                  </Box>
                  <Box component={"ul"} className="bets-list">
                    {groupedBets["1"][key].map((bet) => {
                      return (
                        <SingleBet
                          bet={bet}
                          focusedBet={focusedBet}
                          updateBet={updateBet}
                          setFocus={setFocus}
                          clearBet={clearBet}
                          addAmount={addAmount}
                        />
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
      <Box component={"p"} className="summary-info">
        Liability: {bets.reduce((res,bet)=>res+bet.liability,0)?.toFixed(2)}
      </Box>
      <Stack
        direction={"row"}
        className={"summary-buttons"}
        spacing={"10px"}
        justifyContent={"flex-end"}
      >
        <Button onClick={() => removeAllBets()} variant="error">
          Remove All
        </Button>
        <Button disabled={cantBet} onClick={handlePlaceBetClick} variant="success">
          Place Bets
        </Button>
      </Stack>
      <Box component={"label"} className="confirmation-checkbox">
        <input onChange={handleConfirmClick} checked={settings.confirmBet} type="checkbox" />
        Confirm bets before placing
      </Box>
    </Box>
  );
};

export default BetSlip;
