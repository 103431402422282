import {
  Casino,
  Games,
  LocalAtm,
  Spoke,
  VideogameAsset,
} from "@mui/icons-material";

const liveCasinoData = [
  {
    banner: [
      {
        bgImg: "https://d2eb1cys5jgnub.cloudfront.net/cl/Evolution.webp",
        link: "https://arroww.evo-games.com/frontend/evo/r2/",
      },
    ],
    // Categories
    categories: [
      {
        categoryId: 1,
        categoryTitle: "Roulette",
        categoryIcon: <Casino />,
      },
      {
        categoryId: 2,
        categoryTitle: "Baccarat",
        categoryIcon: <LocalAtm />,
      },
      {
        categoryId: 3,
        categoryTitle: "Instant Games",
        categoryIcon: <Games />,
      },
      {
        categoryId: 4,
        categoryTitle: "Game Shows",
        categoryIcon: <VideogameAsset />,
      },
      {
        categoryId: 5,
        categoryTitle: "Dragon Tiger",
        categoryIcon: <LocalAtm />,
      },
      {
        categoryId: 6,
        categoryTitle: "Blackjack",
        categoryIcon: <Casino />,
      },
      {
        categoryId: 7,
        categoryTitle: "Poker",
        categoryIcon: <Spoke />,
      },
      {
        categoryId: 8,
        categoryTitle: "Teen Patti",
        categoryIcon: <Casino />,
      },
      {
        categoryId: 9,
        categoryTitle: "Indian Games",
        categoryIcon: <Casino />,
      },
      {
        categoryId: 10,
        categoryTitle: "Slots",
        categoryIcon: <Casino />,
      },
    ],
    // Events
    events: [
      {
        eventId: 1,
        eventTitle: "Bulgaria Roulette",
        categoryId: 1,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/r/bulgaria-roulette-live.webp`,
      },
      {
        eventId: 2,
        eventTitle: "Spanish Roulette",
        categoryId: 1,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/r/oriental-roulette-live.webp`,
      },
      {
        eventId: 3,
        eventTitle: "Portomaso Roulette",
        categoryId: 1,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/r/portomaso-roulette.webp`,
      },
      {
        eventId: 4,
        eventTitle: "Oracle Roulette",
        categoryId: 1,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/r/oracle-roulette-vivo-live.webp`,
      },
      {
        eventId: 5,
        eventTitle: "Oracle 360 Roulette",
        categoryId: 1,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/r/oracle360-roulette-live.webp`,
      },
      {
        eventId: 6,
        eventTitle: "Indian Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_indianroulette.jpg`,
      },
      {
        eventId: 7,
        eventTitle: "Immersive Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_liveimmersiveroulette.jpg`,
      },
      {
        eventId: 8,
        eventTitle: "Hindi Lightning Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_hindilightningroulette.jpg`,
      },
      {
        eventId: 9,
        eventTitle: "XXXTreme Lightning Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_xxxtremelightningroulette.jpg`,
      },
      {
        eventId: 10,
        eventTitle: "Lightning Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_lightningroulette.jpg`,
      },
      {
        eventId: 11,
        eventTitle: "Auto Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_liveauto-roulette.jpg`,
      },
      {
        eventId: 12,
        eventTitle: "Speed Auto Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_speedautoroulette.jpg`,
      },
      {
        eventId: 13,
        eventTitle: "Speed Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_livespeedroulette.jpg`,
      },
      {
        eventId: 14,
        eventTitle: "Roulette",
        categoryId: 1,
        eventBanner: `https://cdn.hub88.io/evolution/evo_liveroulette.jpg`,
      },
      {
        eventId: 15,
        eventTitle: "Bulgaria Baccarat 1",
        categoryId: 2,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/b/bulgaria-baccarat-1-vivo-gaming-live.webp`,
      },
      {
        eventId: 16,
        eventTitle: "Galaxy 1 Baccarat",
        categoryId: 2,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/b/baccarat-galaxy-1-1-vivo-gaming-live.webp`,
      },
      {
        eventId: 17,
        eventTitle: "Galaxy 2 Baccarat",
        categoryId: 2,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/b/baccarat-galaxy-1-1-vivo-gaming-live.webp`,
      },
      {
        eventId: 18,
        eventTitle: "AVIATOR",
        categoryId: 3,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/ig/aviator-sbe.webp`,
      },
      {
        eventId: 19,
        eventTitle: "JetX",
        categoryId: 3,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/ig/jetx-smrt.webp`,
      },
      {
        eventId: 20,
        eventTitle: "AERO",
        categoryId: 3,
        eventBanner: `https://d2eb1cys5jgnub.cloudfront.net/cl/n/ig/aero-tbg.webp`,
      },
    ],
  },
];

export { liveCasinoData };
