import { handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const casinoLogin = async (data)=>{
    try {
        const response = await instance.post('api/casino/login', data)
        return handleResponse(response.data)
    } catch (error) {
        console.error(`Error Logging In Casino`, error);
    }
}