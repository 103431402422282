import { io } from "socket.io-client";
import { authHeader } from "../api/services/userAuthService";
import { SOCKET_LINK } from "./socketService";

let socket;

// const SOCKET_LINK = `http://betfair-sap-dev.spacex365.io`;

const connect = () =>{
    socket = io(`${SOCKET_LINK}/v1/event`,{
        forceNew:true,
        extraHeaders:{
            Authorization:authHeader(),
        }
    })
    return new Promise((res,rej)=>{
        socket.on('connect', function(socket) {
            console.log("Event connected");
            // handleConnection(true)
            // setTimeout(()=>{
            //     joinUser();
            // },500)
            res(socket);
        });
        socket.on('disconnect',function(){
            // handleConnection(false)
        });
        socket.once('connect_error', function() {
            console.log("error")
        });
        socket.once('connect_timeout', function() {
        });
    })
}

const joinEvent = (data) =>{
    socket?.emit("join-event-room",JSON.stringify(data))
}

const leaveEvent = (data) =>{
    socket?.emit("event-leave",JSON.stringify(data))
}

const getEventDetails = (setEventData) =>{
    socket?.on("get-event-details",(data)=>{
        if(data && !data.response_error && data.data)
        {
            setEventData(data.data)
        }
    })
}

const joinSport = (data) =>{
    socket?.emit("join-sport-room",JSON.stringify(data))
}

const leaveSport = (data) =>{
    socket?.emit("sport-leave",JSON.stringify(data))
}

const getSportDetails = (setSportData) =>{
    socket?.on("get-sport-details",(data)=>{
        if(data && !data.response_error && data.data)
        {
            setSportData(data.data)
        }
    })
}

const disconnect = (userIds) => {
    socket?.disconnect()
    // const data = {
    //   leave_userIds: userIds,
    // };
    // socket?.emit("leave-room", data);
  };

const eventSocket = {
    connect,
    joinEvent,
    leaveEvent,
    getEventDetails,
    joinSport,
    leaveSport,
    getSportDetails,
    disconnect
}

export default eventSocket;