import { handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const fetchMenuData = async ()=>{
    try {
        const response = await instance.get('/api/menu')
        return handleResponse(response.data,true)
    } catch (error) {
        console.error(`Error Fetching Menu Data`, error);
    }
}
