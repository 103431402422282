import Router from "./router";
import ThemeProvider from "./theme";
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { instance } from "./api/services/apiService";
import { authHeader } from "./api/services/userAuthService";
import Initialization from "./initialization";

import { SnackbarProvider, useSnackbar } from "notistack";
import BetSlipProvider from "./context/bet-slip-context";
import BusinessMenuProvider from "./context/business-menu-context";
import LoginProvider from "./context/login-context";

const interCeptor = () => {
  instance.interceptors.request.use(
    (config) => {
      const token = authHeader();
      config.headers.Authorization = token;
      return config;
    },
    null,
    { synchronous: true }
  );
};

console.log = () =>{}

function App() {
  interCeptor();
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      maxSnack={3}
    >
      <Provider store={store}>
        <ThemeProvider>
          <LoginProvider>
            <BetSlipProvider>
              <BusinessMenuProvider>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Initialization />
                    <Router />
                  </LocalizationProvider>
              </BusinessMenuProvider>
            </BetSlipProvider>
          </LoginProvider>
        </ThemeProvider>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
