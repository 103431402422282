import { handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const getEventsListByGameId = async (game_id) => {
  try {
    const response = await instance.get(`/api/event/live-and-upcoming-event-market-list/${game_id}`);
    return handleResponse(response.data,true);
  } catch (error) {
    console.error(`Error Fetching Event List By Game ID: `, error);
  }
};

export const getRacesBySport = async (game_id) => {
  try {
    const response = await instance.get(`/api/event/hours-or-greyhounds-event-list/${game_id}`);
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching Event List By Game ID: `, error);
  }
}

export const getRacesRunnersBySport = async (event_id,market_id) => {
  try {
    const response = await instance.get(`/api/event/hours-or-greyhounds-event-and-market-list?event_id=${event_id}&market_id=${market_id}`);
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching Event List By Game ID: `, error);
  }
}


export const getEventsByLeagueId = async (league_id) => {
  try {
    const response = await instance.get(
      `/api/event/league-event-list/${league_id}`
    );
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching Events By Leauge Id:`, error);
  }
};

export const getMarketListByEventId = async (event_id) => {
  try {
    const response = await instance.get(`api/event/market_list/${event_id}`);
    return handleResponse(response.data, true);
  } catch (error) {
    console.error(`Error Fetching Market List By Id`, error);
  }
};

export const getMarketListBySuspendedEventId = async (event_id) => {
  try {
    const response = await instance.get(`api/event/my_market_list/${event_id}`);
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching Market List By Id`, error);
  }
};

export const getFancyMinMaxByMarketId = async (market_id) => {
  try {
    const response = await instance.get(
      `/api/event/getFancyMinMax?marketId=${market_id}`
    );
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching Data: `, error);
  }
};


export const getMatchOddOrBookMarkerMinMax = async (market_id) => {
  try {
    const response = await instance.get(
      `/api/event/getMatchOddOrBookMarkerMinMax?marketId=${market_id}`
    );
    return handleResponse(response.data,true);
  } catch (error) {
    console.error(`Error Fetching Data: `, error);
  }
};


export const getCasinoEvents = async(casino_menu_id)=>{
  try {
    const response = await instance.get(`api/event/casino-event/?menu_id=${casino_menu_id}&is_mobile=false`)
    return handleResponse(response.data)
  } catch (error) {
    console.error(`Error Fetching Casino Event Data: `, error);
  }
}

export const searchEventByName = async(query) => {
  try {
    const response = await instance.get(`api/event/event_search_list/${query}`)
    return handleResponse(response.data)
  } catch (error) {
    console.error(`Error Fetching Event Data: `, error);
  }
}