import { Box, Button, TextField, Typography } from "@mui/material";
import { updatePassword } from "../../api/services/userAuthService";
import { useRef, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import CustomIcon from "../../components/customIcon";
import { useSnackbar } from "notistack";
import { LoginContext } from "../../context/login-context";

export default function ChangePassword() {
  const userData = useSelector((state) => state.user.userData);
  const {logout} =  useContext(LoginContext)
  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [repeatPassword, setrepeatPassword] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const [validations, setValidations] = useState({
    charactersLength: false,
    upperCaseLowerCase: false,
    atLeastOneNumber: false,
    matchRepeatedPassword: false,
  });

  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const repeatPasswordRef = useRef();

  const styles = {
    form: {
      maxWidth: "300px",
      padding: "16px",
      backgroundColor: "white",
      ".input-box": {
        marginBottom: "20px",
        ".MuiTextField-root": {
          margin: "10px 0 5px",
          width: "100%",
        },
      },
    },
    password_input:{
      margin: '5px 0 20px',
      height: '27px',
      padding: '8px 4px',
      fontSize: '12px',
      maxWidth: '100%',
      width: '100%',
      border: '2px solid #DDDDDD',
      background: '#FFFFFF',
      color: '#666666',
      transition: 'linear 0.2s',
      borderRadius: '3px'
  },
    validation: {
      ".requirement": {
        marginBottom: "5px",
        display: "flex",
        flexFlow: "row",
        alignItems: "center",
        ".mark": {
          marginRight: "10px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          ".iconMarkCross": {
            borderRadius:"100%",
            bgcolor: "#ff0000",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          },
          ".iconMarkCheck": {
            bgcolor: "#149909",
            borderRadius:"100%",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
          },
        },
        ".message": {
          transition: "margin-bottom ease 0.5s",
          fontWeight: "bold",
          fontStyle: "normal",
          fontSize: 12,
          "&.invalid": {
            color: "#ff0000",
          },
        },
      },
    },
  };

  const updatePasswordFunc = async (user_id, oldPassword, newPassword) => {
    try {
      const response = await updatePassword(user_id, oldPassword, newPassword);
      console.log(response);
      enqueueSnackbar("Password Changed Successfullly!", { variant: "success" });
      logout()
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
      console.error(`Error Updating Password: ${error}`);
    }
  };

  //   New Password Validation
  const newPasswordValidation = (password) => {
    const charactersLength = password.length + 1 >= 8;
    const upperCaseLowerCase = /[A-Z]/.test(password) && /[a-z]/.test(password);
    const atLeastOneNumber = /\d/.test(password);

    setValidations((prevValidations) => ({
      ...prevValidations,
      charactersLength,
      upperCaseLowerCase,
      atLeastOneNumber,
    }));
  };

  // Repeat Password
  const repeatPasswordValidation = (password) => {
    const isMatching = password === newPassword;
    setValidations((prev) => ({
      ...prev,
      matchRepeatedPassword: isMatching,
    }));
  };

  // Checking If all conditions are valid
  useEffect(() => {
    const isAllValid = Object.values(validations).every(
      (item) => item === true
    );

    setDisableSaveButton(isAllValid ? false : true);

    console.log(isAllValid)
  }, [validations]);

  return (
    <Box sx={styles.form}>
      <Box className={"input-box"}>
        <Typography variant="body2">Old Password</Typography>
        <input
          style={styles.password_input}
          ref={oldPasswordRef}
          onChange={() => setoldPassword(oldPasswordRef.current.value)}
          value={oldPassword}
          type="password"
        />
      </Box>
      <Box className={"input-box"}>
        <Typography variant="body2">New Password</Typography>
        <input
        style={styles.password_input}
          ref={newPasswordRef}
          onChange={() => {
            const newPasswordValue = newPasswordRef.current.value;
            setnewPassword(newPasswordValue);
            newPasswordValidation(newPasswordValue);
          }}
          value={newPassword}
          type="password"
        />
      </Box>
      <Box className={"input-box"}>
        <Typography variant="body2">Repeat Password</Typography>
        <input
        style={styles.password_input}
          ref={repeatPasswordRef}
          onChange={() => {
            const repeatPasswordValue = repeatPasswordRef.current.value;
            setrepeatPassword(repeatPasswordValue);
            repeatPasswordValidation(repeatPasswordValue);
          }}
          value={repeatPassword}
          type="password"
        />
      </Box>
      <Box mb={"30px"} sx={styles.validation}>
        <Box sx={{ fontSize: "16px", marginBottom: "15px" }}>
          Password should:
        </Box>
        <Box className={"requirement"}>
          <Box className={"mark"}>
            <Box
              className={validations.charactersLength?"iconMarkCheck":"iconMarkCross"}
              sx={{ width: 14, height: 14 }}
            >
              <CustomIcon name={validations.charactersLength?"tick":"modal-close"} color={"#fff"} fontSize={7} />
            </Box>
          </Box>
          <Box
            className={validations.charactersLength ? "-positive" : "-negative"}
          >
            Be from 8 up to 255 characters.
          </Box>
        </Box>
        <Box className={"requirement"}>
          <Box className={"mark"}>
            <Box
              className={validations.upperCaseLowerCase?"iconMarkCheck":"iconMarkCross"}
              sx={{ width: 14, height: 14 }}
            >
              <CustomIcon name={validations.upperCaseLowerCase?"tick":"modal-close"} color={"#fff"} fontSize={7} />
            </Box>
          </Box>
          <Box
            className={
              validations.upperCaseLowerCase ? "-positive" : "-negative"
            }
          >
            Have at least one upper case and lower case letter.
          </Box>
        </Box>
        <Box className={"requirement"}>
          <Box className={"mark"}>
            <Box
              className={validations.atLeastOneNumber?"iconMarkCheck":"iconMarkCross"}
              sx={{ width: 14, height: 14 }}
            >
              <CustomIcon name={validations.atLeastOneNumber?"tick":"modal-close"} color={"#fff"} fontSize={7} />
            </Box>
          </Box>
          <Box
            className={validations.atLeastOneNumber ? "-positive" : "-negative"}
          >
            Have at least one number.
          </Box>
        </Box>
        <Box className={"requirement"}>
          <Box className={"mark"}>
            <Box
              className={validations.matchRepeatedPassword?"iconMarkCheck":"iconMarkCross"}
              sx={{ width: 14, height: 14 }}
            >
              <CustomIcon name={validations.matchRepeatedPassword?"tick":"modal-close"} color={"#fff"} fontSize={7} />
            </Box>
          </Box>
          <Box
            className={
              validations.matchRepeatedPassword ? "-positive" : "-negative"
            }
          >
            Match repeated password.
          </Box>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          onClick={() =>
            updatePasswordFunc(userData.user_id, oldPassword, newPassword)
          }
          variant="success"
          className="large"
          disabled={disableSaveButton}
        >
          SAVE
        </Button>
      </Box>
    </Box>
  );
}
