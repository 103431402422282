import { handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const getGameByMenuId = async (menu_id) => {
  try {
    const response = await instance.get(
      `/api/game/get-game-by-menu/${menu_id}`
    );
    return handleResponse(response.data, true);
  } catch (error) {
    console.error(`Error Fetching Game By Menu ID`);
  }
};

export const getGameBusinessMenu = async () => {
  try {
    const response = await instance.get(
      `/api/game/business-menu?is_desktop_sequence=1&origin=${window.location.origin}`
    );
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching Game Business Menu`);
  }
};
