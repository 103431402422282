import React, { createContext, useState } from "react";

export const BusinessMenuContext = createContext();

const BusinessMenuProvider = ({ children }) => {
  const [businessMenu, setbusinessMenu] = useState([]);

  return (
    <BusinessMenuContext.Provider value={{ businessMenu, setbusinessMenu }}>
      {children}
    </BusinessMenuContext.Provider>
  );
};

export default BusinessMenuProvider;
