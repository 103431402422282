import { io } from "socket.io-client";
import { authHeader } from "../api/services/userAuthService";


export const SOCKET_LINK = process.env.REACT_APP_SOCKET_LINK;

export let socket;

const connect = (onConnect) => {
  socket = io(`${SOCKET_LINK}/v1/market`, {
    forceNew: true,
    withCredentials: true,
    extraHeaders: {
      Authorization: authHeader(),
    },
  });

  // const COOKIE_NAME = process.env.SECRET_KEY;

  // socket.io.on("open", () => {
  //   socket.io.engine.transport.on("pollComplete", () => {
  //     const request = socket.io.engine.transport.pollXhr.xhr;
  //     const cookieHeader = request?.getResponseHeader("set-cookie");
  //     if (!cookieHeader) return;
  //     cookieHeader.forEach((cookieString) => {
  //       if (cookieString.includes(`${COOKIE_NAME}=`)) {
  //         const cookie = parse(cookieString);
  //         socket.io.opts.extraHeaders = {
  //           cookie: `${COOKIE_NAME}=${cookie[COOKIE_NAME]}`,
  //         };
  //       }
  //     });
  //   });
  // });

  return new Promise((res, rej) => {
    socket?.on("connect", (socket) => {
      console.log("connected", socket);
    //   onConnect(true);
      res(socket);
    });
    socket?.once("connect_error", () => {
      // rej(new Error('connect_error'))
    });
    socket?.once("connect_timeout", function () {
      // rej(new Error('connect_timeout'));
    });
    socket?.on("reconnect", () => {
      console.log("reconnected");
    });
    socket?.on("disconnect", function () {
      console.log("disconnect");
      // onConnect(false);
    });
  });
};

const reconnect = () => {};

const joinRoom = (data) => {
  socket?.emit("join-room", JSON.stringify(data));
};

const roomJoined = (setData) => {
  socket?.on("room-joined", (data) => {
    setData(data);
  });
};

const leaveRoom = (data) => {
  socket?.emit("room-leave", JSON.stringify(data));
};
const disconnect = (userIds) => {
  socket?.disconnect()
  // const data = {
  //   leave_userIds: userIds,
  // };
  // socket?.emit("leave-room", data);
};

const listenEvent = (eventName, setData) => {
  socket?.on(eventName, (data) => {
    setData(data);
  });
};

const socketServices = {
  connect,
  joinRoom,
  listenEvent,
  disconnect,
  leaveRoom,
  roomJoined,
  reconnect,
};

export default socketServices;
