import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { getBetList } from "../../api/services/betServices";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomIcon from "../../components/customIcon";
import { useNavigate } from "react-router-dom";

export default function MyBets() {
  const userData = useSelector((state) => state.user.userData);

  const [betData, setBetData] = useState([]);
  const [current, setCurrent] = useState(true);
  const [matched, setMatched] = useState(true);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  
  const navigate = useNavigate();

  const [apiParameters, setApiParameters] = useState({
    matched: matched,
    current: current,
    limit: 1000,
    userId: userData !== null && userData.user_id,
    from: fromDate,
    to: toDate,
  });

  useEffect(() => {
    setApiParameters({
      ...apiParameters,
      matched,
      current,
      from: fromDate,
      to: toDate,
    });
  }, [current, matched]);

  useEffect(() => {
    getBeltListFunc();
  }, [apiParameters]);

  //   Api Call To bets
  async function getBeltListFunc() {
    try {
      const response = await getBetList(
        apiParameters.current
          ? apiParameters.matched
            ? "matched"
            : "un-matched"
          : "",
        apiParameters.current ? "unsettle" : "settle",
        apiParameters.limit,
        apiParameters.userId,
        moment(fromDate, "ddd MMM DD YYYY HH:mm:ss ZZ").startOf("day").unix(),
        moment(toDate, "ddd MMM DD YYYY HH:mm:ss ZZ").endOf("day").unix()
      );
      setBetData(response.data.betList);
    } catch (error) {
      console.error("Error Fetchnig Bet List");
    }
  }

  const tableHeaders = [
    [
      {
        title: "Placed",
        width: "10%",
      },
      {
        title: "Description",
        width: "45%",
      },
      {
        title: "Type",
        width: "7%",
        className: "numeric",
      },
      {
        title: "Odds",
        width: "7%",
        className: "numeric",
      },
      {
        title: "Stake",
        width: "7%",
        className: "numeric",
      },
      {
        title: "Liability",
        width: "7%",
        className: "numeric",
      },
      {
        title: "Potential Profit",
        width: "17%",
        className: "numeric",
      },
    ],
    [
      {
        title: "Settled",
        width: "10%",
      },
      {
        title: "Description",
        width: "45%",
      },
      {
        title: "Type",
        width: "7%",
        className: "numeric",
      },
      {
        title: "Odds",
        width: "7%",
        className: "numeric",
      },
      {
        title: "Stake",
        width: "7%",
        className: "numeric",
      },
      {
        title: "Profit/Loss",
        width: "17%",
        className: "numeric",
      },
      {
        title: "Status",
        width: "17%",
      },
    ],
  ];

  const renderDescription = (item) =>{
      return(
        <>
            {
              current ? 
              <a style={{cursor:"pointer"}} onClick={()=>navigate(`/sport/${item.provider_game_id}/${item.league_id}/${item.event_id}`)}>{item.event_name}</a>
              :
              <span style={{color:"#a0a0ff"}}>{item.event_name}</span>
            }
            <br />
            <span style={{ fontWeight: 600 }}>
              {item.runner_name} - <span style={{color:"#909090"}}>{item.market_name}</span>
            </span>
            <br />
            <span>
              Bet ID: {item.bet_id}{current ? matched && ` | Matched: ${moment.unix(item.matched_at).format("DD/MM/YYYY, HH:mm:ss")}`:` | Placed: ${moment.unix(item.created_at).format("DD/MM/YYYY, HH:mm:ss")}`}
            </span>
        </>
      )
  }

  return (
    <>
      <Box className={"order-listing"}>
        <Stack direction={"row"}>
          <Box
            mr={"40px"}
            sx={{
              ".item": {
                width: "100px",
                border: "1px solid ",
                borderColor: "primary.main",
                borderRadius: "3px",
                color: "primary.main",
                display: "inline-block",
                margin: "0 20px 10px 0",
                padding: "3px 8px 4px",
                cursor: "pointer",
                fontSize: "12px",
                "&.active": {
                  background: "#0f2327",
                  color: "#fff",
                },
                "&.alt-active": {
                  background: "#cae0e8",
                  color: "#0f2327",
                },
              },
            }}
          >
            <Box>
              <Box
                onClick={() => setCurrent(true)}
                className={`item ${current ? "active" : ""}`}
              >
                Current
              </Box>
              <Box
                onClick={() => setCurrent(false)}
                className={`item ${!current ? "active" : ""}`}
              >
                Past
              </Box>
            </Box>
            {current && (
              <Box>
                <Box
                  onClick={() => setMatched(true)}
                  className={`item ${matched ? "alt-active" : ""}`}
                >
                  Matched
                </Box>
                <Box
                  onClick={() => setMatched(false)}
                  className={`item ${!matched ? "alt-active" : ""}`}
                >
                  Unmatched
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={{ marginTop: "9px", height: "99px" }}>
            <Box className={"datepicker-container"}>
              <Box
                component={"label"}
                sx={{
                  display: "block",
                  marginBottom: "10px",
                }}
              >
                From
              </Box>
              <MobileDatePicker
                onChange={(e) => setFromDate(e)}
                value={apiParameters.from}
                format="DD/MM/YYYY"
                disableFuture={true}
              />
            </Box>
            <Box className={"datepicker-container"}>
              <Box
                component={"label"}
                sx={{
                  display: "block",
                  marginBottom: "10px",
                }}
              >
                To
              </Box>
              <MobileDatePicker
                onChange={(e) => setToDate(e)}
                value={apiParameters.to}
                format="DD/MM/YYYY"
                disableFuture={true}
              />
            </Box>
            <Button onClick={getBeltListFunc} variant="success">
              <Stack direction={"row"} alignItems={"center"} spacing={0.4}>
              <CustomIcon color={"#fff"} name={"search"} fontSize={14} />
                <Box>Search</Box>
              </Stack>
            </Button>
          </Box>
        </Stack>
        { (
          <Table className="listing">
            <TableHead>
              <TableRow>
                {tableHeaders[current ? 0 : 1].map((col) => {
                  return (
                    <TableCell className={col.className} width={col.width}>
                      {col.title}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {betData &&
                betData.map((item) => (
                  <TableRow>
                    <TableCell>
                      {moment.unix(current?item.created_at:item.settled_at).format("DD/MM/YYYY")}
                      <br/>
                      <span style={{color:"#909090"}}>{moment.unix(current?item.created_at:item.settled_at).format("HH:mm:ss")}</span>
                    </TableCell>
                    <TableCell>
                      {renderDescription(item)}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right !important",textTransform:"capitalize" }}>
                      {item.type}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right !important" }}>
                      {item.odds}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right !important" }}>
                      {item.bet_amount?.toFixed(2)}
                    </TableCell>
                    <TableCell className={current ? "":item.is_win?"-positive":"-negative"} sx={{ textAlign: "right !important" }}>
                      {current ? item.liability?.toFixed(2):item.is_win?item.profit?.toFixed(2):-item.liability?.toFixed(2)}
                    </TableCell>
                    {
                      current ? 
                      <TableCell sx={{ textAlign: "right !important" }}>
                        {item.profit?.toFixed(2)}
                      </TableCell>
                      :
                      <TableCell className={item.is_win?"-positive":"-negative"} sx={{ textAlign: "right !important",textTransform:"uppercase" }}>
                        {item.outcome}
                      </TableCell>
                    }
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </Box>
    </>
  );
}
