import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BusinessMenuContext } from "../context/business-menu-context";

export default function SportMenu() {
  const navigate = useNavigate();
  const sportsData = useSelector((state) => state.sports);

  const { businessMenu } = useContext(BusinessMenuContext);


  // useEffect(() => {
  //   let temp = [
  //     {
  //       provider_game_id: 10,
  //       game_name: "Live casino",
  //     },
  //     {
  //       provider_game_id: 10,
  //       game_name: "Teen patti",
  //     },
  //   ];
  //   temp = [
  //     ...temp,
  //     ...(sportsData.sportsData || []),
  //     {
  //       provider_game_id: 10,
  //       game_name: "slots",
  //     },
  //   ];
  //   setItems(temp);
  // }, [sportsData]);

  const renderMenuItems = (item) => {
    let link = item.link
    if(item.type === 'Casino'){
      link = `/sport/10`
    }else if(!link){
      link = `/sport/${item.provider_id}`
    }
    return (
      <Link to={link} style={{ color: "unset", textDecoration: "none" }}>
        <Typography
          sx={{
            fontSize: 12,
            fontWeight: "bold",
            textTransform: "uppercase",
            textShadow: "0 1px 0 #f6e4fc",
          }}
        >
          {item.menu_name}
        </Typography>
      </Link>
    );
  };

  return (
    <Box
      sx={{
        height: 30,
        bgcolor: "white",
        borderBottom: "1px solid #ddd",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
      >
        {businessMenu.map((item, index) => {
          return (
            <>
              {/* {item.menu_name !== "Live casino" && ( */}
              <Box
                sx={{
                  width: 4,
                  height: 4,
                  bgcolor: "#440a57",
                  borderRadius: "50%",
                }}
              />
              {/* )} */}
              <Box
                // key={item.id}
                sx={{
                  padding: "5px 20px",
                  transform: "perspective(1px) translateZ(0)",
                  boxShadow: "0 0 1px rgba(0, 0, 0, 0)",
                  transitionDuration: "0.5s",
                  cursor: "pointer",
                  color: item.color ? item.color : "#440a57",
                  "&:hover": {
                    transform: "scale(1.2)",
                    transitionTimingFunction:
                      "cubic-bezier(0.47, 2.02, 0.31, -0.36)",
                  },
                }}
              >
                {renderMenuItems(item)}
              </Box>
            </>
          );
        })}
      </Stack>
    </Box>
  );
}
