import Button from "./Button";
import CssBaseline from "./CssBaseline";
import Dialog from "./Dialog";
import Input from "./Input";



export default function ComponentsOverrides(theme)
{
    return Object.assign(
        CssBaseline(theme),
        Button(theme),
        Input(theme),
        Dialog(theme),
    )
}