import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { groupByArray } from "../../utils/functions";
import MatchOdds from "../eventDetail/components/matchOdds";
import MatchOddsBookmaker from "../eventDetail/components/matchOddsBookmaker";
import OtherTable from "../eventDetail/components/ohterTable";
import CustomIcon from "../../components/customIcon";


export default function Favorites(){

    const { favorites } = useSelector(state=>state.favorites);

    const [groupData,setGroupedData] = useState([]);

    const sportCustomSort = ["cricket","football","tennis","horse racing","greyhound racing"];

    useEffect(()=>{
        groupMarkets();
    },[favorites])

    const groupMarkets = () =>{
        if(favorites && favorites.length>0){
            const bySport = groupByArray(favorites,"game_name",["game_id"])
            console.log(bySport)
            bySport.map((sport)=>{
                const byEvent = groupByArray(sport.data,"event_name",["game_event_id","event_time","provider_status"])
                sport.data = byEvent.sort((a,b)=>Number(a.event_time)-Number(b.event_time))
                return sport;
            })
            setGroupedData(bySport.sort((a,b)=>sportCustomSort.indexOf(a.game_name)-sportCustomSort.indexOf(b.game_name)));
        }
    }

    return(
        <Box>
            {
                groupData.map((sport)=>{
                    return(
                    <Box key="sport">
                        <Typography variant="h2" sx={{textTransform:"capitalize"}}>{sport.game_name}</Typography>
                        <Box key="events">
                            {
                                sport.data.map((event)=>{
                                    return(
                                        <Box key={"event"}>
                                            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                                                {event.provider_status == 'IN_PLAY' && <CustomIcon name={"live"} fontSize={14} color={"#1d7f1e"} />}
                                                <Typography variant="h3">{event.event_name}</Typography>
                                            </Stack>
                                            <Stack key="markets">
                                                {
                                                    event.data.map((market)=>{
                                                        if(market.market_type === "match_odd"){
                                                            return(
                                                                <MatchOdds market={market} event={market} />
                                                            )
                                                        }
                                                        else if(market.market_type === "bookmaker"){
                                                            return(
                                                                <MatchOddsBookmaker market={market} event={market} />
                                                            )
                                                        }
                                                        else if(market.market_type === "fancy"){
                                                            return(
                                                                <OtherTable market={market} event={market} />
                                                            )
                                                        }
                                                        return ""
                                                    })
                                                }
                                            </Stack>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                    )
                })
            }
        </Box>
    )
}