import { encodeV1Response, handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const getBetList = async (
  betType,
  betStatus,
  limit = 1000,
  memberId,
  fromDate,
  toDate
) => {
  try {
    const response = await instance.get(
      `/api/bet/bet-list?bet_type=${betType}&bet_status=${betStatus}&limit=${limit}&member_id=${memberId}&from=${fromDate}&to=${toDate}`
    );
    // const response = await instance.post(
    //   `/api/bet/bet-list`,encodeV1Response({
    //     bet_type:betType,
    //     bet_status:betStatus,
    //     limit,
    //     member_id:memberId,
    //     from:fromDate.toString(),
    //     to:toDate.toString()
    //   })
    // );
    return response.data;
  } catch (error) {
    console.error("Error Fetching Bet List");
  }
};

export const postBet = async (data) => {
  try {
    const response = await instance.post(`/api/bet`, data);
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Placing Bet: `, error);
  }
};

export const userExposureByMarket = async (market_id) => {
  try {
    const response = await instance.get(
      `/api/bet/user-exposure-by-market?market_id=${market_id}`
    );
    return handleResponse(response.data,true);
  } catch (error) {
    console.error(`Error Fetching User Exposure BVy Market: ${error} :(`);
  }
};

export const userFancyExposure = async (event_id) => {
  try {
    const response = await instance.get(
      `/api/bet/ladder-exposure?event_id=${event_id}`
    );
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching User Exposure BVy Market: ${error} :(`);
  }
};

export const onUpdatedExposure = async (data) => {
  try {
    const response = await instance.post(
      `/api/exposure/firebase-runners`,data
    );
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching User Exposure BVy Market: ${error} :(`);
  }
};


export const openBet = async (bet_type) => {
  try {
    const response = await instance.get(
      `/api/bet/open-bet?bet_type=${bet_type}`
    );
    return handleResponse(response.data, true);
  } catch (error) {
    console.error(`Error Fetching Open Bets: ${error}`);
  }
};

export const api_placeBet = async (placeBetData) => {
  try {
    const response = await instance.post("/api/bet/desktop", placeBetData);
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Placing Bets", error);
  }
};

export const pendingBet = async (bets) => {
  try {
    const response = await instance.post(`/api/bet/pending-bet`, { bets });
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error Fetching Open Bets: ${error}`);
  }
};

export const cancelBet = async (bet_id) => {
  try {
    const response = await instance.get(`api/bet/bet-cancel?bet_id=${bet_id}`);
    return handleResponse(response.data);
  } catch (error) {
    console.error(`Error While Cancelling Bet: ${error}`);
  }
};
