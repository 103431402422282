import React, { useState, useEffect } from "react";
import EventTab from "./eventTab";
import { Stack } from "@mui/material";

const EventTabs = ({ events, currentCategory, categoryToShow, tabData, openEvent }) => {
  const [currentCategoryId, setCurrentCategoryId] = useState(2);

  

  useEffect(() => {
    const givenCategory = tabData.find(
      (category) => category.categoryTitle === categoryToShow
    );

    // Use givenCategory to set the currentCategoryId if needed
    if (givenCategory) {
      setCurrentCategoryId(givenCategory.categoryId);
    }
  }, [categoryToShow, tabData]);

  useEffect(() => {
    console.log({ currentCategory });
  }, [currentCategory]);

  return (
    <>
      {events && (
        <>
          {currentCategory ? (
            // Category tabs
            <Stack
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridGap: 12,
              }}
            >
              {events.map((event, i) => (
                <EventTab
                  openEvent={openEvent}
                  key={i}
                  image={event.game_thumbnail}
                  altText={event.event_name}
                  event={event}
                />
              ))}
            </Stack>
          ) : (
            // All Tabs
            <Stack
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gridGap: 12,
              }}
            >
              {events.map((event, i) => (
                <EventTab
                  openEvent={openEvent}
                  key={i}
                  image={event.game_thumbnail}
                  altText={event.event_name}
                  event={event}
                />
              ))}
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default EventTabs;
