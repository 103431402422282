import { createSlice } from '@reduxjs/toolkit';

const initialState ={ values:[100,500,1000,5000,10000,25000]};


const stakesSlice = createSlice({
    name:"stakes",
    initialState:initialState,
    reducers:{
        changeStake:(state,action)=>{
            state.values = [...action.payload]; 
        },
        clearStake:()=>initialState
    }
});

export const {changeStake,clearStake} = stakesSlice.actions;

export default stakesSlice.reducer;