import { handleResponse } from '../../utils/functions'
import {instance} from './apiService'

export const getLeaguesBySporId = async (game_id)=>{
    try {
        const response = await instance.get(`/api/league/${game_id}`)
        return handleResponse(response.data)
    } catch (error) {
        console.error(`Error Fetching Leagues By Sporttt ID`);
        throw error
    }
}