import { Card, CardMedia } from "@mui/material";
import React, { useEffect, useState } from "react";

const EventTab = ({ image, altText, openEvent, event }) => {
  console.log({ event }, event?.provider_event_id);

  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      gameId: event?.provider_event_id,
      is_mobile: false,
      clientIp: "192.168.0.1",
      providerName: event?.provider_name,
      provider_type: event?.provider_type,
      redirectUrl: "https://server.maverick777.in",
    });
  }, [event]);

  return (
    <Card onClick={() => openEvent(data)}>
      <CardMedia component={"img"} height={"250"} image={image} alt={altText} />
    </Card>
  );
};

export default EventTab;
