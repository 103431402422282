import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import EventTabs from "./eventTabs";
import { liveCasinoData } from "../../../../config/liveCasinoData";

const AllEvents = ({ events, categories, openEvent }) => {
  useEffect(() => {
    console.log("categories", categories);
  }, [categories]);
  return (
    <>
      {categories.map((category) => {
        const filteredEvents = events.filter(
          (event) => event.game_id === category.game_id
        );

        // Only render Typography if there are events for the category
        return (
          filteredEvents.length > 0 && (
            <>
              <Typography
                sx={{
                  mt: 4,
                  mb: 2,
                  pb: 0.5,
                  borderBottom: "1px solid",
                  display: "inline-block",
                  fontWeight: 600,
                }}
              >
                {category.game_name}
              </Typography>
              <EventTabs
                openEvent={openEvent}
                events={filteredEvents}
                tabData={liveCasinoData[0].categories}
              />
            </>
          )
        );
      })}
    </>
  );
};

export default AllEvents;
