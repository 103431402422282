import { Box, Button, Dialog, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default function ConfirmBetModal({ open, onClose = () => {}, parent,placeBet = () =>{} }) {

  const [offset, setOffset] = useState();
  useEffect(() => {
    setTimeout(() => {
      if (parent && parent.current && open) {
        setOffset(parent.current.getBoundingClientRect());
      }
    }, 10);
  }, [parent, open]);

  const handleConfirm = () =>{
    onClose();
    placeBet();
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        ".MuiModal-backdrop": {
          background: "rgba(0, 0, 0, 0.5)",
        },
        ".MuiDialog-paper": {
          borderRadius: "0",
          maxWidth: "270px",
          maxHeight: "150px",
          position: "absolute",
          top: "0",
          left: "0",
          bottom: "0",
          right: "0",
          margin: "auto",
          color: "#0f2327",
          background: "#fff",
          zIndex: "2000",
          marginLeft: `${offset?.x + 20}px`,
          marginTop: `${offset?.top + 20}px`,
        },
      }}
    >
      <Box padding={"20px"} px={"40px"} height={"100%"}>
        <Stack justifyContent={"space-between"} height={"100%"}>
          <Box component={"p"}>Are you sure you want to place your bets?</Box>
          <Stack direction={"row"} justifyContent={"center"} mt={"10px"}>
            <Button onClick={onClose} variant="text" className="large">
              Cancel
            </Button>
            <Button onClick={handleConfirm} variant="success" className="large">
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
}
