

import React, { createContext, useState } from "react";
import ChangeDefaultPasswordModal from "../components/modals/changeDefaultPasswordModal";
import { logout as userLogout } from "../api/services/userAuthService";
import KycModal from "../components/modals/KYCModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUser } from "../redux/reducers/user";
import { clearBets } from "../redux/reducers/bets";
import { removeAllExposures } from "../redux/reducers/exposure";
import socketServices from "../sockets/socketService";
import eventSocket from "../sockets/eventSocket";
import userSocket from "../sockets/userSocket";
import sportSocket from "../sockets/sportSocket";
import { clearFavorites } from "../redux/reducers/favorites";

export const LoginContext = createContext();

const LoginProvider = ({ children }) => {
    const [openLogin, setLogin] = useState(false);
    const { userData,isLogginIn } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

  const isDefaultPasswordChanged = Boolean(userData?.is_password_changed)

  const isKYCDone = Boolean(userData?.is_condition_accepted);


  const logout = async () => {
    try {
      const response = await userLogout();
      if (response.status === 200) {
        clearAll()
      }
      console.log(response);
      return response;
    } catch (error) {
      console.error("Logout Failed: ", error.response.status);
      clearAll()
    }
  };


  const clearAll = () =>{
    sessionStorage.clear("token");
    dispatch(clearUser());
    dispatch(clearBets());
    dispatch(removeAllExposures())
    dispatch(clearFavorites())
    socketServices.disconnect();
    eventSocket.disconnect();
    userSocket.disconnect();
    sportSocket.disconnect();
    navigate("/");
  }

  return (
    <LoginContext.Provider value={{ openLogin, setLogin,logout }}>
      <ChangeDefaultPasswordModal open={(isLogginIn && !isDefaultPasswordChanged)} />
      <KycModal open={(isDefaultPasswordChanged && !isKYCDone)}  />
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;