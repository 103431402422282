import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenuData } from "./api/services/menuService";
import {
  getGameBusinessMenu,
  getGameByMenuId,
} from "./api/services/gameService";
import { selectMenuItem, updateMenuData } from "./redux/reducers/menu";
import { updateSportsData } from "./redux/reducers/sports";
import store from "./redux/store";
import { sports } from "./config/data";
import socketServices from "./sockets/socketService";
import userSocket from "./sockets/userSocket";
import { clearUser, updateBalance } from "./redux/reducers/user";
import { clearBets } from "./redux/reducers/bets";
import { useNavigate } from "react-router-dom";
import eventSocket from "./sockets/eventSocket";

import { useLocation } from "react-router-dom";
import { BusinessMenuContext } from "./context/business-menu-context";
import sportSocket from "./sockets/sportSocket";
import { LoginContext } from "./context/login-context";

const Initialization = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const [pathname, setPathname] = useState(location.pathname.split("/"));
  const { businessMenu, setbusinessMenu } = useContext(BusinessMenuContext);
  const { logout } = useContext(LoginContext);


  const setBalance = (data) =>{
    dispatch(updateBalance(data));
  }

  const getBusinessMenu = async () => {
    const response = await getGameBusinessMenu();
    if (response) {
      setbusinessMenu(response);
    }
  };

  useEffect(() => {
    getBusinessMenu();
  }, []);

  useEffect(() => {
    setPathname(location.pathname.split("/"));
  }, [location.pathname]);

  useEffect(() => {
    if (pathname !== null && pathname[1] === "account") {
      if (!user.isLogginIn) {
        navigate("/home");
      }
    }
  }, [pathname, user.isLogginIn, navigate]);

  const dispatch = useDispatch();
  const menuData = useSelector((state) => state.menu);

  const getMenuData = async () => {
    try {
      const response = await fetchMenuData();
      console.log();
      dispatch(updateMenuData(response));
      const sportsResponse = await getGameByMenuIdFunc(
        response.find((game) => game.game_type === "sport").menu_id
      );
    } catch (error) {
      console.error("Error Fetching Menu Data", error);
    }
  };

  const getGameByMenuIdFunc = async (menuId) => {
    try {
      const response = await getGameByMenuId(menuId);
      return response;
    } catch (error) {
      console.error("Error Fetching Game By Menu Id", error);
    }
  };

  const getGamesByMenuId = async (menuId, actionCreator) => {
    try {
      const response = await getGameByMenuIdFunc(menuId);
      const modifiedResponse = response.map((res) => {
        return {
          ...res,
          bgcolor: sports.find((sport) => sport.id === res.provider_game_id)
            ?.bgcolor,
          hoverColor: sports.find((sport) => sport.id === res.provider_game_id)
            ?.hoverColor,
        };
      });
      dispatch(actionCreator(modifiedResponse));
    } catch (error) {
      console.error(`Error Fetching Games for Menu Id ${menuId}`, error);
    }
  };

  useEffect(() => {
    if (menuData.menuData) {
      const sportsMenu = menuData.menuData.find(
        (menu) => menu.game_type === "sport"
      );
      const casinoMenu = menuData.menuData.find(
        (menu) => menu.game_type === "casino"
      );

      if (sportsMenu) {
        getGamesByMenuId(sportsMenu.menu_id, updateSportsData);
      }

      if (casinoMenu) {
        // getCasinoGames();
        // getGamesByMenuId(casinoMenu.menu_id, updateSportsData);
      }
    }
  }, [dispatch, menuData.menuData]);

  useEffect(() => {
    getMenuData();
  }, []);

  // const handleLogout = async () => {
  //   sessionStorage.clear("token");
  //   dispatch(clearUser());
  //   dispatch(clearBets());
  //   navigate("/");
  // };

  const onUserConnect = (isConnected) => {
    if (isConnected) {
      userSocket.getBalanceData(setBalance);
      userSocket.joinUser();
      userSocket.logoutUser(logout);
    }
  };

  useEffect(() => {
    if (user.isLogginIn) {
      socketServices.connect();
      userSocket.connect(onUserConnect);
      eventSocket.connect();
      sportSocket.connect(()=>{})
    }
  }, [user.isLogginIn]);
};

export default Initialization;
