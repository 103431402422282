import { handleResponse } from "../../utils/functions";
import { instance } from "./apiService";

export const getUserTransferStatement = async ()=>{
    try {
        const response = await instance.get('/api/transfer/user-transfer-statement?isDesktop=true')
        return handleResponse(response.data,true)
    } catch (error) {
        console.log('Error Fetching User Transfer Statement', error)
    }
}