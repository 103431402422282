import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { generateBarcode, verifyOtp } from "../../api/services/securityService";
import { useEffect, useState } from "react";

export default function Security() {
  const [enteredOtp, setEnteredOtp] = useState("");
  const [barcodeData, setbarcodeData] = useState("");

  const generateBarcodeFunc = async () => {
    try {
      const response = await generateBarcode();
      setbarcodeData(response.data);
    } catch (error) {
      console.error("Error Generating Barcode", error);
    }
  };

  //   Verify OTP
  const handleVeriftOtp = async (token) => {
    try {
      const response = await verifyOtp(token);
      console.log(response);
    } catch (error) {
      console.error("Error Verifying Otp", error);
    }
  };

  useEffect(() => {
    generateBarcodeFunc();
  }, []);


  return (
    <Box>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Two-factor authentication
      </Box>

      <Stack
        px={6}
        py={3}
        sx={{
          bgcolor: "#fff",
          width: "71.01%",
        }}
        direction={"row"}
        gap={6}
      >
        <Box flex={1} alignItems={"center"}>
          {barcodeData ? (
            <img width={"100%"} title="QR" src={barcodeData.data.qrcode} />
          ) : (
            <div>Loading...</div>
          )}
        </Box>
        <Box flex={2}>
          <List>
            <ListItem
              disablePadding
              sx={{ flexDirection: "column", alignItems: "center", py: 0.5 }}
            >
              <ListItemText>
                <span style={{ fontWeight: 600 }}>Step 1:</span> Download Google
                Authenticator on your phone directly from AppStore or
                GooglePlay.
              </ListItemText>
              <Stack direction={"row"} gap={4} mt={1}>
                <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605" target="_blank"><img src="https://d2eb1cys5jgnub.cloudfront.net/m/tf/download-on-the-app-store.webp" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US" target="_blank"><img src="https://d2eb1cys5jgnub.cloudfront.net/m/tf/get-it-on-google-play.webp" /></a>
              </Stack>
            </ListItem>
            <ListItem sx={{ py: 0.5 }} disablePadding>
              <ListItemText>
                <span style={{ fontWeight: 600 }}>Step 2:</span> Setup account
                within Google Authenticator.
              </ListItemText>
            </ListItem>
            <ListItem sx={{ py: 0.5 }} disablePadding>
              <ListItemText>
                <span style={{ fontWeight: 600 }}>Step 3:</span> Scan QR in
                Google Authenticator and enter the verification code down below.
              </ListItemText>
            </ListItem>
          </List>

          <TextField
            value={enteredOtp}
            onChange={(e)=>{setEnteredOtp(e.target.value)}}
            sx={{
              width: "100%",
              py: 1,
              mb: 1,
              "& .MuiInputBase-input": {
                p: 2,
              },
              "& .MuiInputBase-input::placeholder": {
                fontSize: "1rem",
              },
            }}
            type="text"
            placeholder="Verification code"
          />
          <Button
            onClick={() => verifyOtp(enteredOtp)}
            variant="contained"
            sx={{
              bgcolor: "#5c996f",
              width: "100%",
              mt: 1,
              fontSize: "1rem",
              fontWeight: 400,
              p: 0.5,
              boxShadow: `0 0 0 0 #fff`,
              border: "0px",
              "&:hover": {
                bgcolor: "#5c996f",
                color: "#fff",
                boxShadow: `1.5px 1.5px 0px 0px #3f694c`,
                borderBottom: "2px solid #3f694c",
              },
            }}
          >
            Enable Two-factor authentication
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
