import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Box, Stack } from "@mui/material";
import { LiveTv, PlayCircle, Scoreboard } from "@mui/icons-material";
import CustomIcon from "../../../components/customIcon";
import { useSelector } from "react-redux";

const ScoreBoard = ({ data, scoreAndLiveAvailable }) => {
  const [value, setValue] = useState("");
  const [iframeHeight, setIframeHeight] = useState(0);
  const {isLogginIn} = useSelector((state) => state.user);

  const liveVideoRef = useRef();

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setIframeHeight(+(liveVideoRef.current.clientWidth * 9) / 16);
  }, [liveVideoRef.current]);

  useEffect(()=>{
    if(isLogginIn) setValue("scores")
  },[isLogginIn])

  return (
    <>
      <Stack
        ref={liveVideoRef}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingRight={"10px"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            minHeight: "fit-content !important",
            "& .MuiTab-root": {
              color: "#fff !important",
              border: "1px solid #ffffff90",
              borderBottom: "none",
              boxShadow: "inset 0px 0 50px -25px #fff",
              padding: "0.5rem",
              minHeight: "fit-content",
              fontWeight: 400,
              transition: "0.125s all ease-in",
            },
            "& .Mui-selected": {
              background: `#1F5058`,
            },
          }}
        >
          {isLogginIn && scoreAndLiveAvailable.is_live_score &&
            scoreAndLiveAvailable.is_score_available && (
              <Tab
                value={"scores"}
                label="Scores"
                icon={<Scoreboard fontSize={"14"} />}
                iconPosition="start"
              />
            )}
          {isLogginIn && scoreAndLiveAvailable.is_live_stream &&
            scoreAndLiveAvailable.is_stream_available && (
              <Tab
                value={"liveVideo"}
                label="Live Video"
                icon={<PlayCircle fontSize={"14"} />}
                iconPosition="start"
              />
            )}
          {isLogginIn && Object.values(scoreAndLiveAvailable).every((el) => el === true) && (
            <Tab
              value={"scoresAndLive"}
              label="Scores & Live"
              icon={<LiveTv fontSize={"14"} />}
              iconPosition="start"
            />
          )}
        </Tabs>

        <Box className="rule-box" sx={{ mb: "6px" }}>
          <Stack direction={"row"} alignItems={"center"} spacing={0.75}>
            <CustomIcon name="info" fontSize={16} color={"#fff"} />
            <Box>Rules</Box>
          </Stack>
        </Box>
      </Stack>

      {value === "scores" &&
        scoreAndLiveAvailable.is_live_score &&
        scoreAndLiveAvailable.is_score_available && (
          <iframe
            style={{ border: "none !important" }}
            src={`https://spacex365.net/api/stream/vScore/${data?.provider_event_id}`}
            width={"100%"}
            height={`${iframeHeight}px`}
          />
        )}
      {value === "liveVideo" &&
        scoreAndLiveAvailable.is_live_stream &&
        scoreAndLiveAvailable.is_stream_available && (
          <iframe
            style={{ border: "none !important" }}
            src={`https://spacex365.net/api/stream/vStream/${data?.provider_event_id}`}
            width={"100%"}
            height={`${iframeHeight}px`}
          />
        )}

      {value === "scoresAndLive" &&
        Object.values(scoreAndLiveAvailable).every((el) => el === true) && (
          <Stack direction={"row"}>
            <iframe
              style={{ border: "none !important" }}
              src={`https://spacex365.net/api/stream/vScore/${data?.provider_event_id}`}
              width={"100%"}
              height={`${iframeHeight}px`}
            />
            <iframe
              style={{ border: "none !important" }}
              src={`https://spacex365.net/api/stream/vStream/${data?.provider_event_id}`}
              width={"100%"}
              height={`${iframeHeight}px`}
            />
          </Stack>
        )}
    </>
  );
};

export default ScoreBoard;
