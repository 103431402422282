import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import BetButton from "../../../components/miniComponents/betButton";
import socketServices from "../../../sockets/socketService";
import { useSelector } from "react-redux";

export default function OddsTable({ data, event }) {
  const [suspended, setSuspended] = useState(true);
  const [more, setMore] = useState(false);
  const [ballRunning, setBallRunning] = useState(false);
  const [roomData, setRoomData] = useState([]);
  const [odds, setOdds] = useState({});
  const { isLogginIn } = useSelector((state) => state.user);

  useEffect(() => {
    setOdds(data);
    setSuspended(data.provider_status === "SUSPENDED");
    setMore(data.more);
    setBallRunning(data.ballRunning);
  }, [data]);

  // const backPriceRunner0 = data?.runners[0]?.back[0]?.price;
  // const layPriceRunner0 = data?.runners[0]?.lay[0]?.price;
  // const backPriceRunner1 = data?.runners[1]?.back[0]?.price;
  // const layPriceRunner1 = data?.runners[1]?.lay[0]?.price;

  const handleData = (data) => {
    if (data.data) {
      let temp = roomData;
      temp.push(data.data);
      setRoomData(temp);
    }
  };

  const handleMatchOddsSocketData = (data) => {
    setOdds({...event,...data.data});
    if (data.data.provider_event_id == `32853025`) {
      console.log(data.data);
    }
    switch (data.data.provider_status) {
      case "SUSPENDED":
        setSuspended(true);
        break;
      case "BALL_RUNNING":
        setBallRunning(true);
        break;
      default:
        setSuspended(false);
        setBallRunning(false);
    }
  };

  useEffect(() => {
    if(isLogginIn && event){

      const data = {
        roomNames: `${event.provider_event_id}/${event.provider_market_id}`,
        marketType: "match_odd",
      };
      socketServices.joinRoom(data);
      socketServices.listenEvent(
        `${event.provider_event_id}/${event.provider_market_id}`,
        handleMatchOddsSocketData
      );
    }
  }, [event,isLogginIn]);

  useEffect(() => {
    socketServices.roomJoined(handleData);

    return () => {
      roomData.forEach((el) => {
        socketServices.leaveRoom(el);
      });
    };
  }, []);

  return (
    <TableCell
      className={`${
        suspended
          ? "-status suspended"
          : more
          ? "-status more"
          : "inner-table-container"
      }`}
      colSpan={6}
    >
      {suspended ? (
        <Box className="status-label">SUSPENDED</Box>
      ) : more ? (
        <Box className="status-label-more">See More markets</Box>
      ) : (
        <>
          <Table className="inner-table">
            <TableBody>
              <TableRow>
                <BetButton
                  event={event}
                  market={odds}
                  runner={odds?.runners[0]}
                  odds={odds && odds?.runners[0]?.back[0]?.price}
                  side={0}
                  className="back"
                  type="back"
                >
                  {odds.runners[0].back[0].price}
                </BetButton>
                <BetButton
                  event={event}
                  market={odds}
                  runner={odds.runners[0]}
                  odds={odds.runners[0].lay[0].price}
                  side={1}
                  className="lay"
                  type="lay"
                >
                  {odds.runners[0].lay[0].price}
                </BetButton>
                {
                  odds.runners[2] ?
                  <>
                      <BetButton
                        event={event}
                        market={odds}
                        runner={odds.runners[2]}
                        odds={odds.runners[2].back[0].price}
                        side={0}
                        className="back"
                        type="back"
                      >
                        {odds.runners[2].back[0].price}
                    </BetButton>
                    <BetButton
                      event={event}
                      market={odds}
                      runner={odds.runners[2]}
                      odds={odds.runners[2].lay[0].price}
                      side={1}
                      className="lay"
                      type="lay"
                    >
                      {odds.runners[2].lay[0].price}
                    </BetButton>
                  </>
                  :
                  <>
                  <TableCell className="empty"></TableCell>
                  <TableCell className="empty"></TableCell>
                  </>
                }
                <BetButton
                  event={event}
                  market={odds}
                  runner={odds.runners[1]}
                  odds={odds.runners[1].back[0].price}
                  side={0}
                  className="back"
                  type="back"
                >
                  {odds.runners[1].back[0].price}
                </BetButton>
                <BetButton
                  event={event}
                  market={odds}
                  runner={odds.runners[1]}
                  odds={odds.runners[1].lay[0].price}
                  side={1}
                  className="lay"
                  type="lay"
                >
                  {odds.runners[1].lay[0].price}
                </BetButton>
              </TableRow>
            </TableBody>
          </Table>

          {ballRunning && (
            <Box className="ball-running-overlay">Ball Running</Box>
          )}
        </>
      )}
    </TableCell>
  );
}
