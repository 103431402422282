import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSportById } from "../utils/functions";
import { compitionData, raceData } from "../config/data";
import { useSelector } from "react-redux";
import { getLeaguesBySporId } from "../api/services/leagueService";
import NestedItem from "./Nesteditem";
import { getGameByMenuId } from "../api/services/gameService";
import { BusinessMenuContext } from "../context/business-menu-context";
import CustomIcon from "./customIcon";
import { getRacesBySport } from "../api/services/eventsService";
import moment from "moment";
import sportSocket from "../sockets/sportSocket";
import { Racescontext } from "../context/races-context";

export default function SideMenu() {
  const { businessMenu } = useContext(BusinessMenuContext);
  const [openLeauge, setOpenLeague] = useState(true);
  const navigate = useNavigate();
  const { sportId, compId,marketId, eventId } = useParams();
  const [sport, setSport] = useState();
  const [showMainMenu, setShowMainMenu] = useState(true);
  const [isRace, setIsRace] = useState(false);
  const { isLogginIn } = useSelector((state) => state.user);
  const { pathname } = useLocation();

  const sportsData = useSelector((state) => state.sports);
  const [compitionData, setCompitionData] = useState([]);

  const {races} = useContext(Racescontext)
  // const [races, setRaces] = useState([]);


  const getLeagueBySportIdFunc = async (game_id) => {
    try {
      const response = await getLeaguesBySporId(game_id);
      setCompitionData(response);
    } catch (error) {
      console.error(`Error Fetching League By Sport Id: `, error);
    }
  };


  const handleGameClick = (item) => {
    let link = item.link;
    if (item.type === "Casino") {
      link = `/sport/10`;
    } else if (!link) {
      link = `/sport/${item.provider_id}`;
    }

    return link;
  };

  useEffect(() => {
    if (sport) {
      if(isRace){
      }
      else{
        getLeagueBySportIdFunc(sport.game_id);
      }
    }
  }, [sport,isRace]);
  

  useEffect(() => {
    const paths = pathname.split("/");
    if (sportId && sportId !== "10" && sportId !== "9") {
      setShowMainMenu(false);
      if (getSportById(sportId, sportsData.sportsData))
        setSport(getSportById(sportId, sportsData.sportsData));
    } else if (paths.includes("account")) {
      setSport(null);
      setShowMainMenu(false);
    } else setShowMainMenu(true);
    if (sportId === "4339" || sportId === "7") setIsRace(true);
    else setIsRace(false);
  }, [sportId, pathname]);


  const renderMainMenuItem = (item) =>{
    return(
      <Box
              key={item.menu_name}
              sx={{
                paddingLeft: "0",
                minHeight: "60px",
                boxShadow: "inset 0 -1px 0 rgba(73, 87, 105, 0.1)",
                cursor: "pointer",
                width: "100%",
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                ":hover": {
                  transform: "scale(1.1)",
                  transformOrigin: "center center",
                  boxShadow:
                    "inset 0 1px 0 rgba(73, 87, 105, 0.1), inset 0 -1px 0 rgba(73, 87, 105, 0.1)",
                  transition: "transform ease 0.1s",
                  background: "#f5f5f5",
                },
              }}
              onClick={() => navigate(handleGameClick(item))}
            >
              <Stack
                direction={"row"}
                pl={"20px"}
                spacing={"10px"}
                alignItems={"center"}
              >
                {/* <Box sx={{ width: 18, height: 18, bgcolor: "grey" }}></Box> */}
                <CustomIcon name={item.icon} fontSize={18} />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    lineHeight: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {item.menu_name}
                </Typography>
                {
                  item.is_new &&
                <Box className="new-label">New</Box>
                }
              </Stack>
            </Box>
    )
  }

  return (
    <List
      sx={{
        height: "100%",
        width: "186px",
        borderLeft: "1px solid #ddd",
        borderRight: "1px solid #ddd",
        background: "white",
        overflow: "hidden",
        display: "table-cell",
        verticalAlign: "top",
        py:0
      }}
    >
      {showMainMenu ? (
        <>
        {
          isLogginIn && renderMainMenuItem({menu_name:"Favorites",icon:"favorite",link:"/favorite"})
        }
       {   businessMenu.map((item) => {
            return renderMainMenuItem(item);
          })}
        </>
      ) : (
        <>
          {isLogginIn && (
            <Box className={"groupitem"} onClick={() => navigate("/favorite")}>
              <Stack
                direction={"row"}
                pl={"13px"}
                spacing={"10px"}
                alignItems={"center"}
              >
                <CustomIcon name={"favorite"} fontSize={18} />
                <Typography
                  variant="body2"
                  sx={{ fontWeight: 700, lineHeight: "16px" }}
                >
                  Favourites
                </Typography>
              </Stack>
            </Box>
          )}
          <Box className={"groupitem"} onClick={() => navigate("/sport")}>
            <Stack
              direction={"row"}
              pl={"13px"}
              spacing={"10px"}
              alignItems={"center"}
            >
              <CustomIcon name={"home"} fontSize={18} />
              <Typography
                variant="body2"
                sx={{ fontWeight: 700, lineHeight: "16px" }}
              >
                Sports
              </Typography>
            </Stack>
          </Box>
          {sport && (
            <Box
              className={"groupitem"}
              onClick={() => navigate(`/sport/${sportId}`)}
              sx={{
                bgcolor: sport.bgcolor,
                ":hover": { bgcolor: `${sport.hoverColor} !important` },
              }}
            >
              <Stack
                direction={"row"}
                pl={"13px"}
                spacing={"10px"}
                alignItems={"center"}
              >
                {/* <Box sx={{ width: 18, height: 18, bgcolor: "white" }}></Box> */}
                <CustomIcon name={sport.game_name} fontSize={18} color={"#fff"} />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 700,
                    color: "#fff",
                    lineHeight: "16px",
                    textTransform: "capitalize",
                  }}
                >
                  {sport.game_name}
                </Typography>
              </Stack>
            </Box>
          )}
          {isRace
            ? races.map((race) => {
                return (
                  <Box
                    onClick={() =>
                      navigate(`/sport/${sportId}/${race.game_event_id}/${race.market_id}/race`)
                    }
                    className={`group-name ${
                      race.market_id.toString() === marketId ? "active" : ""
                    }`}
                    sx={{
                      borderLeftColor:
                        race.market_id.toString() === marketId
                          ? `${sport.bgcolor} !important`
                          : "none",
                      bgcolor:
                        race.market_id.toString() === marketId
                          ? `${sport.bgcolor}12 !important`
                          : "none",
                    }}
                  >
                    {race.market_time && moment.unix(race.market_time).format("HH:mm")} {race.event_name}
                  </Box>
                );
              })
            : sport && compitionData
            ? compitionData.map((comp) => {
                return (
                  <NestedItem
                    openLeauge={openLeauge}
                    setOpenLeague={setOpenLeague}
                    sportId={sportId}
                    comp={comp}
                    compId={compId}
                    sport={sport}
                  />
                );
              })
            : ""}
        </>
      )}
    </List>
  );
}
