// favorite.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favorites:[]
};

const favoriteSlice = createSlice({
  name: "favorites",
  initialState: initialState,
  reducers: {
    addFavorite: (state, action) => {
      state.favorites = [...state.favorites,action.payload];
    },
    removeFavorite:(state,action) =>{
      state.favorites = state.favorites.filter((fav)=>fav.market_id !== action.payload);
    },
    removeFavoriteByProvider:(state,action) =>{
      state.favorites = state.favorites.filter((fav)=>fav.provider_market_id !== action.payload);
    },
    clearFavorites: () => initialState,
  },
});

export const { addFavorite,removeFavorite,removeFavoriteByProvider,clearFavorites } = favoriteSlice.actions;

export default favoriteSlice.reducer;