import { instance } from "./apiService";
import { decodeV1Response, encodeV1Response, getIP, handleCatch, handleResponse } from "../../utils/functions";

export const login = async (user_name, password) => {
  try {
    const data = {
      user_name,
      password,
      ip_address: "59.152.54.146",
    }

    // const response = await instance.post("/api/auth/user-login", encodeV1Response(data));
    const response = await instance.post("/api/auth/user-login", data);
    sessionStorage.setItem("token", response.data.token);
    // response.data.data = decodeV1Response(response.data.data)
    return response.data;
  } catch (error) {
    console.log("Error While Logging into LotusBook", error);
  }
};

export const logout = async () => {
  try {
    const response = await instance.get("/api/auth/logout");
    return response;
  } catch (error) {
    console.error("Error Logging Out", error);
    throw error
  }
};

export const updatePassword = async (userID, oldPassword, newPassword) => {
  try {
    const ip = await getIP()
    const response = await instance.put(`api/user/update-password/${userID}`, {
      old_password: oldPassword,
      new_password: newPassword,
      ip_address:ip,
    });
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Updating Password", error);
    handleCatch(error);
  }
};

export const updateDefaultPassword = async (userID, password) => {
  try {
    const ip = await getIP()
    const response = await instance.put(`api/user/update-default-password/${userID}`, {
      password: password,
      ip_address:ip,
    });
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Updating Password", error);
  }
};


export const acceptCondition = async (userID) => {
  try {
    const response = await instance.put(`api/user/update-accept-condition/${userID}`, {
      is_condition_accepted: true,
    });
    return handleResponse(response.data);
  } catch (error) {
    console.error("Error Updating KYC", error);
  }
};

export function authHeader() {
  // return authorization header with jwt token
  const token = sessionStorage.getItem("token");
  if (token) {
    return `${token}`;
  }
}