import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLogginIn:false,
    userData: null,
    balanceData:{
        available_credit: 0,
        credit_limit: 0,
        total_net_exposure: 0,
        winnings: 0,
    },
    settings:{
        confirmBet:false,
        is_one_click_enabled:false,
        active_one_click_stake:0,
        utc:{
            value:"+05:30",
            id:"1",
        }
    }
}


const userSlice = createSlice({
    name:"user",
    initialState:initialState,
    reducers:{
        login:(state,action)=>{
            state.isLogginIn = true;
            state.userData = action.payload
        },
        editStakes: (state, action)=>{
            state.userData.stake = action.payload
            console.log(action.payload)
        },
        conditionAccepted:(state,action)=>{
            state.userData.is_condition_accepted = true;
        },
        changeConfirmBet:(state,action)=>{
            state.settings.confirmBet = action.payload;
        },
        changeOneClickStatus:(state,action)=>{
            state.userData.is_one_click_bet_enable = action.payload
        },
        changeOneClickStakes:(state,action)=>{
            state.userData.one_click_stake = action.payload
        },
        changeActiveOneClick:(state,action)=>{
            state.settings.active_one_click_stake = action.payload
        },
        changeTimeZone:(state,action)=>{
            state.settings.utc = action.payload;
        },
        updateBalance:(state,action)=>{
            state.balanceData = action.payload
        },
        clearUser:()=>initialState
    }
});

export const {login,editStakes,conditionAccepted,changeConfirmBet,changeOneClickStatus,changeTimeZone,changeActiveOneClick,changeOneClickStakes,clearUser,updateBalance} = userSlice.actions;

export default userSlice.reducer;