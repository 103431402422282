import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useEffect, useRef, useState, useContext } from "react";
import EditStakeModal from "./modals/editStakeModal";
import { useSelector } from "react-redux";
import BetSlip from "./miniComponents/betSlip";
import { cancelBet, openBet, pendingBet } from "../api/services/betServices";
import { Close } from "@mui/icons-material";

import { Link, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { BetSlipContext } from "../context/bet-slip-context";
import UnmatchedBetTable from "./miniComponents/unmatched-bet-table";
import CustomIcon from "./customIcon";
import { groupByArray } from "../utils/functions";
import moment from "moment";
import Loader from "./miniComponents/loader";
import { removeMatch, subscribeToFirestoreCollection } from "../utils/firebase";
import userSocket from "../sockets/userSocket";

const BetManager = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { currentTab, changeTab } = useContext(BetSlipContext);
  const [showMatch, setShowMatch] = useState(true);
  const [showUnMatch, setShowUnMatch] = useState(true);
  const [showPending, setShowPending] = useState(true);
  const [showVoid, setShowVoid] = useState(true);
  const [editStake, setEditStake] = useState(false);
  const [openConfirmBet, setOpenConfirmBet] = useState(false);
  const ref = useRef();
  const { bets } = useSelector((state) => state.bets);

  const { eventId, sportId } = useParams();

  const [matchedBets, setMatchedBets] = useState([]);
  const [filteredMatchedBets, setFilteredMatchedBets] = useState([]);
  const [groupedMatchedBets, setgroupedMatchedBets] = useState([]);
  const [unMatchedBets, setUnMatchedBets] = useState([]);
  const [groupedUnMatchedBets, setgroupedUnMatchedBets] = useState([]);
  const [filteredUnMatchedBets, setFilteredUnMatchedBets] = useState([]);
  const [pendingBets, setPendingBets] = useState([]);
  const [pendingBetsId, setPendingBetsId] = useState([]);
  const [placingBet,setPlacingBet] = useState(false);

  const [showBetInfo,setShowBetInfo] = useState(false);
  const { userData,isLogginIn } = useSelector((state) => state.user);
  const [fireBaseData, setFireBaseData] = useState([]);

  const getMatchedBetsData = async (bet_type) => {
    const response = await openBet(bet_type);
    if (response) {
      if (bet_type === "matched") {
        setMatchedBets(response.bets);
        console.log(response.bets);
        setFilteredMatchedBets(response.bets);
      } else {
        setUnMatchedBets(response.bets);
        setFilteredUnMatchedBets(response.bets);
      }
    }
  };

  const getPendingBet = async () => {
    const response = await pendingBet(pendingBetsId);
    if (response) {
      if (response.errorBets.length > 0) {
        response.errorBets.forEach((errorBet) => {
          setPendingBetsId((prev) =>
            prev.filter((bet_id) => bet_id !== errorBet.bet_id)
          );
          enqueueSnackbar(errorBet.error_message, { variant: "error" });
        });
      }
      if (response.successBets.length > 0) {
        response.successBets.forEach((successBet) => {
          setPendingBetsId((prev) =>
            prev.filter((bet_id) => bet_id !== successBet.bet_id)
          );
          enqueueSnackbar(
            `Bet ${successBet.bet_type}. ${successBet.type} - ${
              successBet.runner_name
            } - ${successBet.bet_amount.toFixed(2)} at odds ${successBet.odds}`,
            { variant:successBet.bet_type ==="matched"? "success":"warning" }
          );
          getMatchedBetsData("un-matched");
          getMatchedBetsData("matched");
        });
      }
      if (response.pendingBets.length > 0) {
        setPendingBets(response.pendingBets);
        setTimeout(() => {
          getPendingBet();
          // getMatchedBetsData("un-matched");
        }, 1000);
      } else {
        setPendingBets([]);
      }
    }
  };

  const fetchData = async () => {
    const unsubscribe = subscribeToFirestoreCollection(`users/${userData.user_id}/un-match-to-match/`, fetchedData => {
      setFireBaseData([...fetchedData])
      console.log(fetchedData,"unmatch to matched");
    })
    return () => unsubscribe()
}

const handleBetsChanges = () =>{
  console.info("updating bets.....");
  getMatchedBetsData("matched");
  getMatchedBetsData("un-matched");
}

useEffect(()=>{
  if(fireBaseData && fireBaseData.length > 0){
      for(const match of fireBaseData){
          if(match && match.is_match === true){
              getMatchedBetsData("matched");
              getMatchedBetsData("un-matched");
              removeMatch(userData.user_id,match.id)
          }
      }
  }
},[fireBaseData])

useEffect(()=>{
  userSocket.getExposureAndOpenBets(handleBetsChanges);
},[])



useEffect(() => {
  if(isLogginIn){
      fetchData()
  }
}, [isLogginIn])

  useEffect(() => {
    if (currentTab === 1) {
      getMatchedBetsData("matched");
      getMatchedBetsData("un-matched");
      getPendingBet();
    }
  }, [currentTab]);

  useEffect(() => {
    if (eventId) {
      const newMatchedBets =
        matchedBets && matchedBets?.filter((bet) => bet.event_id === eventId);
      const newUnMatchedBets =
        unMatchedBets &&
        unMatchedBets?.filter((bet) => bet.event_id === eventId);
      setFilteredMatchedBets(newMatchedBets);
      setFilteredUnMatchedBets(newUnMatchedBets);
    } else {
      setFilteredMatchedBets(matchedBets);
      setFilteredUnMatchedBets(unMatchedBets);
    }
  }, [matchedBets, unMatchedBets, eventId]);

  // const cancelABet = async (bet_id) => {
  //   setBetCancelInProcess(true)
  //   const response = await cancelBet(bet_id);
  //   if (response) {
  //     getMatchedBetsData("un-matched");
  //     setBetCancelInProcess(false)
  //   }
  // };

  useEffect(() => {
    // Group bets by event_name when bets change
    const newGroupedMatchedBets = filteredMatchedBets.reduce((groups, bet) => {
      const { event_name } = bet;
      if (!groups[event_name]) {
        groups[event_name] = [];
      }
      groups[event_name].push(bet);
      return groups;
    }, {});

    // setgroupedMatchedBets(newGroupedMatchedBets);
    groupMatchedBets(filteredMatchedBets,setgroupedMatchedBets)
  }, [filteredMatchedBets]);

  useEffect(() => {
    const newGroupedUnMatchedBets = filteredUnMatchedBets.reduce(
      (groups, bet) => {
        const { event_name } = bet;
        if (!groups[event_name]) {
          groups[event_name] = [];
        }
        groups[event_name].push(bet);
        return groups;
      },
      {}
    );
    groupBets(filteredUnMatchedBets,setgroupedUnMatchedBets)
    

    
  }, [filteredUnMatchedBets]);

  const groupBets = async (bets,setter) =>{
    let byEvents = groupByArray(bets,"event_name",["provider_event_id","provider_game_id","event_id","league_id"]);
    byEvents.map((event,i)=>{
      const byMarket = groupByArray(event.data,"market_name",["market_type"]);
      event.data = byMarket.map((market,i)=>{
        const byRunner = groupByArray(market.data,"runner_name")
        market.data = byRunner;
        return market;
      })
      return event;
    })
  //  console.log(byEvents)
   setter(byEvents);
  }

  const groupMatchedBets = async (bets,setter) =>{
    let byEvents = groupByArray(bets,"event_name",["provider_event_id","provider_game_id","event_id","league_id"]);
    byEvents.map((event,i)=>{
      const byMarket = groupByArray(event.data,"market_name",["market_type"]);
      event.data = byMarket;
      return event;
    })
  //  console.log(byEvents)
   setter(byEvents);
  }


  return (
    <Box className={"bet-manager"}>
      <EditStakeModal
        open={editStake}
        onClose={() => setEditStake(false)}
        parent={ref}
      />
      <Typography
        variant="h4"
        sx={{
          padding: "0 0 10px 0",
          marginBottom: "10px",
          borderBottom: "1px solid rgba(15, 35, 39, 0.4)",
          color: "#0f2327",
        }}
      >
        Betslip
      </Typography>

      <Box component={"ul"} className="tabs">
        <Box
          onClick={() => changeTab(0)}
          component={"li"}
          className={`tab-bet-slip ${currentTab === 0 ? "-active" : ""}`}
        >
          <Box component={"a"}>Betslip</Box>
        </Box>
        <Box
          onClick={() => changeTab(1)}
          component={"li"}
          className={`tab-bet-slip ${currentTab === 1 ? "-active" : ""}`}
        >
          <Box component={"a"}>Open Bets</Box>
        </Box>
        <Box component={"li"}>
          <Button onClick={() => setEditStake(true)} variant="success">
            Edit Stakes
          </Button>
        </Box>
      </Box>
      {currentTab === 1 && (
        <Box className={"bets-by-event-filter"}>
          <Box component={"label"}>
            <input checked={showBetInfo} onChange={()=>setShowBetInfo(!showBetInfo)} type="checkbox" />
            Show bet Info
          </Box>
        </Box>
      )}
      <Box ref={ref} component={"ul"} className="content">
        {(editStake || openConfirmBet) && <Box className={"overlay"}></Box>}
        {currentTab === 0 && (
          <Box
            component={"li"}
            className="tab-betslip"
            sx={{ height: document.body.clientHeight - 306 }}
          >
            <Box className={"bets betslip"}>
              {bets && bets.length > 0 ? (
                <BetSlip
                  parent={ref}
                  openConfirmBet={openConfirmBet}
                  setOpenConfirmBet={setOpenConfirmBet}
                  setActiveTab={changeTab}
                  setPendingBetsId={setPendingBetsId}
                  placingBet={placingBet}
                  setPlacingBet={setPlacingBet}
                />
              ) : (
                <Box className={"empty-list-info"}>
                  Click on the odds to add selections to the betslip.
                </Box>
              )}
              {
                placingBet &&
              <Loader />
              }
            </Box>
          </Box>
        )}
        {currentTab === 1 && (
          <Box
            component={"li"}
            className="content-open-bets"
            sx={{ height: document.body.clientHeight - 306 }}
          >
            <Box className={"matched-bets pending"}>
              <Box
                onClick={() => setShowPending(!showPending)}
                className={`toggleable-list-title ${
                  showPending ? "list-opened" : ""
                }`}
              >
                <Box component={"span"}>Pending Bets</Box>
                <Box className={"toggle-icon"}>
                <CustomIcon name={showPending ?"sm-up-arrow":"sm-down-arrow"} color={"#1f5058"} fontSize={14} />
                </Box>
              </Box>
              {showPending && (
                <Box
                  className={`list-wrap ${showPending ? "shown -animate" : ""}`}
                >
                  {!pendingBets.length === 0 ? (
                    <Box component={"p"} className="empty-list-info">
                      You have no Pending bets
                    </Box>
                  ) : (
                    pendingBets.map((bet) => (
                      <Stack direction={"column"}>
                        <Stack
                          direction={"row"}
                          spacing={0.5}
                          alignItems={"center"}
                          sx={{
                            fontWeight: "400",
                            fontSize: "11px",
                            lineHeight: "18px",
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            color: "#2F2F2F",
                          }}
                        >
                          <Box
                            className={`${bet.type}-bg`}
                            sx={{ width: 12, height: 12 }}
                          ></Box>
                          <Box component={"span"}>{bet.runner_name}</Box>
                          <Box component={"b"}>{bet.type}</Box>
                          <Box component={"span"}>{bet.market_name} - </Box>
                          <Box component={"b"}>{bet.bet_amount}</Box>
                          <Box component={"span"}>at Odds</Box>
                          <Box component={"b"}>{bet.odds}</Box>
                        </Stack>
                      </Stack>
                    ))
                  )}
                </Box>
              )}
            </Box>
            <Box className={"bets unmatched-bets"}>
              <Box
                onClick={() => setShowUnMatch(!showUnMatch)}
                className={`toggleable-list-title ${true ? "list-opened" : ""}`}
              >
                <Box component={"span"}>Unmatched Bets</Box>
                <Box className={"toggle-icon"}>
                  <CustomIcon name={showUnMatch ?"sm-up-arrow":"sm-down-arrow"} color={"#1f5058"} fontSize={14} />
                </Box>
              </Box>
              {showUnMatch && (
                <Box className={`list-wrap ${true ? "shown -animate" : ""}`}>
                  {groupedUnMatchedBets.length === 0 ? (
                    <Box component={"p"} className="empty-list-info">
                      You have no unmatched bets
                    </Box>
                  ) : (
                    <>
                    {
                      groupedUnMatchedBets.map((event)=>{
                        return(
                          <Box sx={{ p: 0.5 }}>
                              <Link
                              to={`/sport/${event.provider_game_id}/${event.league_id}/${event.event_id}`}
                              // to={`/sport/${sportId}/${event.league_id}/${event.game_event_id}`}
                              style={{ color: "unset", textDecoration: "none" }}
                            >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                fontWeight: 700,
                                pb: 0.5,
                                m: "0 !important",
                              }}
                            >
                              {event.event_name}
                            </Typography>
                              </Link>

                          {event.data.map((market) => (
                            <UnmatchedBetTable market={market} getMatchedBetsData={getMatchedBetsData} />
                          ))}
                        </Box>
                        )
                      })
                    }
                      {/* {Object.keys(groupedUnMatchedBets).map((event_name) => (
                        <Box sx={{ p: 2 }}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: 700,
                              pb: 0.5,
                              m: "0 !important",
                            }}
                          >
                            {event_name}
                          </Typography>

                          {groupedUnMatchedBets[event_name].map((el) => (
                            <UnmatchedBetTable event_name={event_name} groupedUnMatchedBets={groupedUnMatchedBets} el={el} getMatchedBetsData={getMatchedBetsData} />
                          ))}
                        </Box>
                      ))} */}
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box className={"matched-bets"}>
              <Box
                onClick={() => setShowMatch(!showMatch)}
                className={`toggleable-list-title ${
                  showMatch ? "list-opened" : ""
                }`}
              >
                <Box component={"span"}>Matched Bets</Box>
                <Box className={"toggle-icon"}>
                <CustomIcon name={showMatch ?"sm-up-arrow":"sm-down-arrow"} color={"#1f5058"} fontSize={14} />
                </Box>
              </Box>
              {showMatch && (
                <Box
                  className={`list-wrap ${showMatch ? "shown -animate" : ""}`}
                >
                  {groupedMatchedBets.length === 0 ? (
                    <Box component={"p"} className="empty-list-info">
                      You have no matched bets
                    </Box>
                  ) : (
                    <>
                    {
                      groupedMatchedBets.map((event)=>{
                        return(
                          <>
                            <Typography
                              px={"4px"}
                              py={"5px"}
                              pb={0}
                              variant="body2"
                              sx={{ fontWeight: 700 }}
                            >
                              <Link
                                to={`/sport/${event.provider_game_id}/${event.league_id}/${event.event_id}`}
                                // to={`/sport/${sportId}/${event.league_id}/${event.game_event_id}`}
                                style={{ color: "unset", textDecoration: "none" }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 700,
                                    "&:hover": { textDecoration: "underline" },
                                  }}
                                >
                                  {event.event_name}
                                </Typography>
                              </Link>
                            </Typography>
                            <Table>
                              {
                                event.data.map((market)=>{
                                  return(
                                    <>
                                    {
                                      market.market_type === "fancy" &&
                                      <TableRow >
                                        <TableCell
                                          colSpan={4}
                                          sx={{padding:"2px 1px 0px"}}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {market.market_name}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    }
                                      <TableRow>
                                      <TableCell
                                        sx={{
                                          px: 0,
                                          pl: "4px",
                                          py: 1,
                                          pb: 0,
                                          fontWeight: market.market_type !== "fancy"? 600 : 400,
                                          fontSize: market.market_type !== "fancy"?12:10,
                                          textAlign: market.market_type !== "fancy"?"left":"center",
                                        }}
                                      >
                                        {market.market_type !== "fancy"?market.market_name:"Runs"}
                                      </TableCell>
                                      <TableCell
                                        sx={{ px: 2, py: 1, pb: 0, fontSize: 10 }}
                                      >
                                        Odds
                                      </TableCell>
                                      <TableCell
                                        sx={{ px: 2, py: 1, pb: 0, fontSize: 10 }}
                                      >
                                        Stake
                                      </TableCell>
                                      <TableCell
                                        sx={{ px: 2, py: 1, pb: 0, fontSize: 10 }}
                                      >
                                        Profit/Liability
                                      </TableCell>
                                    </TableRow>
                                    {
                                      market.data.map((bet)=>{
                                        return(
                                          <>
                                          <TableRow
                                          className={`${bet.type}-bg`}
                                            sx={{ borderBottom: "3px solid #f6f6f6",border:showBetInfo ?"none":"",}}
                                          >
                                              <TableCell
                                                sx={{
                                                  px: 0,
                                                  pl: "4px",
                                                  py: "5px",
                                                  fontWeight: 600,
                                                  fontSize: 12,
                                                  border:"none",
                                                  textAlign: market.market_type !== "fancy"?"left":"center",
                                                }}
                                              >
                                                {bet?.runner_name &&
                                                bet.runner_name.length > 10
                                                  ? bet.runner_name.slice(0, 10) + "..."
                                                  : bet.runner_name}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{ px: 0,border:"none", py: "5px" }}
                                              >
                                                {bet?.odds}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{ px: 0,border:"none", py: "5px" }}
                                              >
                                                {bet?.bet_amount}
                                              </TableCell>
                                              <TableCell
                                                align="center"
                                                sx={{ px: 0,border:"none", py: "5px" }}
                                              >
                                                {bet?.profit}
                                              </TableCell>
                                          </TableRow>
                                          {
                                            showBetInfo && 
                                            <TableRow className={`${bet.type}-bg`}>
                                              <TableCell  colSpan={4}
                                                sx={{
                                                  py: 0, px: "5px",
                                                  fontSize:9,
                                                  margin:"2px 7px 0",
                                                  color:"rgba(15, 35, 39, 0.65)",
                                                  borderBottom: "3px solid #f6f6f6",
                                                }}
                                                >
                                                Ref: {bet.bet_id_ui} | Placed: {moment.unix(bet.created_at).format("DD/MM/YYYY HH:mm:ss")}
                                              </TableCell>
                                            </TableRow>
                                          }
                                          </>
                                        )
                                      })
                                    }
                                    </>
                                  )
                                })
                              }
                            </Table>
                          </>
                        )
                      })
                    }
                    </>
                  )}
                </Box>
              )}
            </Box>
            <Box className={"matched-bets"}>
              <Box
                onClick={() => setShowVoid(!showVoid)}
                className={`toggleable-list-title ${
                  showVoid ? "list-opened" : ""
                }`}
              >
                <Box component={"span"}>Voided Bets</Box>
                <Box className={"toggle-icon"}>
                <CustomIcon name={showVoid ?"sm-up-arrow":"sm-down-arrow"} color={"#1f5058"} fontSize={14} />
                </Box>
              </Box>
              {showVoid && (
                <Box
                  className={`list-wrap ${showVoid ? "shown -animate" : ""}`}
                >
                  <Box component={"p"} className="empty-list-info">
                    You have no voided bets
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BetManager;
