import zlib from 'react-zlib-js';
import { Buffer } from 'buffer';
import { compitionData, eventData, sports } from "../config/data";
import axios from 'axios';

export const getSportNameById = (sportId) => {
  const sport = sports.filter((s) => s.id === sportId);
  if (sport && sport.length > 0) return sport[0].name;
  else return "Cricket";
};
export const getSportById = (sportId, sportsData = []) => {
  const sport = sportsData.filter((s) => s.provider_game_id === sportId);
  if (sport && sport.length > 0) return sport[0];
  else return undefined;
};

export const getCompNameById = (compId) => {
  const comp = compitionData.filter((c) => c.id.toString() === compId);
  if (comp && comp.length > 0) return comp[0].name;
  else return "Cricket";
};

export const getEventDataById = (eventId) => {
  const event = eventData.find((e) => e.eventId.toString() === eventId);
  if (event) return event;
};

export const calcProfit = (odds, amount,is_fractional_odds) => {
  if (odds && amount) {
    // console.log(parseFloat(odds) - 1, parseFloat(amount));
    return !is_fractional_odds ? ((parseFloat(odds) / 100) * parseFloat(amount)).toFixed(2) : ((parseFloat(odds) - 1) * parseFloat(amount)).toFixed(2);
  }
  return "0.00";
};

export const groupBy = (array, key) => {
  return array.reduce((result, obj) => {
    (result[obj[key]] = result[obj[key]] || []).push(obj);
    return result;
  }, {});
};

export const newGroupBy = (array, key, commonKeys = []) => {
  // First, group by the key
  const grouped = array.reduce((result, obj) => {
    (result[obj[key]] = result[obj[key]] || []).push(obj);
    return result;
  }, {});

  // Then, convert the object back into an array
  return Object.entries(grouped).map(([keyValue, data]) => {
    const commonFields = {};
    commonKeys.forEach((commonKey) => {
      if (data.length > 0 && data[0].hasOwnProperty(commonKey)) {
        commonFields[commonKey] = data[0][commonKey];
      }
    });

    return {
      [key]: keyValue,
      ...commonFields,
      data,
    };
  });
};

// Handle Response
export const handleResponse = (res,isEncrypted = false) => {
  if ((res.status_code === 200 || res.status_code === 201) && !res.response_error) {
    // console.log(res.status_message);
    // return isEncrypted ? decodeV1Response(res.data) : res.data;
    return res.data;
  } else {
    throw new Error(res.status_message || "Unknown error occurred");
  }
};


export const handleCatch = (res) => {
  if (res.response.status == 401) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/");
  }
  if (res?.response?.data?.status_message) {
    throw res.response.data.status_message
  }
  else throw "Something went wrong"
}

export const xorDecrypt = (encryptedText, key) => {
  let decrypted = '';
  for (let i = 0; i < encryptedText.length; i++) {
    decrypted += String.fromCharCode(encryptedText.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }
  return decrypted;
}

export const decodeV1Response = (encodeResponse) => {
  const brCompressed = Buffer.from(encodeResponse, 'base64');
  const decodeData = zlib.gunzipSync(brCompressed);
  const jsonString = decodeData.toString();
  const key = process.env.REACT_APP_SECRET_KEY;
  const decryptedData = xorDecrypt(jsonString, key);
  return JSON.parse(decryptedData)
}


const xorEncrypt = (text, key) => {
  let encrypted = '';
  for (let i = 0; i < text.length; i += 1) {
    encrypted += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    // const charCodeText = text.charCodeAt(i);
    // const charCodeKey = key.charCodeAt(i % key.length);
    // const xorResult = (charCodeText | charCodeKey) & ~(charCodeText & charCodeKey);
    // encrypted += String.fromCharCode(xorResult);
  }
  return encrypted;
};

export const encodeV1Response = (response) => {
  try {
    const jsonString = JSON.stringify(response);
    const encryptedData = xorEncrypt(jsonString, process.env.REACT_APP_SECRET_KEY);
    const responseData = zlib.gzipSync(encryptedData).toString('base64');
    // const responseData = zlib.deflateSync(encryptedData).toString('base64');
    // const responseData = zlib.brotliCompressSync(jsonString).toString('base64');
    return {data:responseData};
  } catch (error) {
    console.log(error);
    throw "something went wrong";
  }
};

export const isNegative = (num) => Math.sign(num) === -1;

export const groupByArray = (array, key, commonKeys = []) => {
  // First, group by the key
  const grouped = array.reduce((result, obj) => {
    (result[obj[key]] = result[obj[key]] || []).push(obj);
    return result;
  }, {});

  // Then, convert the object back into an array
  return Object.entries(grouped).map(([keyValue, data]) => {
    const commonFields = {};
    commonKeys.forEach((commonKey) => {
      if (data.length > 0 && data[0].hasOwnProperty(commonKey)) {
        commonFields[commonKey] = data[0][commonKey];
      }
    });

    return {
      [key]: keyValue,
      ...commonFields,
      data,
    };
  });
};

export const FIREBASE_COLLECTION = {
  USERS: `users`,
  UN_MATCH_TO_MATCH: `un-match-to-match`,
  UN_MATCH_LIST: 'un-match-list',
  USER_BALANCE: 'user-balance'
};

export const getIP = async () => {
  const res = await axios.get(`https://api-bdc.net/data/ip-geolocation-full?key=bdc_655c0adebc9040d891bac02d6ea38dfd`);
  // console.log(res.data);
  if(res && res.data && res.data.ip) return res.data.ip;
  else return null
  // return "192.168.0.1"
};