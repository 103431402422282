import React, { useEffect, useState } from "react";

import {
  Box,
  Stack,
  TextField,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  Button,
} from "@mui/material";

import { Close } from "@mui/icons-material";
import { cancelBet } from "../../api/services/betServices";
import { useSnackbar } from "notistack";
import CustomIcon from "../customIcon";

const UnmatchedBetTable = ({
  market,
  getMatchedBetsData,
}) => {
  
  

  return (
    <TableContainer>
      <Table>
        <Stack
          sx={{ pb: 0.5, pt: 1 }}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              fontSize: "12px",
              m: "0 !important",
              fontWeight: 700,
            }}
          >
            {market.market_name}
          </Typography>
          {/* <Typography
            // onClick={() => cancelABet(el?.bet_id)}
            sx={{
              fontSize: "12px",
              m: "0 !important",
              color: "#BF3E35",
              cursor: "pointer",
            }}
          >
            Cancel
          </Typography> */}
        </Stack>
        <TableBody>
        {
          market.data.map((runner)=>{
            return(    
              <>
              <TableRow>
                <TableCell sx={{ p: 0 }}>{runner?.runner_name}</TableCell>
              </TableRow>
              {
                runner.data.map((bet)=><SingleBet bet={bet} getMatchedBetsData={getMatchedBetsData} />)
              }
              </>
            )
          })
        }
        </TableBody>
       
      </Table>
    </TableContainer>
  );
};

const SingleBet = ({bet,getMatchedBetsData}) =>{

  const { enqueueSnackbar } = useSnackbar();

  const [betCancelInProcess, setBetCancelInProcess] = useState(false);

  const cancelABet = async (bet_id) => {
    setBetCancelInProcess(true);
    const response = await cancelBet(bet_id);
    if (response) {
      await getMatchedBetsData("un-matched").then(() => {
        setBetCancelInProcess(false);
        enqueueSnackbar("Bet Cancelled Successfullly!", { variant: "success" });
      });
    }
  };
  return(
    <TableContainer
      className={`${bet.type}-bg`}
        sx={{
          position: "relative",
        }}
      >
        {betCancelInProcess && (
          <Stack
            sx={{
              position: "absolute",
              bgcolor: "rgba(0, 0, 0, 0.75)",
              zIndex: 1,
              width: "100%",
              height: "100%",
              placeContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Removing Bet
            </Typography>
          </Stack>
        )}
        <Table className="inner-table-unmatched-bets">
          <TableHead>
            <TableRow>
              <TableCell sx={{ p: 0, border: "0", textAlign: "center" }}>Odds</TableCell>
              <TableCell sx={{ p: 0, border: "0", textAlign: "center" }}>Stake</TableCell>
              <TableCell sx={{ p: 0, border: "0", textAlign: "center" }}>Profit</TableCell>
              <TableCell
                sx={{
                  p: 0,
                  textAlign: "right",
                  border: "0",
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ p: 0.5,pt:0,pb:1, textAlign: "center" }}>
                {/* <TextField
                  sx={{
                    width: "50px",
                    borderRadius: "16px",
                  }}
                  value={el?.odds}
                  readonly
                /> */}
                {bet?.odds}
              </TableCell>
              <TableCell sx={{ p: 0.5,pt:0,pb:1, textAlign: "center" }}>
                {/* <TextField
                  sx={{
                    width: "50px",
                    borderRadius: "16px",
                  }}
                  value={el?.bet_amount}
                  readonly
                /> */}
                {bet?.bet_amount}
              </TableCell>
              <TableCell sx={{ p: 0.5,pt:0,pb:1, textAlign: "center" }}>{bet?.profit}</TableCell>
              <TableCell sx={{ p: 0.5,pt:0,pb:1, textAlign: "center" }}>
              <Button onClick={() => cancelABet(bet?.bet_id)} variant="error" className="mini">
                {/* <Box
                  sx={{
                    width: 8,
                    height: 8,
                    bgcolor: "white",
                  }}
                ></Box> */}
                <CustomIcon name={"modal-close"} fontSize={8} color={"#fff"} />
              </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
  )
}

export default UnmatchedBetTable;
