import { Box } from "@mui/material";


export default function NotificationMarquee()
{
    const messages = ["Play Now! Aviator in the Live Casino. Fly for the Big Win!","Check Out! Our live Hindi dealer games in Live casino. 3 Patti, Super Andar Bahar, Indian and Lightning Roulette.  "]
    return(
        <Box bgcolor={"primary.main"} width={"100%"} color={"#e6e6e6"}>
            <Box className={"marquee-wrapper"}>
                <Box component={"span"} className="span-marquee" sx={{background:`url(/assets/images/ball.gif)`}}></Box>
                <Box className={"notifications"}>
                    <Box component={"ul"} className="marquee-list scroll">
                        {
                            messages.map((message, index)=>{
                                return(
                                    <Box key={index} component={"li"} className="marquee-item">
                                        {message}
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
                <Box component={"span"} className="span-marquee right" sx={{background:`url(/assets/images/ball.gif)`}}></Box>
            </Box>
        </Box>
    )
}