import { Box, Typography } from "@mui/material";
import SideMenu from "../components/sideMenu";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { getCompNameById, getSportNameById } from "../utils/functions";
import { useEffect, useState } from "react";
import RightSidePane from "../components/rightSidePane";
import { useSelector } from "react-redux";
import { getEventsByLeagueId } from "../api/services/eventsService";
import RaceProvider from "../context/races-context";

export default function MenuLayout() {
  const { sportId, compId, eventId } = useParams();
  const [title, setTitle] = useState(
    compId
      ? getCompNameById(compId)
      : sportId
      ? getSportNameById(sportId) : ''
  );

  const [showTitle, setShowTitle] = useState();

  const { pathname } = useLocation();

  const { isLogginIn } = useSelector((state) => state.user);

  const pageTitles = [
    {
      link: "sport",
      title: "Cricket",
    },
    {
      link: "mybets",
      title: "My Bets",
    },
    {
      link: "betProfitLoss",
      title: "Betting Profit and Loss",
    },
    {
      link: "accountStatement",
      title: "Account Statement",
    },
    {
      link: "transferStatement",
      title: "Transfer Statement",
    },
    {
      link: "security",
      title: "Security",
    },
    {
      link: "changePassword",
      title: "Change Password",
    },
    {
      link: "favorite",
      title: "Favorites",
    },
  ];

  useEffect(() => {
    const path = pathname.split("/").slice(-1).pop();
    if (eventId || path === "race") setShowTitle(false);
    else setShowTitle(true);
  }, [pathname, eventId]);

  const getLeagueBySportIdFunc = async (comp_id) => {
    try {
      const response = await getEventsByLeagueId(comp_id);
      setTitle(response[0].league_name);
      //   setCompitionData(response);
    } catch (error) {
      console.error(`Error Fetching League By Sport Id: `, error);
    }
  };

  useEffect(() => {
    if (compId) {
      getLeagueBySportIdFunc(compId);
    } else {
      setTitle(sportId ? getSportNameById(sportId) : sportId);
    }
  }, [compId, title, sportId]);

  return (
    <Box
      sx={{
        display: "table",
        margin: "auto",
        overflow: "hidden",
        zIndex: "1",
        position: "relative",
        minHeight: "420px",
        height: "100%",
        width: "100%",
        padding: "0",
        maxWidth: "1254px",
      }}
    >
      <RaceProvider >
        <SideMenu />
        <Box
          sx={{
            display: "table-cell",
            verticalAlign: "top",
            padding: "0 20px 20px",
          }}
        >
          {showTitle && (
            <Typography
              variant="h1"
              sx={{
                color: "#0f2327",
                marginBottom: "10px",
                padding: "10px 0 10px 0",
                borderBottom: "1px solid rgba(15, 35, 39, 0.4)",
              }}
            >
              {compId?getCompNameById(compId):sportId ? getSportNameById(sportId):pageTitles.find(p=>p.link === pathname.split("/").slice(-1).pop())?.title}
            </Typography>
          )}

          <Outlet />
      </Box>
      </RaceProvider>
      {isLogginIn && <RightSidePane />}
    </Box>
  );
}
