import { io } from "socket.io-client";
import { authHeader } from "../api/services/userAuthService";

let socket;

export const SOCKET_LINK = process.env.REACT_APP_SOCKET_LINK;

const connect = (handleConnection) =>{
    socket = io(`${SOCKET_LINK}/v1/sport`,{
        forceNew:true,
        extraHeaders:{
            Authorization:authHeader(),
        }
    })
    return new Promise((res,rej)=>{
        socket.on('connect', function(socket) {
            console.log("Event connected");
            handleConnection(true)
            // setTimeout(()=>{
            //     joinUser();
            // },500)
            res(socket);
        });
        socket.on('disconnect',function(){
            handleConnection(false)
        });
        socket.once('connect_error', function() {
            console.log("error")
        });
        socket.once('connect_timeout', function() {
        });
    })
}

const joinSport = (data) =>{
    socket?.emit("join-sport-room",JSON.stringify(data))
}

const leaveSport = (data) =>{
    socket?.emit("sport-leave",JSON.stringify(data))
}

const getSportDetails = (setSportData) =>{
    socket?.on("get-sport-details",(data)=>{
        if(data && !data.response_error && data.data)
        {
            setSportData(data.data)
        }
    })
}


const disconnect = (userIds) => {
    socket?.disconnect()
    // const data = {
    //   leave_userIds: userIds,
    // };
    // socket?.emit("leave-room", data);
  };

const sportSocket = {
    connect,
    joinSport,
    leaveSport,
    getSportDetails,
    disconnect
}

export default sportSocket;