import { Box, Button, TextField, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { calcProfit, groupBy } from "../utils/functions";
import { clearBets, removeBet, updateAmount } from "../redux/reducers/bets";
import userSocket from "../sockets/userSocket";
import { openBet } from "../api/services/betServices";

import BetManager from "./BetManager";
import CustomIcon from "./customIcon";
import { subscribeUserCollection } from './../utils/firebase';
import { changeActiveOneClick, changeOneClickStakes, changeOneClickStatus } from "../redux/reducers/user";
import { updateUserSettings } from "../api/services/userAuthService";
import { editStakesByUserId } from "../api/services/settingsService";

export default function RightSidePane() {
  const [open, setOpen] = useState(false);

  const { sportId } = useParams();

  const [balance, setBalance] = useState({
    available_credit: 0,
    credit_limit: 0,
    total_net_exposure: 0,
    winnings: 0,
  });

  const [floting, setFloating] = useState(false);
  const [sticky, setSticky] = useState(false);

  const [stickyTop, setStickyTop] = useState();
  const ref = useRef();
  const stickyref = useRef();
  const { pathname } = useLocation();

  const {userData,balanceData} = useSelector((state) => state.user);

  useEffect(() => {
    if (stickyref && stickyref.current) {
      const stickyEl = stickyref.current.getBoundingClientRect();
      setStickyTop(stickyEl.top);
    }
  }, [stickyref]);

  useEffect(() => {
    if (!stickyTop) return;

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
    // eslint-disable-next-line
  }, [stickyTop,open]);

  useEffect(() => {
    const path = pathname.split("/");
    console.log(path.includes("account"))
    if (path.includes("account") || sportId === "10"){ 
      setFloating(true);
      // console.log("in if")
    }
    else{ 
      // console.log("in else")
      setFloating(false);
    }
  }, [pathname,sportId]);

  useEffect(()=>{
    console.log("floting",floting);
  },[floting])

  const isSticky = (e) => {
    const scrollTop = window.scrollY;
    // console.log(open,scrollTop,stickyTop+50);
    if ((scrollTop >= stickyTop - 10 && !open) || (scrollTop >= stickyTop + 50 && open)) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const handleClick = (e) => {
    if (!open) setOpen(true);
    else {
      ref?.current?.classList.remove("-animate");
      ref?.current?.classList.add("hidden");
      setTimeout(() => {
        setOpen(false);
        ref?.current?.classList.remove("hidden");
      }, 200);
    }
  };

  const styles = {
    floating: {
      position: "absolute",
      top: "0",
      right: "20px",
      width: "312px",
    },
    static: {
      display: "table-cell",
      verticalAlign: "top",
      width: "312px",
    },
    switchOn: {
      ".toggle-alternate input ~ :checked ~ .switch": { left: "30.25px" },
    },
    sticky: {
      position: "fixed",
      width: "312px",
      top: "0",
    },
  };
  const fetchData = async () => {
    console.log(userData.user_id)
    const unsubscribe = subscribeUserCollection(`${userData.user_id}`, fetchedData => {
        console.log({fetchedData})
        setBalance(fetchedData)
    })
    return () => unsubscribe()
}
  // useEffect(() => {
  //   // fetchData()
  //   userSocket.getBalanceData(setBalance);
  // }, []);

  useEffect(()=>{
    if(balanceData) setBalance(balanceData)
  },[balanceData])

  // useEffect(() => {
  //   if (sportId === "10") {
  //     setFloating(true);
  //   } else {
  //     setFloating(false);
  //   }
  // }, [sportId]);

  return (
    <Box sx={floting ? styles.floating : styles.static}>
      <Box className={"account-overview"}>
        <Box
          onClick={handleClick}
          className={`toggleable-list-title ${open ? "list-opened" : ""}`}
        >
          <Box component={"span"}>
            <Box component={"strong"}>Available Credit:</Box>
            <Box component={"span"} className="value">
              &nbsp;{balance?.available_credit}
            </Box>
          </Box>
          <Box className={"toggle-icon"}>
            {/* <Box sx={{ width: 6, height: 10, bgcolor: "#1f5058" }}></Box> */}
            <CustomIcon name={open ?"sm-up-arrow":"sm-down-arrow"} color={"#1f5058"} fontSize={14} />
          </Box>
        </Box>
        <Box
          ref={ref}
          className={`list-wrap ${open ? "shown -animate" : ""}`}
          component={"ul"}
        >
          <Box component={"li"}>
            <Box component={"strong"}>Credit Limit:</Box>&nbsp;
            {balance?.credit_limit}
          </Box>
          <Box component={"li"}>
            <Box component={"strong"}>Winnings:</Box>&nbsp;
            <Box component={"span"} className={balance?.winnings>-1?"-positive":"-negative"}> {balance?.winnings}</Box>
          </Box>
          <Box component={"li"}>
            <Box component={"strong"}>Total Net Exposure:</Box>&nbsp;
            <Box component={"span"} className="-negative">{balance?.total_net_exposure}</Box>
            
          </Box>
        </Box>
      </Box>
      {!floting && (
        <Box ref={stickyref} sx={sticky ? styles.sticky : {}}>
          <OneClickSetting />
          <BetManager />
        </Box>
      )}
    </Box>
  );
}

const OneClickSetting = () => {
  const [openOneClick, setOpenOneClick] = useState(false);
  const dispacth = useDispatch();
  const [selected, setSelected] = useState(0);
  const { userData,settings } = useSelector(state=>state.user)
  const { one_click_stake,user_id,stake,mobile_stake,is_one_click_bet_enable } = userData;
  const {active_one_click_stake} = settings;
  const [newValues, setNewValues] = useState(one_click_stake);
  const [editMode, setEditMode] = useState(false);


  const styles = {
    switchOn: {
      ".toggle-alternate input ~ :checked ~ .switch": { left: "30.25px" },
    },
  };

  const handleChange = (value, index) => {
    const temp = newValues;
    temp[index] = value;
    setNewValues([...temp]);
  };

  const saveValues = () => {
    // setValues([...newValues]);
    // setEditMode(false);
    updateOneClick(true);
  };

  const updateOneClick = async(is_one_click_enable) =>{
    try {
        const data = {
            is_one_click_bet_enable:is_one_click_enable,
            stake: stake,
            mobile_stake: mobile_stake,
            one_click_stake:newValues
        }
        const res = await editStakesByUserId(user_id,data);
        dispacth(changeOneClickStatus(is_one_click_enable));
        dispacth(changeOneClickStakes(newValues));
        setEditMode(false);
    } catch (error) {
        
    }
}

const handleEditStakeToggleClick = async() =>{
      await updateOneClick(!is_one_click_bet_enable);
}

  return (
    <Box
      className={`oneClick-wrapper one-click-bet-settings ${
        editMode ? "edit-mode-on" : "select-mode-on"
      } ${is_one_click_bet_enable ? "one-click-active" : ""}`}
    >
      <Box
        onClick={handleEditStakeToggleClick}
        className={"oneClick-switch small"}
        sx={is_one_click_bet_enable ? styles.switchOn : {}}
      >
        <Box
          className={`toggle-bg toggle-alternate ${
            is_one_click_bet_enable ? "on" : "off"
          }`}
        >
          <Box component={"label"} className={`${is_one_click_bet_enable ? "on" : "off"}`}>
            {is_one_click_bet_enable ? "on" : "off"}
          </Box>
          <input type="radio" checked={false} />
          <input type="radio" checked={true} />
          <Box
            component={"span"}
            className={`switch ${is_one_click_bet_enable ? "on" : "off"}`}
          ></Box>
        </Box>
      </Box>
      <Box onClick={() => setEditMode(false)} className={"btn-cancel-editing"}>
        Cancel
      </Box>
      <Box className={"one-click-settings-animation-wrap"}>
        <Box component={"strong"} className="one-click-title">
          1 Click Betting
        </Box>
        <Box className={"one-click-bet-form"}>
          <Box className={"select-mode-fields"}>
            <Box component={"ul"} className="settings-list">
              {one_click_stake.map((v, i) => {
                return (
                  <Box key={i} component={"li"} className="setting-select-only">
                    <Button
                      onClick={() => dispacth(changeActiveOneClick(i))}
                      variant="contained"
                      className={`small ${active_one_click_stake === i ? "selected" : ""}`}
                    >
                      {v}
                    </Button>
                  </Box>
                );
              })}
            </Box>
            <Button
              onClick={() => {
                setEditMode(true);
                setNewValues([...one_click_stake]);
              }}
              variant="success"
              className="small"
              sx={{ float: "right" }}
            >
              Edit
            </Button>
          </Box>
          <Box className={"edit-mode-fields"}>
            <Box component={"ul"} className="settings-list">
              {newValues.map((v, i) => {
                return (
                  <Box component={"li"} className="setting-input-wrap">
                    <TextField
                      type="number"
                      value={v}
                      onChange={(e) => handleChange(e.target.value, i)}
                      className="setting-input small"
                      variant="outlined"
                    />
                  </Box>
                );
              })}
            </Box>
            <Button
              onClick={() => saveValues()}
              variant="success"
              className="small"
              sx={{ float: "right" }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const StakeButtons = ({ side, onClick = () => {} }) => {
  const stake = useSelector((state) => {// Log the entire userData object
    return state.user.userData.stake;
  });

  if (!stake) {
    return console.log("Loading");
  }

  return (
    <Box className={`six-stakes ${side}`}>
      <Box className={"first-row"}>
        {stake &&
          stake.map((st, i) => {
            if (i < 3) {
              return (
                <Button onClick={() => onClick(st)} variant="secondary">
                  +{st}
                </Button>
              );
            } else return <></>;
          })}
        <Button variant="secondary">MAX</Button>
      </Box>
      <Box className={"second-row"}>
        {stake.map((st, i) => {
          if (i > 2) {
            return (
              <Button onClick={() => onClick(st)} variant="secondary">
                +{st}
              </Button>
            );
          } else return <></>;
        })}
        <Button
          onClick={() => onClick("")}
          variant="text"
          sx={{ textAlign: "right" }}
        >
          Clear
        </Button>
      </Box>
    </Box>
  );
};
