import { useEffect, useState } from "react";
import { Box, TextField, Button, Stack } from "@mui/material";
import { calcProfit } from "../../utils/functions";
import { StakeButtons } from "../rightSidePane";
import { useDispatch, useSelector } from "react-redux";
import { getFancyMinMaxByMarketId, getMatchOddOrBookMarkerMinMax } from "../../api/services/eventsService";
import {
  addExposure,
  updateExposure,
  removeAnExposure,
} from "../../redux/reducers/exposure";
import { Close, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { updateLiability, updateOdds } from "../../redux/reducers/bets";
import CustomIcon from "../customIcon";

const SingleBet = ({
  bet,
  focusedBet,
  updateBet,
  setFocus,
  clearBet,
  addAmount,
}) => {
  const dispatch = useDispatch();
  const exposures = useSelector((state) => state.exposures.exposure);

  const [maxBetAndMktData, setMaxBetAndMktData] = useState({});
  const [exposure, setExposure] = useState({});
  const [odds, setOdds] = useState(0);

  const fetchMatchOddOrBookMarkerMinMax = async (market_id) => {
    const response = await getMatchOddOrBookMarkerMinMax(market_id);
    if (response) {
      setMaxBetAndMktData(response);
    }
  };

  const fetchFancyMinMax = async (market_id) => {
    const response = await getFancyMinMaxByMarketId(market_id);
    if (response) {
      setMaxBetAndMktData(response);
    }
  };

  useEffect(() => {
    if(bet.is_fancy){
      fetchFancyMinMax(bet.provider_market_id)
    }
    else{
      fetchMatchOddOrBookMarkerMinMax(bet.market_id);
    }
  }, [bet]);

  useEffect(() => {
    const temp = exposures.find(
      (exposure) =>
        exposure.exposureId === `${bet.runner_id}-${bet.market_id}-${bet.type}`
    );
    if (!temp) {
      let data = {
        exposureId: `${bet.runner_id}-${bet.market_id}-${bet.type}`,
        market_id: bet.market_id,
        runners: bet?.runners?.map((runner) => ({
          runnerId: runner.runner_id,
          profit: 0,
        })),
      };
      setExposure(data);
      dispatch(addExposure(data));
    } else {
      setExposure(new Object({ ...temp }));
      // console.log(temp);
    }
  }, []);

  const removeExposure = () => {
    clearBet(bet.betId);
    dispatch(removeAnExposure(`${bet.runner_id}-${bet.market_id}-${bet.type}`));
  };

  const calculateProfit = (runnerId) => {
    if (runnerId === bet.runner_id) {
      return bet.type === "back"
        ? +calcProfit(bet.odds, bet.bet_amount,bet.is_fractional_odds)
        : -calcProfit(bet.odds, bet.bet_amount,bet.is_fractional_odds);
    }
    return bet.type === "back" ? -bet.bet_amount : bet.bet_amount;
  };

  // Inside your component
  useEffect(() => {
    const temp = { ...exposure };
    temp?.runners?.forEach((runner) => {
      const newProfit = calculateProfit(runner.runnerId);
      dispatch(
        updateExposure({
          exposureId: `${bet.runner_id}-${bet.market_id}-${bet.type}`,
          runnerId: runner.runnerId,
          profit: newProfit,
        })
      );

      // if (runner?.runnerId === bet?.runnerId && bet.type === "back") {
      //   const newProfit = +calcProfit(bet.odds, bet.bet_amount);
      //   console.log({newProfit})
      //   dispatch(
      //     updateExposure({
      //       exposureId: `${bet.runner_id}-${bet.market_id}-${bet.type}`,
      //       runnerId: runner.runnerId,
      //       profit: newProfit,
      //     })
      //   );
      // } else {
      //   dispatch(
      //     updateExposure({
      //       exposureId: `${bet.runner_id}-${bet.market_id}-${bet.type}`,
      //       runnerId: runner.runnerId,
      //       profit: -bet.bet_amount,
      //     })
      //   );
      // }
    });
  }, [bet]);

  useEffect(()=>{
    onUpdateLiability()
  },[bet.bet_amount,bet.odds])

  const setChangedOdds = (odds) =>{
    dispatch(
      updateOdds({ betId: bet.betId, odds: odds })
    );
  }

  const increaseOdds = () =>{
    const currentOdds = parseFloat(bet.odds)
    if(currentOdds < 2)
    {
        setChangedOdds((currentOdds + 0.01).toFixed(2));
    }
    else if(currentOdds <3)
    {
        setChangedOdds((currentOdds + 0.02).toFixed(2));
    }
    else if(currentOdds <4)
    {
        setChangedOdds((currentOdds + 0.05).toFixed(2));
    }
    else if(currentOdds <6)
    {
        setChangedOdds((currentOdds + 0.10).toFixed(2));
    }
    else if(currentOdds <10)
    {
        setChangedOdds((currentOdds + 0.20).toFixed(2));
    }
    else if(currentOdds <20)
    {
        setChangedOdds((currentOdds + 0.5));
    }
    else if(currentOdds <30)
    {
        setChangedOdds(currentOdds + 1);
    }
    else if(currentOdds <50)
    {
        setChangedOdds(currentOdds + 2);
    }
    else if(currentOdds <100)
    {
        setChangedOdds(currentOdds + 5);
    }
    else 
    {
        setChangedOdds(currentOdds + 10);
    }
}

const decreaseOdds = () =>{
    const currentOdds = parseFloat(bet.odds)
    if(currentOdds <= 2)
    {
        setChangedOdds((currentOdds - 0.01).toFixed(2));
    }
    else if(currentOdds <=3)
    {
        setChangedOdds((currentOdds - 0.02).toFixed(2));
    }
    else if(currentOdds <=4)
    {
        setChangedOdds((currentOdds - 0.05).toFixed(2));
    }
    else if(currentOdds <=6)
    {
        setChangedOdds((currentOdds - 0.10).toFixed(2));
    }
    else if(currentOdds <=10)
    {
        setChangedOdds((currentOdds - 0.20).toFixed(2));
    }
    else if(currentOdds <=20)
    {
        setChangedOdds(currentOdds - 0.5);
    }
    else if(currentOdds <=30)
    {
        setChangedOdds(currentOdds - 1);
    }
    else if(currentOdds <=50)
    {
        setChangedOdds(currentOdds - 2);
    }
    else if(currentOdds <=100)
    {
        setChangedOdds(currentOdds - 5);
    }
    else 
    {
        setChangedOdds(currentOdds - 10);
    }
}


const onUpdateLiability = ()=>{
  if(bet.type == "back"){
    dispatch(updateLiability({ betId: bet.betId, liability: bet.bet_amount || 0 }))
  }
  else{
    dispatch(updateLiability({ betId: bet.betId, liability: Number(calcProfit(bet.odds, bet.bet_amount)) }))
  }
  
}

const renderOddsRunsCell = () =>{
  return(
<Box className={"info-field runs-label"}>
                <Box>{!bet.is_fancy || bet.fancy_category === "ODD_EVEN"?"Odds":"Runs"}</Box>
                <Box
                  className={"line-value"}
                  sx={{ paddingTop: "0px !important" }}
                >
                  {bet.market_type === "match_odd" ? (
                    <TextField
                      sx={{
                        position: "relative",
                        "& .MuiInputBase-root": { overflow: "hidden" },
                        borderRadius: "32px",
                      }}
                      value={bet.odds}
                      onChange={(e) => {
                        dispatch(
                          updateOdds({ betId: bet.betId, odds: e.target.value })
                        );
                      }}
                      InputProps={{
                        endAdornment: (
                          <Stack
                            sx={{
                              // bgcolor: "#59B6FF",
                              position: "absolute",
                              height: "fit-content",
                              width: "auto",
                              right: 0,
                            }}
                          >
                            <Button sx={{padding:0,bgcolor:bet.type === "back"?"#59B6FF":"#fb7e9a",minHeight:"auto",border:"none"}}>
                              <KeyboardArrowUp
                              onClick={increaseOdds}
                                sx={{ color: "#fff", fontSize: "14px" }}
                              />
                            </Button>
                            <Button sx={{padding:0,bgcolor:bet.type === "back"?"#59B6FF":"#fb7e9a",minHeight:"auto",border:"none"}}>
                              <KeyboardArrowDown
                              onClick={decreaseOdds}
                                sx={{ color: "#fff", fontSize: "14px" }}
                              />
                            </Button>
                            
                          </Stack>
                        ),
                      }}
                    />
                  ) : (
                    !bet.is_fancy || bet.fancy_category === "ODD_EVEN"?bet.odds:bet.line
                  )}
                </Box>
              </Box>
  )
}

const renderRunnerName = (
  !bet.is_fancy || bet.fancy_category === "ODD_EVEN"? bet.runner_name :`${bet.market_name} @ ${bet.odds}`
)

  return (
    <Box component={"li"}>
      <Box className={`bet ${bet.type}`}>
        <Box className={"bet-header"}>
          <Box className={"section-name-label"}>
            <Box component={"span"} className="selection-name">
              {renderRunnerName}
            </Box>
            {bet.preMatch && (
              <Box component={"span"} className="pre-match-label">
                Pre-match Only
              </Box>
            )}
          </Box>
          <Box className={"max-market"}>
            Max Market: {maxBetAndMktData?.max_bet_amount}
          </Box>
        </Box>
        <Box className={"bet-fields"}>
          <Box className={"odds-field session-runs"}>
            <Box className={"placeholder"}></Box>
            <Box>
              {renderOddsRunsCell()}
            </Box>
          </Box>
          <Box className={"betslip__input placeholder-wrapper"}>
            <Box className={"placeholder"}></Box>
            <Box>
              <TextField
                type="number"
                autoFocus
                onChange={(e) => updateBet(bet.betId, e.target.value)}
                value={bet.bet_amount}
                onFocus={() => setFocus(bet.betId)}
              />
              {!bet.bet_amount && (
                <Box className={"betslip__placeholder"}>
                  <Box component={"span"}>Min Bet: {maxBetAndMktData.min}</Box>
                  <Box component={"span"}>Max: {maxBetAndMktData.max}</Box>
                </Box>
              )}
              <Box className={"betslip__placeholder-label"}>Stake</Box>
            </Box>
          </Box>
          <Box className={"info-field"}>
            <Box component={"span"} className="title">
              {bet.type === "lay" ? "Liability" : "Profit"}
            </Box>
            <Box component={"span"} className="value">
              {calcProfit(bet.odds, bet.bet_amount,bet.is_fractional_odds)}
            </Box>
          </Box>
          <Box className={"control-fields"}>
            <Button onClick={removeExposure} variant="error" className="mini">
              {/* <Box
                sx={{
                  width: 8,
                  height: 8,
                  bgcolor: "white",
                }}
              ></Box> */}
              <CustomIcon name={"modal-close"} fontSize={8} color={"#fff"} />
            </Button>
          </Box>
        </Box>
        {focusedBet === bet.betId && (
          <StakeButtons
            side={bet.type}
            onClick={(value) => addAmount(bet.betId, value, bet.bet_amount)}
          />
        )}
      </Box>
    </Box>
  );
};

export default SingleBet;
