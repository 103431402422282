import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEventDataById } from "../../../utils/functions";
import BetButton from "../../../components/miniComponents/betButton";
import MatchOdds from "./matchOdds";
import MatchOddsBookmaker from "./matchOddsBookmaker";
import Fancy from "./fancy";
import OtherTable from "./ohterTable";
import socketServices, { socket } from "../../../sockets/socketService";
import { userFancyExposure } from "../../../api/services/betServices";
import { subscribeToFirestoreCollection } from "../../../utils/firebase";
import { useSelector } from "react-redux";

const MatchOddCustomSort = [
  "Match Odds",
  "Over/Under 0.5 Goals",
  "Over/Under 1.5 Goals",
  "Over/Under 2.5 Goals",
  "Over/Under 3.5 Goals",
];

export default function EventMarket({ data }) {
  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [fancyExposures,setFancyExposures] = useState([]);
  const [openLadder,setOpenLadder] = useState(null);
  const [updateExposure,setUpdateExposure] = useState([]);
  const {userData,isLogginIn} = useSelector((state) => state.user);

  useEffect(() => {
    if (data) {
      setEvent(data);
    }
  }, [data]);

  const [fancy, setFancy] = useState([]);
  
  const handleMatchOddsSocketData = (data) => {
    setFancy(data.data.fancy);
  };


  const getFancyExposureData = async () => {
    const response = await userFancyExposure(eventId);
    if (response) {
      // console.log(response);
      setFancyExposures(response);
      // setExposures(response[0]);
    }
  };

  useEffect(()=>{
    if(isLogginIn){
      getFancyExposureData()
    }
},[updateExposure,isLogginIn])

  useEffect(()=>{
    if(isLogginIn){
      getFancyExposureData()
    }
  },[eventId,isLogginIn])

  useEffect(() => {
    if (event && isLogginIn) {
      setFancy(event.fancy);
      const data = {
        roomNames: `${event.provider_event_id}`,
        marketType: "fancy",
      };
      socketServices.joinRoom(data);
      socketServices.listenEvent(
        `${event.provider_event_id}`,
        handleMatchOddsSocketData
      );
    }
  }, [event,isLogginIn]);

  useEffect(() => {
    if(isLogginIn){
        fetchExpData()
    }
}, [isLogginIn])

  const fetchExpData = async () => {
    const unsubscribe = subscribeToFirestoreCollection(`user-exposure-list/${userData.user_id}/exposure-list/`, fetchedData => {
    //   setFireBaseData([...fetchedData])
      console.log(fetchedData,"exposures");
      console.log(updateExposure)
      if(fetchedData){
        fetchedData.map((market)=>{
            if(market.is_exposure){
                setUpdateExposure(prev=>[...prev,market.id]);
            }
        })
      }
    })
    return () => unsubscribe()
}


  return (
    <Box>
      {data && data?.match_odd
        ?.sort(
          (a, b) =>
            MatchOddCustomSort.indexOf(a.market_name) -
            MatchOddCustomSort.indexOf(b.market_name)
        )
        .map((market) => {
          return <MatchOdds setUpdateExposure={setUpdateExposure} updateExposure={updateExposure} event={event} market={market} />;
        })}
      {data?.bookmaker?.map((market) => {
        return <MatchOddsBookmaker setUpdateExposure={setUpdateExposure} updateExposure={updateExposure} event={event} market={market} />;
      })}

      {fancy && fancy?.length !== 0 && (
        <Typography
          sx={{
            fontWeight: 600,
            mt: 4,
            mb: 2,
            borderBottom: "1px solid",
            width: "max-content",
          }}
        >
          Fancy
        </Typography>
      )}
      {fancy && fancy?.map(
        (f) => ((f.category === "OVERS" || f.category === "BATSMAN" || f.category === "SINGLE_OVER") && f.yes_value !== 1) && 
        <Fancy exposure={fancyExposures.find((exp)=>exp.provider_market_id === f.provider_market_id)} market={f} event={event} openLadder={openLadder} setOpenLadder={setOpenLadder} />
      )}

      {fancy && fancy?.map(
        (f) => ((f.category !== "OVERS") && (f.category !== "BATSMAN") && (f.category !== "SINGLE_OVER") || f.yes_value === 1) && <OtherTable setUpdateExposure={setUpdateExposure} updateExposure={updateExposure} market={f}  event={event} />
      )}
    </Box>
  );
}

