import { Box, Stack } from "@mui/material";
import { Swiper , SwiperSlide } from "swiper/react";
import { Navigation,Autoplay } from "swiper/modules";
import 'swiper/css';
import { useNavigate } from "react-router-dom";


export default function Home()
{

    const navigate = useNavigate();

    const onBannerClick = (link) =>{
        navigate(link);
    }

    return(
        <Stack spacing={1} width={"100%"} mb={1}>
            <Box onClick={()=>onBannerClick("/sport/10")} component={"img"} src={`/assets/images/home/main.webp`} />
            <Stack direction={"row"} spacing={1} >
                <Box onClick={()=>onBannerClick("/sport/4")} component={"img"} src={`/assets/images/home/cricket.webp`} width={"33%"} />
                <Box onClick={()=>onBannerClick("/sport/1")} component={"img"} src={`/assets/images/home/football.webp`} width={"33%"} />
                <Box onClick={()=>onBannerClick("/sport/7")} component={"img"} src={`/assets/images/home/horse_racing.webp`} width={"33%"} />
            </Stack>
           <Slider1 onBannerClick={onBannerClick} />
           <Slider2 onBannerClick={onBannerClick} />
        </Stack>
    )
}

const Slider1 = ({onBannerClick})=>{
    const slides = [
        {
            image:"slider_1_1.webp",
            title:"Queenco Baccarat 1",
        },
        {
            image:"slider_1_2.webp",
            title:"Ander Bahar",
        },
        {
            image:"slider_1_3.webp",
            title:"Galaxy Baccarat 1",
        },
        {
            image:"slider_1_4.webp",
            title:"Oracle 360 Roulette",
        },
        {
            image:"slider_1_5.webp",
            title:"Portomaso Roulette",
        },
    ]
    return(
        <Box
        sx={{
            width:"99vw",
            overflow:"hidden",
        }}
    >
        <Swiper
            slidesPerView={5}
            spaceBetween={8}
            navigation={true}
            modules={[Navigation]}
        >
            {
                slides.map((slide)=>{
                    return(
                        <SwiperSlide>
                            <Stack
                                onClick={()=>onBannerClick("/sport/10")}
                            >
                                <Box component={"img"} src={`/assets/images/home/${slide.image}`} />
                                <Box
                                    sx={{
                                        fontSize: '22px',
                                        lineHeight: '1',
                                        color: '#420A57',
                                        background: '#FAB841',
                                        width: '100%',
                                        padding: '17px 4px',
                                        textAlign: 'center',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {slide.title}
                                </Box>
                            </Stack>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    </Box>
    )
}

const Slider2 = ({onBannerClick})=>{
    const slides = [
        {
            image:"back-to-venus-TM.webp",
            title:"Back to Venus",
        },
        {
            image:"the-slot-father-part-II-TM.webp",
            title:"Slot Father II",
        },
        {
            image:"golden-horns-TM.webp",
            title:"Golden Horns",
        },
        {
            image:"sin-city-nights-TM.webp",
            title:"Sin City Nights",
        },
        {
            image:"take-olympus-TM.webp",
            title:"Take Olympus",
        },
        {
            image:"sugar-pop-2-double-dipped-TM.webp",
            title:"SugarPop 2",
        },
        {
            image:"stacked-TM.webp",
            title:"Stacked",
        },
        {
            image:"mystic-hive-TM.webp",
            title:"Mystic Hive",
        },
        {
            image:"primal-hunt-TM.webp",
            title:"Primal Hunt",
        },
    ]
    return(
        <Box
        sx={{
            width:"99vw",
            overflow:"hidden",
        }}
    >
        <Swiper
            slidesPerView={5}
            spaceBetween={8}
            navigation={true}
            modules={[Navigation,Autoplay]}
            autoplay={{
                delay:5000
            }}
        >
            {
                slides.map((slide)=>{
                    return(
                        <SwiperSlide>
                            <Stack
                                onClick={()=>onBannerClick("/sport/10")}
                            >
                                <Box component={"img"} src={`/assets/images/home/${slide.image}`} />
                                <Box
                                    sx={{
                                        fontSize: '22px',
                                        lineHeight: '1',
                                        color: '#420A57',
                                        background: '#FAB841',
                                        width: '100%',
                                        padding: '17px 4px',
                                        textAlign: 'center',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {slide.title}
                                </Box>
                            </Stack>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    </Box>
    )
}