import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { liveCasinoData } from "../../../../config/liveCasinoData";
import { getCasinoEvents } from "../../../../api/services/eventsService";
import EventCategoryTabButtons from "./eventCategoryTabButtons";
import EventTabs from "./eventTabs";
import AllEvents from "./allEvents";
import { useSelector } from "react-redux";

import { casinoLogin } from "../../../../api/services/casinoService";
import { LoginContext } from "../../../../context/login-context";

const LiveCasinoPage = () => {
  const [currentCategory, setCurrentCategory] = useState(0);
  const [events, setEvents] = useState([]);
  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [iframe, setIframe] = useState("");
  const {setLogin} =  useContext(LoginContext)
  const { isLogginIn } = useSelector((state) => state.user);
  const menuData = useSelector((state) => state.menu.menuData?.[0]);

  console.log({ fetchedEvents });

  const changeCategory = (id) => {
    setIframe('')
    setCurrentCategory(id);
  };

  const openEvent = async (data) => {
    if(isLogginIn){
      const response = await casinoLogin(data);
      if (response) {
        setIframe(response.url);
      }
      console.log({ iframe });
    }
    else{
      setLogin(true)
    }
  };

  const fetchCasinoEventsData = async (casino_menu_id) => {
    const response = await getCasinoEvents(casino_menu_id);
    if (response) {
      setFetchedEvents(response);
      console.log({ response });
    }
  };

  useEffect(() => {
    const eventsByCategory = fetchedEvents?.filter(
      (event) => event.game_id === currentCategory
    );
    const allEvents = fetchedEvents.reduce((prev, current) => {
      prev = [...prev, ...current.event_data];
      return prev;
    }, []);
    // console.log()
    console.log("Hello", allEvents);
    currentCategory === 0
      ? setEvents(allEvents)
      : setEvents(eventsByCategory[0].event_data);
  }, [currentCategory, fetchedEvents]);

  useEffect(() => {
    fetchCasinoEventsData(menuData.menu_id);
  }, [menuData]);

  {
    /* <iframe src={iframe} width={"100%"} height={"675px"} /> */
  }

  return (
    <>
      <Box
        sx={{
          background: `url('${liveCasinoData[0].banner[0].bgImg}')`,
          height: 165,
          width: "100%",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
      {/* Tab Buttons */}
      <EventCategoryTabButtons
        currentCategory={currentCategory}
        handleClick={changeCategory}
        // tabData={liveCasinoData[0].categories}
        tabData={fetchedEvents}
      />
      {iframe !== "" ? (
        <iframe src={iframe} width={"100%"} height={"675px"} />
      ) : currentCategory === 0 ? (
        <AllEvents
          events={events}
          categories={fetchedEvents}
          openEvent={openEvent}
        />
      ) : (
        <EventTabs
          openEvent={openEvent}
          currentCategory={currentCategory}
          categoryToShow={currentCategory}
          events={events}
          tabData={fetchedEvents}
        />
      )}
    </>
  );
};

export default LiveCasinoPage;
