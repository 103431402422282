import { instance } from "./apiService.js";
import { handleResponse } from "../../utils/functions";

export const editStakesByUserId = async (user_id, data) => {
  try {
    const response = await instance.put(
      `/api/user/update-user-stake/${user_id}`,
      data
    );
    return handleResponse(response.data)
  } catch (error) {
    console.error("Error Editing Stakes", error);
  }
};
