import {
  Box,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BetButton from "../../../components/miniComponents/betButton";
import socketServices from "../../../sockets/socketService";

import { useDispatch, useSelector } from "react-redux";
import { isNegative } from "../../../utils/functions";
import { onUpdatedExposure, userExposureByMarket } from "../../../api/services/betServices";
import CustomIcon from "../../../components/customIcon";
import { addFavorite, removeFavorite } from "../../../redux/reducers/favorites";

const MatchOdds = ({ market, event,updateExposure=[],setUpdateExposure }) => {
  const [suspended, setSuspended] = useState(false);
  const [ballRunning, setBallRunning] = useState(false);
  const [odds, setOdds] = useState({});

  const [exposures, setExposures] = useState([]);

  const exposure = useSelector((state) => state.exposures.exposure);
  const isExpExist = Boolean(exposure.find((exp) => exp.market_id == market.market_id))
  const { favorites } = useSelector(state =>state.favorites)
    const dispatch = useDispatch();
    const { isLogginIn,userData } = useSelector((state) => state.user);
    const isInFavorites = favorites.find((fav)=>fav.market_id === market.market_id)

    const onAddFavorite = () =>{
      if(isLogginIn){
        if(favorites.find((fav)=>fav.market_id === market.market_id))
        {
            dispatch(removeFavorite(market.market_id))
        }
        else{
            dispatch(addFavorite({...market,game_name:event.game_name,game_event_id:market.event_id,event_name:event.event_name,event_time:event.event_time}));
        }
      }
    }

  const handleMatchOddsSocketData = (data) => {
    if (data.data) {
      setOdds(data.data);
    }

    switch (data.data.provider_status) {
      case "SUSPENDED":
        setSuspended(true);
        break;
      case "BALL_RUNNING":
        setBallRunning(true);
        break;
      default:
        setSuspended(false);
        setBallRunning(false);
    }
  };

  useEffect(() => {
    setOdds(market);
    if (event && market && isLogginIn) {
      const data = {
        roomNames: `${event.provider_event_id}/${market.provider_market_id}`,
        marketType: "match_odd",
      };
      socketServices.joinRoom(data);
      socketServices.listenEvent(
        `${event.provider_event_id}/${market.provider_market_id}`,
        handleMatchOddsSocketData
      );
      switch (market.provider_status) {
        case "SUSPENDED":
          setSuspended(true);
          break;
        case "BALL_RUNNING":
          setBallRunning(true);
          break;
        default:
          setSuspended(false);
          setBallRunning(false);
      }
    }
  }, [event, market,isLogginIn]);

  const getExposureData = async (marketId) => {
    const response = await userExposureByMarket(marketId);
    if (response) {
      setExposures(response[0]);
    }
    onUpdateExposure()
  };

  const onUpdateExposure = async() =>{
    try {
        const req = {
            user_id:userData.userData,
            exposure_id:market.market_id,
        }
        await onUpdatedExposure(req);
        setUpdateExposure(updateExposure.filter((id)=>id!==market.market_id));

    } catch (error) {
        console.log(error)
    }
}

  useEffect(() => {
    if(isLogginIn){
      getExposureData(market.provider_market_id);
    }
  }, [market,isLogginIn]);

  useEffect(()=>{
    if(isLogginIn && updateExposure.includes(market.market_id)){
      getExposureData(market.provider_market_id);
    }
},[updateExposure,isLogginIn])

  return (
    <Table className="market-listing-table">
      <TableHead>
        <TableRow>
          <TableCell className="title">
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack direction={"row"} alignItems={"center"} spacing={"6px"}>
                {/* <Box sx={{ width: 14, height: 14, bgcolor: "#e4c41e" }}></Box> */}
                <CustomIcon onClick={onAddFavorite} name={!isInFavorites?"favorite":"favorite-filled"} fontSize={14} color={"#e4c41e"} />
                <Box>&nbsp;{market.market_name}</Box>
              </Stack>
              <Box className={"icon-box"}>
                {/* <Box
                  sx={{ width: 18, height: 18, bgcolor: "primary.main" }}
                ></Box> */}
                <CustomIcon name={"inplay"} fontSize={16} color={"primary.main"} popover={"Going in-play"} />
              </Box>
            </Stack>
          </TableCell>
          <TableCell className="back"></TableCell>
          <TableCell className="back"></TableCell>
          <TableCell className="back"> Back </TableCell>
          <TableCell className="lay"> Lay </TableCell>
          <TableCell className="lay"></TableCell>
          <TableCell className="lay"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        
        {odds?.runners?.map((runner, i) => {
          const profit = exposure
          ?.filter((exp) => exp.market_id == market.market_id)
          .reduce((profit, exp) => {
            profit += exp.runners.find(
              (run) => run.runnerId == runner.runner_id
            ).profit;
            return profit;
          }, 0);
          const exposureProfit = exposures?.runners && exposures?.runners[i]?.profit ? exposures?.runners[i]?.profit : null
          return (
            <TableRow>
              <TableCell className="event-row">
                <Box className="event-name">{runner?.runner_name}</Box>
                {!isNaN(
                  exposureProfit
                ) && (
                  <Typography
                    variant="body2"
                  >
                    {
                      exposureProfit ? 
                      <>
                      <span className={
                      isNegative(exposureProfit)
                        ? "-negative"
                        : "-positive"
                    }>{exposureProfit}</span>
                      {
                        !isNaN(profit) && isExpExist &&
                        <>
                          <span> » </span>
                          <span className={
                          isNegative(exposureProfit + profit)
                            ? "-negative"
                            : "-positive"
                        } >{`${(exposureProfit + profit).toFixed(2)}`} </span>
                        </>
                      }
                      </>
                      :
                      <span className={
                        isNegative(profit)
                          ? "-negative"
                          : "-positive"
                      }>{!isNaN(profit) && isExpExist && `${(profit).toFixed(2)}`} </span>
                    }
                    {/* {exposureProfit} {!isNaN(profit) && profit !== 0 && `» ${(exposureProfit + profit).toFixed(2)}`}  */}
                  </Typography>
                )}
              </TableCell>
              <TableCell
                className={`${
                  suspended || runner.provider_status === "SUSPENDED"
                    ? "-status suspended"
                    : "inner-table-container"
                }`}
                colSpan={6}
              >
                {suspended || runner.provider_status === "SUSPENDED" ? (
                  <Box className="status-label">SUSPENDED</Box>
                ) : (
                  <>
                    <Table className="inner-table">
                      <TableBody>
                        <TableRow>
                          {runner?.back
                            ?.slice()
                            .reverse()
                            ?.map((back, i) => (
                              <BetButton
                                event={event}
                                market={market}
                                runner={runner}
                                odds={back.price}
                                type={"back"}
                                side={0}
                                className={`back ${
                                  i < 2 && "unhighlighted"
                                }`}
                                key={i}
                              >
                                <Box className={"bet-button-wrapper"}>
                                  <strong>{back.price}</strong>
                                  <Box className={"size"}>{back.size}</Box>
                                </Box>
                              </BetButton>
                            ))}
                          {runner?.lay.map((lay, i) => (
                            <BetButton
                              event={event}
                              market={market}
                              runner={runner}
                              odds={lay.price}
                              side={1}
                              className={`lay ${
                                i > 0 && "unhighlighted"
                              }`}
                              type="lay"
                            >
                              <Box className={"bet-button-wrapper"}>
                                <strong>{lay.price}</strong>
                                <Box className={"size"}>{lay.size}</Box>
                              </Box>
                            </BetButton>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                    {ballRunning && (
                      <Box className="ball-running-overlay">Ball Running</Box>
                    )}
                  </>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default MatchOdds;
