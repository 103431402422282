import { useEffect, useState } from "react";
import { getMarketListByEventId, getMarketListBySuspendedEventId } from "../../api/services/eventsService";
import EventHeader from "./components/eventHeader";
import EventMarket from "./components/eventMarkets";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import eventSocket from "../../sockets/eventSocket";

import { useSelector } from "react-redux";
import socketServices from "../../sockets/socketService";

export default function EventDetail() {
  const isLoggedIn = useSelector((state) => state.user.isLogginIn);

  const { eventId } = useParams();

  const [loading, setLoading] = useState(true);

  const [eventDetail, setEventDetail] = useState({});
  const [socketEventDetail, setSocketEventDetail] = useState({});

  const [roomData, setRoomData] = useState([]);

  const getMarketListByEventIdFunc = async (event_id) => {
    setLoading(true);
    const response = await getMarketListByEventId(event_id);
    if (response) {
      setEventDetail(response);
      setLoading(false);
    }
    else{
      const response = await getMarketListBySuspendedEventId(event_id);
      if (response) {
        setEventDetail(response);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getMarketListByEventIdFunc(eventId);
  }, [eventId]);

  useEffect(() => {
    if (eventDetail && isLoggedIn) {
      eventSocket.joinEvent({ eventId: eventDetail.provider_event_id });
      eventSocket.getEventDetails(setSocketEventDetail);
    }

    return () => {
      const data = {
        eventId: eventDetail?.provider_event_id,
      };
      eventSocket.leaveEvent(data);
    };
  }, [eventDetail, isLoggedIn]);

  const handleRoomData = (data) => {
    if (data.data) {
      let temp = roomData;
      temp.push(data.data);
      setRoomData(temp);
    }
  };

  useEffect(() => {
    socketServices.roomJoined(handleRoomData);
    
    return () => {
      roomData.forEach((el) => {
        socketServices.leaveRoom(el);
      });
    };
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <EventHeader data={eventDetail} />
          <EventMarket
            data={
              socketEventDetail?.match_odd ? socketEventDetail : eventDetail
            }
          />
        </>
      ) : (
        <Typography m={4}>Loading</Typography>
      )}
    </>
  );
}
