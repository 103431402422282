import { Box, Stack } from "@mui/material";
import CustomIcon from "../../../components/customIcon";
import moment from "moment";
import { useParams } from "react-router-dom";



export default function RaceHeader({race})
{
    const {marketId} = useParams(); 
    return(
        <Box sx={{borderBottom: '1px solid #ddd'}}>
            <Box
                sx={{
                    bgcolor:"primary.main",
                    color:"white",
                }}
            >
                <Stack direction={"row"} alignItems={"center"} className="title-box">
                    {/* <Box sx={{width:16,height:16,bgcolor:"white",ml:"10px"}} /> */}
                    <CustomIcon fontSize={16} color={"#fff"} name={race?.game_name} />
                    <Box>
                        <Box className={"event-name racing-title"}>{marketId ? "":"Next Race: "}{race?.market_time && moment.unix(race?.market_time).format("HH:mm")} {race?.event_name} </Box>
                    </Box>
                    <Box className={"event-datetime"}> {race?.market_name} </Box>
                </Stack>
                <Box className={"event-tabs"}>
                    <Box className="rule-box">
                        <Stack direction={"row"} alignItems={"center"} spacing={0.75}>
                        <CustomIcon name="info" fontSize={16} color={"#fff"} />
                            <Box>Rules</Box>
                        </Stack>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}