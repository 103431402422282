import { Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CustomIcon from "../../../components/customIcon";
import BetButton from "../../../components/miniComponents/betButton";
import { useEffect, useState } from "react";
import socketServices from "../../../sockets/socketService";
import { useSelector } from "react-redux";


export default function RaceMarket({event,market,sportId})
{
    const [suspended, setSuspended] = useState(false);
  const [ballRunning, setBallRunning] = useState(false);
  const [socketUserIds,setSocketUserIds] = useState([]);
  const [odds,setOdds] = useState(market);
  const { isLogginIn,userData } = useSelector((state) => state.user);

  useEffect(()=>{
    socketServices.roomJoined(handleRoomIds)
  },[])

//   useEffect(()=>{
    
    
// },[event.provider_event_id,market.provider_market_id,sportId]);

const handleRoomIds = (data) =>{
    // console.log("room Joined",data);
    if(data && data.data && !data.response_error)
    {
        // let temp = socketUserIds;
        // console.info("room Joined",data.data.roomNames);
        // if(!temp.find((id)=>id.roomNames === data.data.roomNames)){
        //     temp = [(data.data)];
        //     setSocketUserIds(temp);
        // }
    }
}

  useEffect(() => {
    if (event && market && isLogginIn) {
    //   setOdds(market);
      const data = {
        roomNames: `${event.provider_event_id}/${market.provider_market_id}`,
        marketType: "match_odd",
      };
      if(!socketUserIds.find((socket)=>socket.roomNames === data.roomNames)){
          socketServices.joinRoom(data);
          socketServices.listenEvent(
            `${event.provider_event_id}/${market.provider_market_id}`,
            handleMatchOddsSocketData
          );
          setSocketUserIds([data])
      }
      switch (market.provider_status) {
        case "SUSPENDED":
          setSuspended(true);
          break;
        case "BALL_RUNNING":
          setBallRunning(true);
          break;
        default:
          setSuspended(false);
          setBallRunning(false);
      }
    }
    return ()=>{
        if (event && market){
            console.info("leaving",socketUserIds);
            socketUserIds.forEach((data)=>{
                socketServices.leaveRoom(data);
            })
        }
        // socketServices.disconnect(socketUserIds)
    }
  }, [event, market,sportId,isLogginIn,socketUserIds]);

  const handleMatchOddsSocketData = (data) => {
    if (data.data && data.data.provider_market_id === market?.provider_market_id) {
      setOdds(data.data);
    }
    // console.log(data);

    switch (data.data.provider_status) {
      case "SUSPENDED":
        setSuspended(true);
        break;
      case "BALL_RUNNING":
        setBallRunning(true);
        break;
      default:
        setSuspended(false);
        setBallRunning(false);
    }
  };

    return(
        <Table className="market-listing-table">
            <TableHead>
                <TableRow>
                    <TableCell className="title">
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Stack direction={"row"} alignItems={"center"} >
                                <Box>&nbsp;Race Odds</Box>
                            </Stack>
                            <Box
                                sx={{
                                    flex: 1,
                                    display: "grid",
                                    gridAutoFlow: "column",
                                    justifyContent: "flex-end",
                                }}
                                >
                                <Box className={"icon-box"}>
                                    <CustomIcon name={"inplay"} fontSize={16} color={"primary.main"} popover={"Going in-play"} />
                                </Box>
                                <Box className={"icon-box"}>
                                    <CustomIcon name={"0percent"} fontSize={16} color={"primary.main"} popover={"No commission"} />
                                </Box>
                                </Box>
                        </Stack>
                    </TableCell>
                    <TableCell className="_align-center" colSpan={3}>
                        <Box className="three-cells-header">Back</Box>
                    </TableCell>
                    <TableCell className="_align-center" colSpan={3}>
                        <Box className="three-cells-header">Lay</Box>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    odds?.runners?.map((runner)=>{
                        return(
                            <TableRow>
                                <TableCell className="event-row">
                                    {/* <Box className={"runner-numbers"}>
                                        <Box component={"span"}>4</Box>
                                        <Box component={"span"}>(4)</Box>
                                    </Box> */}
                                    {/* <Box component={"img"} className={"runner-img"} src={"/d/assets/images/race-runner-img.png"} /> */}
                                    <Box sx={{float:"left"}}>
                                        <Box sx={{fontWeight:"bold"}}>{runner.runner_name}</Box>
                                        {/* <Box  display={"block"}>A Domeyer</Box> */}
                                    </Box>
                                </TableCell>
                                <TableCell
                                    className={`${
                                    suspended || runner.provider_status === "SUSPENDED"
                                        ? "-status suspended"
                                        : "inner-table-container"
                                    }`}
                                    colSpan={6}
                                >
                                    {suspended || runner.provider_status === "SUSPENDED" ? (
                                    <Box className="status-label">SUSPENDED</Box>
                                    ) : (
                                    <>
                                        <Table className="inner-table">
                                        <TableBody>
                                            <TableRow>
                                            {runner?.back ? runner?.back
                                                ?.slice()
                                                ?.reverse()
                                                ?.map((back, i) => (
                                                <BetButton
                                                    event={event}
                                                    market={market}
                                                    runner={runner}
                                                    odds={back.price}
                                                    type={"back"}
                                                    side={0}
                                                    className={`back ${
                                                    i < 2 && "unhighlighted"
                                                    }`}
                                                    key={i}
                                                >
                                                    <Box className={"bet-button-wrapper"}>
                                                    <strong>{back.price}</strong>
                                                    <Box className={"size"}>{back.size}</Box>
                                                    </Box>
                                                </BetButton>
                                                ))
                                                    :
                                                    [...Array(3)].map((e,i)=>(
                                                        <BetButton
                                                            event={event}
                                                            market={market}
                                                            runner={runner}
                                                            odds={""}
                                                            type={"back"}
                                                            side={0}
                                                            className={`back ${
                                                            i < 2 && "unhighlighted"
                                                            }`}
                                                            key={i}
                                                        >
                                                            <Box className={"bet-button-wrapper"}>
                                                            <strong></strong>
                                                            <Box className={"size"}></Box>
                                                            </Box>
                                                        </BetButton>
                                                    ))
                                            }
                                            {runner?.lay ? runner?.lay.map((lay, i) => (
                                                <BetButton
                                                event={event}
                                                market={market}
                                                runner={runner}
                                                odds={lay.price}
                                                side={1}
                                                className={`lay ${
                                                    i > 0 && "unhighlighted"
                                                }`}
                                                type="lay"
                                                >
                                                <Box className={"bet-button-wrapper"}>
                                                    <strong>{lay.price}</strong>
                                                    <Box className={"size"}>{lay.size}</Box>
                                                </Box>
                                                </BetButton>
                                            ))
                                        :
                                        [...Array(3)].map((e,i)=>(
                                            <BetButton
                                                event={event}
                                                market={market}
                                                runner={runner}
                                                odds={""}
                                                side={1}
                                                className={`lay ${
                                                    i > 0 && "unhighlighted"
                                                }`}
                                                type="lay"
                                                >
                                                <Box className={"bet-button-wrapper"}>
                                                    <strong></strong>
                                                    <Box className={"size"}></Box>
                                                </Box>
                                                </BetButton>
                                        ))
                                        }
                                            </TableRow>
                                        </TableBody>
                                        </Table>
                                        {ballRunning && (
                                        <Box className="ball-running-overlay">Ball Running</Box>
                                        )}
                                    </>
                                    )}
                                </TableCell>
                            </TableRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    )
}