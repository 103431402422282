import { Box, Button, Dialog, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { LoginContext } from "../../context/login-context";
import { acceptCondition } from "../../api/services/userAuthService";
import { useDispatch, useSelector } from "react-redux";
import { conditionAccepted } from "../../redux/reducers/user";

const styles = {
    modalBox:{
        padding: '20px',
        width: '240px',
        margin: '0 auto',
        background: '#fff',
        transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out'
    },
    over18ModalBox:{
        width: '240px',
    },
    rulesModalBox:{
        width: '640px',
        maxHeight: '500px'
    },
    actionBar:{
        paddingTop: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "input":{
            marginRight:1
        }
    },
    rules_text:{
        overflow: 'auto',
        position: 'relative',
        maxHeight: '400px',
        fontSize:"12px",
        color:"#333",
        h2:{
            fontSize:"20px",
            lineHeight:"26px",
            margin:"21px 0px 13px",
            fontWeight:400
        },
        h3:{
            fontSize:"15px",
            lineHeight:"20px",
            margin:"21px 0px 13px",
            fontWeight:400
        },
        "& p":{
            fontSize:"12px",
            lineHeight:1.15,
            margin:"0px 0px 13px"
        },
        "& ul":{
            // display: 'block',
            // listStyleType: 'disc',
            // marginBlockStart: '1em',
            // marginBlockEnd: '1em',
            // marginInlineStart: '0px',
            // marginInlineEnd: '0px',
            // paddingInlineStart: '40px',
            // marginLeft: '20px',
            // paddingLeft: '0'
        },
        "& li":{
            // marginBottom:"8px"
        }
    }
}

export default function KycModal({open,onClose=()=>{}}){

    const [over18,setOver18] = useState(false);
    const [rulesAccepted,setRulesAccepted] = useState(false);
    const {logout} =  useContext(LoginContext);
    const {userData} = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const handleNo = () =>{
        logout();
    }


    const onAcceptConditions = async() =>{
        try{
            const res = await acceptCondition(userData?.user_id)
            if(res)
            {
                onClose();
                dispatch(conditionAccepted())
            }
        }
        catch(error){
            console.log(error)
        }
    }

    return(
        <Dialog 
            PaperProps={{
                sx:{
                    maxWidth:"none"
                }
            }}
        
        open={open}>
            <Box sx={{...styles.modalBox,...(over18 ?styles.rulesModalBox:styles.over18ModalBox)}}>
                {
                    !over18 ? (
                        <>
                            <Typography variant="body2" textAlign={"center"}>Are you over 18?</Typography>
                            <Box sx={styles.actionBar}>
                                <Button onClick={()=>setOver18(true)} variant="success" className="large">Yes</Button>
                                <Button onClick={handleNo} variant="error_text" className="large">No</Button>
                            </Box>
                        </>
                    )
                    :(
                        <>
                            <Box sx={styles.rules_text}>
                            <p>
                                We are committed to the highest level of security, hence require all our
                                members to provide us with certain documentation in order to validate their
                                accounts.
                            </p>
                            <p>
                                Please note that the identification process shall be complete before any
                                withdrawal of funds can take place.
                            </p>
                            <h2>Why do I need to provide documentation?</h2>
                            <p>There are several reasons:</p>
                            <ul>
                                <li>
                                We are committed to providing a socially responsible platform for online
                                gaming. All of our members must be 18 or older and we are bound by our
                                licensing agreement to verify this.
                                </li>
                                <li>
                                It is in our interests to guarantee maximum security on all transactions.
                                </li>
                                <li>
                                Our payment processors and licensing agreement require that our policies
                                are in line with international banking standards. A proven business
                                relationship with each and every member is mandatory for the protection of
                                all parties.
                                </li>
                                <li>
                                By ensuring that your account details are absolutely correct, the
                                inconvenience of 'missing payments' can be avoided. It can take weeks (and
                                sometimes months) to trace, recall and resend using the correct
                                information. This lengthy process also results in additional fees from our
                                processors.
                                </li>
                            </ul>
                            <h2>WHAT DOCUMENTS DO I NEED TO PROVIDE?</h2>
                            <h3>PROOF OF ID:</h3>
                            <p>
                                A color copy of a valid government issued form of ID (Driver's License,
                                Passport, State ID or Military ID)
                            </p>
                            <h3>PROOF OF ADDRESS:</h3>
                            <p>A copy of a recent utility bill showing your address</p>
                            <p>
                                Note: If your government ID shows your address, you do not need to provide
                                further proof of address.
                            </p>
                            <p>Additional documentation might be required as the company sees fit.</p>
                            <h2>When do I need to provide these documents?</h2>
                            <p>
                                Please provide these at your earliest possible convenience to avoid any
                                delays in processing your transactions. Documents must be received before
                                any withdrawals are executed. Under special circumstances we may require the
                                documents before further activity (deposits and wagering) can take place on
                                your account
                            </p>
                            <p>
                                Please understand, if we do not have the required documents on file, your
                                pending withdrawals will be cancelled and credited back to your account. You
                                will be notified when this happens.
                            </p>
                            <h2>How can I send you these documents?</h2>
                            <p>
                                Please scan your documents, or take a high quality digital camera picture,
                                save the images as jpegs, then <a href="mailto:kyc@verifyexch.com">submit the files here</a>.
                            </p>
                            <h2>How do I know my documents are safe with you?</h2>
                            <p>
                                All files are protected with the highest level of encryption at every step
                                of the review process. All documentation received is treated with the utmost
                                respect and confidentiality.
                            </p>
                            <p>
                                We’d like to thank you for your cooperation in helping us make our platform
                                a safer place to play. As always, if you have any questions about this
                                policy, or anything else, don’t hesitate to contact us using the <a href="mailto:contact@verifyexch.com">contact us here</a>.
                            </p>
                            </Box>
                                <Box sx={styles.actionBar}>
                                    <input type="checkbox" checked={rulesAccepted} onChange={()=>setRulesAccepted(!rulesAccepted)} />
                                    <Button disabled={!rulesAccepted} onClick={onAcceptConditions} variant="success" className="large">Accept and Continue</Button>
                                </Box>
                        </>
                    )
                }
            </Box>
        </Dialog>
    )
}