
import React, { createContext, useEffect, useRef, useState } from "react";
import { getRacesBySport } from "../api/services/eventsService";
import sportSocket from "../sockets/sportSocket";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSportById } from "../utils/functions";

export const Racescontext = createContext();

const RaceProvider = ({ children }) => {
    const [races, setRaces] = useState([]);
    const { isLogginIn } = useSelector((state) => state.user);
    const { sportId } = useParams();
    const [sport, setSport] = useState();
    const sportsData = useSelector((state) => state.sports);

    const handleSportDataRef = useRef();


    handleSportDataRef.current = function(data){
        if(data && data.length > 0)
        {
            // let newArray = addNewField(data,"time",(obj)=>moment.unix(obj.market_time).format("HH:mm"))
            // let temp = newArray;
            // if((selectedTab.index === 0 && marketId !== temp[0]?.market_id))
            // {
            //     setSelectedTab({market_id:temp[0]?.market_id,event_id:temp[0]?.game_event_id,index:0})
            // }
            setRaces(data)
        }
    }


    const getRaces = async(game_id) =>{
        const res = await getRacesBySport(game_id)
        if(res && res.length>0){
          // console.log(res)
          setRaces([...res]);
            // getRace(res[0].game_event_id,res[0].market_id)
        }
    }


    useEffect(() => {
        if (sportId && sportId !== "10" && sportId !== "9") {
          if (getSportById(sportId, sportsData.sportsData))
            setSport(getSportById(sportId, sportsData.sportsData));
        }
    
      }, [sportId]);

    useEffect(()=>{
        if(handleSportDataRef && handleSportDataRef.current){
            sportSocket.getSportDetails((data)=>handleSportDataRef.current(data));
        }
    },[handleSportDataRef])

    const joinSportRoom = (gameId) =>{
        const data ={
            gameId
        }
        console.log(data);
        sportSocket.joinSport(data);
    }

    useEffect(()=>{
        if(sport && (sport.provider_game_id === "7" || sport.provider_game_id === "4339")){ 
            if(isLogginIn) joinSportRoom(sport.game_id);
            getRaces(sport.game_id);
        }
        return ()=>{
            if(sport)
            {
                const data ={
                    gameId:sport?.game_id
                }
                sportSocket.leaveSport(data)
            }
        }
    // eslint-disable-next-line
    },[sport,isLogginIn])

  return (
    <Racescontext.Provider value={{ races }}>
      {children}
    </Racescontext.Provider>
  );
};

export default RaceProvider;